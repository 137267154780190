import {
  Loader,
  SegmentedMenu,
  SimplifiedHeader,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusEscalierYellow,
  RedBullet,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import EDPDetailActions from 'src/Components/EDPDetailActions';
import { useFetchPositionStudyFromURL, useUpdatePositionStudy } from 'src/Hooks/PositionStudies';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import {
  EnumPositionStudyInstructionsHasHomeFormalisation,
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
  EnumPositionStudyRisksHasRisks,
} from 'src/Services/API';
import { getEditionRoute } from 'src/Services/Routing';
import { ACTIVITY_SECTORS } from 'src/Utils/constants';
import { numberToHours } from 'src/Utils/durationHelpers';

import { BreadCrumb } from './BreadCrumb';
import MobilePositionStudyNavigation from './MobilePositionStudyModification/MobilePositionStudyNavigation.component';
import PositionStudyForm from './PositionStudyForm';
import styles from './PositionStudyModification.module.scss';
import { navigation } from './PositionStudyModification.types';
import { PositionStudyModificationContext } from './PositionStudyModificationContext';
import Sidebar from './Sidebar';
import { checkTruthOfRequiredFields } from './utils';

const PositionStudyModification = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyId = useParams<{ positionStudyId: string }>().positionStudyId;
  const updateMutation = useUpdatePositionStudy();
  const fetchPositionStudy = useFetchPositionStudyFromURL({
    enabled: !updateMutation.isLoading,
  });
  const history = useHistory();

  const params = useParams<{
    firstSection: string;
    companyId: string;
    positionStudyId: string;
    secondSection: string;
  }>();

  const requiredFields = useSelector(getRequiredFields);
  const [simplifiedSelect, setSimplifiedSelect] = useState<boolean>(false);
  return (
    <PositionStudyModificationContext.Provider
      value={{
        fetchPositionStudy,
        updateMutation,
      }}
    >
      <div className={styles.container} id="formContainer">
        {!simplifiedSelect && (
          <>
            <div className={classNames(styles.topBar, { [styles.topBarSpace]: !isMobile })}>
              <BreadCrumb />
              <EDPDetailActions
                positionStudyId={positionStudyId}
                positionStudyCompletionStatus={fetchPositionStudy.data?.completionStatus}
              />
            </div>
            {isMobile && (
              <div className={styles.mobileEDPName}>
                {fetchPositionStudy.data?.generalInformation.positionStudyName}
              </div>
            )}

            {isMobile ? (
              <MobilePositionStudyNavigation />
            ) : (
              <div className={styles.tabsContainer}>
                <SegmentedMenu
                  controls={Object.entries(navigation)}
                  className={styles.menu}
                  selected={params.firstSection}
                  keyValueExtractor={([section, { name }]) => {
                    let shouldDisplayRedBullet = false;
                    if (requiredFields && section in requiredFields) {
                      shouldDisplayRedBullet = checkTruthOfRequiredFields(
                        requiredFields[section as keyof typeof requiredFields]
                      );
                    }
                    return {
                      key: section,
                      value: name,
                      rightIcon: shouldDisplayRedBullet ? <RedBullet /> : null,
                    };
                  }}
                  rightIconClassName={styles.rightIcon}
                  onSelectionChange={([section]) => {
                    history.push(
                      getEditionRoute({
                        companyId: params.companyId,
                        positionStudyId: params.positionStudyId,
                        section,
                      })
                    );
                  }}
                />
              </div>
            )}
          </>
        )}
        {simplifiedSelect && (
          <SimplifiedHeader
            fields={Object.entries(navigation).map(item => {
              return item[1].name;
            })}
            selectedkey={Object.entries(navigation).map(item => {
              return item[0];
            })}
            selected={params.firstSection}
            onSelectionChange={section => {
              history.push(
                getEditionRoute({
                  companyId: params.companyId,
                  positionStudyId: params.positionStudyId,
                  section,
                })
              );
            }}
          />
        )}
        <div className={classNames(styles.tabContainer, { [styles.desktopTab]: !isMobile })}>
          {!isMobile && <Sidebar />}

          {fetchPositionStudy.isLoading && (
            <div className={isMobile ? styles.mobileLoader : styles.loader}>
              <Loader heightInRem={isMobile ? 4 : 8} />
            </div>
          )}

          {fetchPositionStudy.isError && (
            <div className={styles.errorContainer}>
              <div className={styles.errorYellowLadder}>
                <IllusEscalierYellow />
              </div>
              <p className={styles.errorTitle}>erreur serveur, connexion perdue</p>
              <p className={styles.errorDescription}>
                dès que vous retrouverez la connexion, vous pourrez recharger la page
              </p>
            </div>
          )}

          {fetchPositionStudy.isSuccess && (
            <>
              <PositionStudyForm
                initialRequiredFields={{
                  hr: {
                    profile: {
                      positionStatus:
                        fetchPositionStudy.data.profileSection.positionStatus === undefined,
                      practice:
                        fetchPositionStudy.data.profileSection.practice === -1 ||
                        fetchPositionStudy.data.profileSection.practice === undefined,
                      weekHours:
                        fetchPositionStudy.data.profileSection.weekHours === undefined ||
                        (numberToHours(fetchPositionStudy.data.profileSection.weekHours).hours ===
                          0 &&
                          numberToHours(fetchPositionStudy.data.profileSection.weekHours)
                            .minutes === 0),
                    },
                    usefulinfo: {
                      selectedSector:
                        fetchPositionStudy.data.practicalInformation.sector?.id === undefined ||
                        fetchPositionStudy.data.practicalInformation.sector.id === '',
                      contactId:
                        fetchPositionStudy.data.practicalInformation.contactId === undefined ||
                        fetchPositionStudy.data.practicalInformation.contactId === null,
                      zipCode:
                        fetchPositionStudy.data.practicalInformation.workLocation?.zipCode ===
                        undefined,
                    },
                  },
                  security: {
                    risks: {
                      risks:
                        fetchPositionStudy.data?.practicalInformation?.sector?.id !==
                          ACTIVITY_SECTORS.TRADE_AND_SERVICES &&
                        fetchPositionStudy.data.risks.hasRisks ===
                          EnumPositionStudyRisksHasRisks.Unknown,
                    },
                    protectionEquipment: {
                      hasEpis:
                        fetchPositionStudy.data?.practicalInformation?.sector?.id !==
                          ACTIVITY_SECTORS.TRADE_AND_SERVICES &&
                        fetchPositionStudy.data.protectionEquipments.hasEpis ===
                          EnumPositionStudyProtectionEquipmentsHasEpis.Unknown,
                      hasEpcs:
                        fetchPositionStudy.data?.practicalInformation?.sector?.id !==
                          ACTIVITY_SECTORS.TRADE_AND_SERVICES &&
                        fetchPositionStudy.data.protectionEquipments.hasEpcs ===
                          EnumPositionStudyProtectionEquipmentsHasEpcs.Unknown,
                    },
                    instructions: {
                      hasHomeFormalisation:
                        fetchPositionStudy.data.instructions.hasHomeFormalisation ===
                        EnumPositionStudyInstructionsHasHomeFormalisation.Unknown,
                    },
                  },
                  job: { skills: fetchPositionStudy.data.skillSection.skills.length < 6 },
                }}
                skillsLength={fetchPositionStudy.data.skillSection.skills.length}
                selectedSector={
                  fetchPositionStudy.data.practicalInformation.sector ?? { id: '', label: '' }
                }
                currentHasRisks={fetchPositionStudy.data.risks.hasRisks}
                currentHasEpis={fetchPositionStudy.data.protectionEquipments.hasEpis}
                currentHasEpcs={fetchPositionStudy.data.protectionEquipments.hasEpcs}
                simplifiedHeader={simplifiedSelect}
                setSimplifiedHeader={setSimplifiedSelect}
              />
            </>
          )}
        </div>
      </div>
    </PositionStudyModificationContext.Provider>
  );
};

export default PositionStudyModification;
