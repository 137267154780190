import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { CompletionStatusState } from './Types';

export const getCompletionStatusState = (state: RootState) => state.completionStatus;

export const getTotalSkills = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.totalSkills
);

export const getSelectedSector = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.selectedSector
);

export const getCurrentHasRisks = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.currentHasRisks
);

export const getCurrentHasEpis = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.currentHasEpis
);

export const getCurrentHasEpcs = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.currentHasEpcs
);

export const getRequiredFields = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.requiredFields
);

export default getCompletionStatusState;
