import {
  Button,
  FetchButton,
  Modal,
  PopupMenu,
  PopupActions,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useRef } from 'react';

import { IllusEDPWarning } from 'src/Assets';
import { useDeletePositionStudy } from 'src/Hooks/PositionStudies/useDeletePositionStudy';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './EDPDeletionWarningModal.types';

const EDPDeletionWarningModal = ({ positionStudyId }: Props) => {
  const ref = useRef<PopupActions>(null);
  const deletePositionStudy = useDeletePositionStudy();
  const deleteFetchStatus = toFetchStatus(deletePositionStudy.mutation);

  return (
    <Modal
      onClose={ref.current?.close}
      title="Attention"
      ref={ref}
      trigger={
        <PopupMenu.Item
          icon={<Trashcan />}
          text="supprimer"
          onClick={ref.current?.open}
          keepOpenOnClick
        />
      }
      icon={<IllusEDPWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={ref.current?.close} text="annuler" />,
        <FetchButton
          title="confirmer"
          fetchStatus={deleteFetchStatus}
          onClick={() =>
            positionStudyId && deletePositionStudy.deletePositionStudy(positionStudyId)
          }
          onSuccess={() => {
            ref.current?.close();
            toast.success("l'absence a bien été supprimée", { autoClose: 5000 });
          }}
        />,
      ]}
    >
      <div>Vous vous apprêtez à supprimer définitivement cette edp. Confirmez vous ce choix ?</div>
    </Modal>
  );
};

export default EDPDeletionWarningModal;
