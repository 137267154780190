import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import React, { useContext } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import { ProfileContext } from '../../ProfileContext';

const practiceChoices = (isMobile: boolean) => {
  return [
    { value: -1, label: 'n/r' },
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: isMobile ? 6 : 5, label: 'de 5 à 10' },
    { value: 10, label: 'de 10 à 15' },
    { value: 15, label: 'plus de 15' },
  ];
};

const valuetext = (value: number, isMobile: boolean) => {
  if (value === -1) return 'n/r';
  return practiceChoices(isMobile).find(mark => mark.value === value)?.label ?? '';
};

const ExperienceSelection = () => {
  const isMobile = useSelector(getDevice);
  const profileContext = useContext(ProfileContext);
  const experienceController = useController({ control: profileContext.control, name: 'practice' });
  const value = experienceController.field.value;

  const style =
    value === -1
      ? {
          '& .MuiSlider-thumb': {
            backgroundColor: 'red',
            '&:hover': {
              boxShadow: '0px 0px 0px 8px rgba(255, 0, 0, 0.16)',
            },
            '&.Mui-focusVisible': {
              boxShadow: '0px 0px 0px 8px rgba(255, 0, 0, 0.16)',
            },
          },
          '& .MuiSlider-track': {
            backgroundColor: 'red',
            borderColor: 'red',
          },
        }
      : {};

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '1rem',
        ...style,
      }}
    >
      <Slider
        min={-2}
        max={16}
        aria-label="Restricted values"
        defaultValue={-1}
        value={value === undefined ? -1 : value}
        onChange={experienceController.field.onChange}
        getAriaValueText={value => valuetext(value, isMobile)}
        step={null}
        valueLabelDisplay="off"
        marks={practiceChoices(isMobile)}
      />
    </Box>
  );
};

export default ExperienceSelection;
