import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useMemo, useState } from 'react';

import { useFetchHabilitations } from 'src/Hooks/Training/useFetchHabilitations';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './HabilitationsPicker.types';

const HabilitationsPicker = ({ onChange, selectedHabilitations }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchHabilitations({ keyword });
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  return (
    <AutoSuggest
      id="habilitations"
      value={selectedHabilitations}
      searchResults={data ?? []}
      onSearch={keyword => throttledSetKeyword(keyword)}
      keyValueExtractor={habilitation => ({
        key: habilitation.id,
        value: habilitation.label,
      })}
      onChange={onChange}
      minLengthToSearch={3}
      fetchStatus={
        keyword.length > 2
          ? toFetchStatus({ isSuccess, isError, isLoading })
          : FETCH_STATUS.FULFILLED
      }
    />
  );
};
export default HabilitationsPicker;
