import { Button, Modal, PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEDPBonus } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';

import { AddButton } from 'src/Containers/RH/Profile/Components/AddButton';
import { useFetchPresetBonuses } from 'src/Hooks/Bonus/useFetchPresetBonuses';

import BonusRatesCategories from './BonusRatesCategories';
import BonusRatesDetails from './BonusRatesDetails';
import {
  BONUS_ADD_STEP,
  bonusCategorySchema,
  BonusCategorySchemaType,
  bonusDetailSchema,
  BonusDetailSchemaType,
  Props,
} from './BonusRatesModal.types';

interface ModalChild {
  component: React.ReactNode;
  next?: BONUS_ADD_STEP;
  title: string;
  previous?: BONUS_ADD_STEP;
  rightButtonText?: { primary: string; secondary: string };
  formResolver?: UseFormReturn<BonusCategorySchemaType> | UseFormReturn<BonusDetailSchemaType>;
}

const BonusRatesModal = ({ onValidate }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);

  const { data } = useFetchPresetBonuses();

  const bonusCategoryZodResolver = useFormWithZodResolver({
    schema: bonusCategorySchema,
    defaultValues: {
      bonusCategory: undefined,
    },
  });

  const bonusCategoryController = useController({
    name: 'bonusCategory',
    control: bonusCategoryZodResolver.control,
  });

  const presetBonus = data?.find(preset => preset.id === bonusCategoryController.field.value?.id);

  const bonusDetailsZodResolver = useFormWithZodResolver({
    schema: bonusDetailSchema(presetBonus?.maxAmount),
    defaultValues: {
      bonusLabel: '',
      bonusAmount: '',
      bonusFrequency: undefined,
    },
  });

  const [step, setStep] = useState(BONUS_ADD_STEP.CATEGORY_SELECTION);

  const ModalChildren: Record<BONUS_ADD_STEP, ModalChild> = {
    [BONUS_ADD_STEP.CATEGORY_SELECTION]: {
      component: (
        <BonusRatesCategories
          field={bonusCategoryController.field}
          error={bonusCategoryController.fieldState.error}
        />
      ),
      next: BONUS_ADD_STEP.BONUS_DETAILS,
      title: 'ajouter une prime',
      formResolver: bonusCategoryZodResolver,
      rightButtonText: { primary: 'suivant', secondary: 'annuler' },
    },
    [BONUS_ADD_STEP.BONUS_DETAILS]: {
      component: (
        <BonusRatesDetails
          name="bonusDetails"
          control={bonusDetailsZodResolver.control}
          setValue={bonusDetailsZodResolver.setValue}
          bonusCategoyField={bonusCategoryController.field}
        />
      ),
      previous: BONUS_ADD_STEP.CATEGORY_SELECTION,
      title: bonusCategoryController.field.value?.id
        ? `détails de la prime ${bonusCategoryController.field.value.id}`
        : 'nouvelle prime',
      formResolver: bonusDetailsZodResolver,
      rightButtonText: {
        primary: 'valider',
        secondary: 'précédent',
      },
    },
  };

  const { component, next, title, previous, rightButtonText, formResolver } = ModalChildren[step];

  return (
    <Modal
      ref={innerRef}
      icon={<IllusEDPBonus />}
      trigger={<AddButton text="ajouter une prime" />}
      title={title}
      onClose={() => {
        setStep(BONUS_ADD_STEP.CATEGORY_SELECTION);
        bonusCategoryZodResolver.reset();
        bonusDetailsZodResolver.reset();
      }}
      footerActionsLeft={
        previous !== undefined
          ? [<Button.Tertiary onClick={innerRef.current?.close}>annuler</Button.Tertiary>]
          : undefined
      }
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            if (previous !== undefined) {
              setStep(previous);
              bonusDetailsZodResolver.reset();
            } else {
              innerRef.current?.close();
            }
          }}
        >
          {rightButtonText?.secondary}
        </Button.Secondary>,
        <Button.Primary
          onClick={formResolver?.handleSubmit(() => {
            const bonusDetails = bonusDetailsZodResolver.getValues();
            const isLiable = bonusCategoryZodResolver.getValues().bonusCategory.liable;
            if (next !== undefined) {
              setStep(next);
            } else {
              onValidate({
                id: undefined,
                amount: parseFloat(bonusDetails.bonusAmount.replace(',', '.')),
                cadency: bonusDetails.bonusFrequency,
                label: bonusDetails.bonusLabel,
                liable: isLiable,
              });
              innerRef.current?.close();
            }
          })}
        >
          {rightButtonText?.primary}
        </Button.Primary>,
      ]}
    >
      {component}
    </Modal>
  );
};
export default BonusRatesModal;
