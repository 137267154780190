import { useMutation } from '@tanstack/react-query';

import { PositionstudiesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUploadPositionStudyFile = () => {
  return useMutation(
    [MutationKeys.uploadPositionStudyFiles],
    async (params: { id: string; file: File }) => {
      const updatedNamePositionStudy =
        await PositionstudiesService.positionStudiesControllerUploadPositionStudyFiles({
          id: params.id,
          file: params.file,
        });
      return updatedNamePositionStudy;
    },
    {}
  );
};
