import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { useCreatePositionStudy } from 'src/Hooks/PositionStudies';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { Sector } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { EDPCreationState, getEditionRoute } from 'src/Services/Routing';

import EDPCreationModal from '..';

const EDPNewCreationModal = () => {
  const location = useLocation<EDPCreationState>();
  const currentAgencyId = useSelector(getCurrentAgency);
  const createPositionStudyMutation = useCreatePositionStudy();
  const companyId = location.state.modalParameters?.companyId;
  const history = useHistory();

  return (
    <EDPCreationModal
      onValidate={(selectedName: string, qualificationId: string, sector?: Sector) => {
        createPositionStudyMutation.mutate({
          companyId: companyId ?? '',
          qualificationId: qualificationId,
          positionStudyName: selectedName,
          agencyId: currentAgencyId ?? '',
          sector,
        });
      }}
      onSuccess={() =>
        history.push(
          getEditionRoute({
            companyId: createPositionStudyMutation.data?.generalInformation?.company?.companyId,
            positionStudyId: createPositionStudyMutation.data?.generalInformation?.positionStudyId,
            section: 'hr/profile',
          })
        )
      }
      fetchStatus={toFetchStatus(createPositionStudyMutation)}
    />
  );
};

export default EDPNewCreationModal;
