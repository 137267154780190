import React from 'react';

import styles from './RequiredFieldHeader.module.scss';
import { Props } from './RequiredFieldHeader.types';

const RequiredFieldHeader = ({ fieldHeader, isMobile, expected = true }: Props) => (
  <div className={isMobile ? styles.mobileTitleSpaccer : styles.spaccer}>
    <span className={styles.fieldHeader}>{fieldHeader}</span>
    {expected && <span className={styles.requiredLabel}> (*info attendue)</span>}
  </div>
);

export default RequiredFieldHeader;
