import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import { ProfileContext } from '../../ProfileContext';
import { AddButton } from '../AddButton';

import { WorkingHourItem } from './WorkingHourItem';
import styles from './WorkingHours.module.scss';

function WorkingHours() {
  const isMobile = useSelector(getDevice);
  const profileContext = useContext(ProfileContext);
  const { fields, append, remove } = useFieldArray({
    control: profileContext.control,
    name: 'workingHours',
  });

  const disabled = fields.length >= 4;
  return (
    <>
      <div>
        {fields.map(({ id, ...defaultValue }, index) => (
          <WorkingHourItem
            key={id}
            className={isMobile ? styles.mobileContainer : styles.container}
            index={index}
            remove={() => {
              remove(index);
              profileContext.submit();
            }}
            defaultValue={defaultValue}
            isLastWorkingHourItem={index === fields.length - 1}
          />
        ))}
      </div>
      <AddButton
        onClick={() => {
          append({});
        }}
        disabled={disabled}
        text={disabled ? 'nombre maximal de créneaux atteint' : 'ajouter un créneau horaire'}
      />
    </>
  );
}

export default WorkingHours;
