import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { Props } from './LanguagePicker.types';

const LanguagePicker = ({ onChange, selectedItems, items }: Props) => {
  return (
    <AutoSuggest
      id="languages"
      value={selectedItems}
      searchResults={items}
      useNativeOptionFiltering
      keyValueExtractor={item => ({ key: item.id, value: item.label })}
      placeholder="rechercher une langue"
      onChange={items => onChange(items)}
    />
  );
};

export default LanguagePicker;
