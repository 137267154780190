import {
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
  PositionStudyProtectionEquipments,
} from 'src/Services/API';

export const formatProtectionEquipments = (
  protectionEquipments: PositionStudyProtectionEquipments
): PositionStudyProtectionEquipments => {
  const checkEpis = (
    protectionEquipments: PositionStudyProtectionEquipments
  ): EnumPositionStudyProtectionEquipmentsHasEpis => {
    return protectionEquipments.hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Yes
      ? protectionEquipments.clientEpis.length > 0 || protectionEquipments.agencyEpis.length > 0
        ? protectionEquipments.hasEpis
        : EnumPositionStudyProtectionEquipmentsHasEpis.No
      : protectionEquipments.hasEpis;
  };

  const checkEpcs = (
    protectionEquipments: PositionStudyProtectionEquipments
  ): EnumPositionStudyProtectionEquipmentsHasEpcs => {
    return protectionEquipments.hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Yes
      ? protectionEquipments.mainEpcs.length > 0 ||
        (protectionEquipments.otherEpcs !== undefined && protectionEquipments.otherEpcs !== '')
        ? protectionEquipments.hasEpcs
        : EnumPositionStudyProtectionEquipmentsHasEpcs.No
      : protectionEquipments.hasEpcs;
  };

  return {
    ...protectionEquipments,
    hasEpis: checkEpis(protectionEquipments),
    hasEpcs: checkEpcs(protectionEquipments),
  };
};
