import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ConsultantsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useHasConsultantGivenPhotoRights = (
  extraOptions?: UseQueryOptions<boolean, unknown, boolean, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchConsultantPhotoRights],
    async () => {
      return await ConsultantsService.consultantsControllerHasConsultantGivenPhotoRights();
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
