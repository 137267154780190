import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PositionstudiesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useDeletePositionStudyFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deletePositionStudyFiles],
    async (params: { id: string }) => {
      const deletedPositionStudyFile =
        await PositionstudiesService.positionStudiesControllerDeletePositionStudyFiles({
          id: params.id,
        });
      if (deletedPositionStudyFile.valueOf())
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudyFiles'] });
      return deletedPositionStudyFile;
    },
    {}
  );
};
