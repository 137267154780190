import { durationSchema } from '@randstad-lean-mobile-factory/react-form-fields';
import * as z from 'zod';

type Duration = z.infer<typeof durationSchema>;

export function hoursToNumber({ hours, minutes }: Duration): number | undefined {
  const numberDuration = hours + minutes / 60;
  return numberDuration === 0 ? undefined : numberDuration;
}

export function numberToHours(numberDuration = 0): Duration {
  const hours = Math.floor(numberDuration);
  const minutes = Math.round((numberDuration - hours) * 60);

  return {
    hours,
    minutes,
  };
}

export function weekDaysSort(weekdays: string[]): string[] {
  const weekDaysDictionnary = {
    Lun: 1,
    Mar: 2,
    Mer: 3,
    Jeu: 4,
    Ven: 5,
    Sam: 6,
    Dim: 7,
  };

  const mapped = weekdays.map(weekDay => ({
    key: weekDay,
    value: weekDaysDictionnary[weekDay as keyof typeof weekDaysDictionnary] ?? 0,
  }));

  mapped.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });
  return mapped.map(weekDay => weekDay.key);
}
