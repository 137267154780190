import * as z from 'zod';

import {
  EnumExplanationTimeUnit,
  EnumLearningTimeUnit,
  EnumPositionStudyLearningPositionCriticality,
  Priority,
} from 'src/Services/API';

export const learningSchema = z
  .object({
    skillsWithPriority: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        priority: z.nativeEnum(Priority),
        rank: z.number(),
        tasks: z.array(z.string()).optional(),
      })
    ),
    cadenceDescription: z.string().optional(),
    attendantProductivity: z.string().optional(),
    workerProductivity: z.string().optional(),
    explanationTime: z
      .object({
        duration: z.string().optional(),
        unit: z.nativeEnum(EnumExplanationTimeUnit).optional(),
      })
      .optional(),
    learningTime: z
      .object({
        duration: z.string().optional(),
        unit: z.nativeEnum(EnumLearningTimeUnit).optional(),
      })
      .optional(),
    positionCriticality: z.nativeEnum(EnumPositionStudyLearningPositionCriticality),
  })
  .refine(
    ({ explanationTime }) => {
      if (
        explanationTime?.unit === undefined &&
        explanationTime?.duration !== undefined &&
        explanationTime?.duration.length > 0
      ) {
        return false;
      }
      return true;
    },
    { message: 'Vous devez sélectionner une unité', path: ['explanationTime'] }
  )
  .refine(
    ({ learningTime }) => {
      if (
        learningTime?.unit === undefined &&
        learningTime?.duration !== undefined &&
        learningTime?.duration.length > 0
      ) {
        return false;
      }
      return true;
    },
    { message: 'Vous devez sélectionner une unité', path: ['learningTime'] }
  );

export type LearningSchemaType = z.infer<typeof learningSchema>;
