import React from 'react';

import {
  MaterialsAndProducts as MaterialsAndProductsIcon,
  SuggestedMaterialsAndProducts,
} from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const MaterialsAndProducts = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.MATERIAUX_ET_PRODUITS}
      skillIcon={<MaterialsAndProductsIcon />}
      suggestedSkillIcon={<SuggestedMaterialsAndProducts />}
    />
  );
};

export default MaterialsAndProducts;
