import * as z from 'zod';

import { Habilitation } from 'src/Services/API';
export interface Props {
  onValidate: (electricHabilitation: Habilitation) => void;
  currentHabilitations: Habilitation[];
}

export const electricHabilitationSchema = z
  .object({
    tension: z.string(),
    current: z.string(),
    currentType: z.string(),
    selectedHabilitation: z.object({ id: z.string(), label: z.string() }),
    currentHabilitations: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
  })
  .refine(
    ({ currentHabilitations, selectedHabilitation }) => {
      if (selectedHabilitation !== undefined) {
        return (
          currentHabilitations
            ?.map(item => item.id)
            .find(item => item === selectedHabilitation.id) === undefined
        );
      }
      return true;
    },
    { message: 'Cette habilitation est déjà séléctionnée', path: ['currentHabilitations'] }
  );
