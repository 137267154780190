import * as z from 'zod';

import {
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
} from 'src/Services/API';

export const protectionEquipmentSchema = z.object({
  hasEpis: z.nativeEnum(EnumPositionStudyProtectionEquipmentsHasEpis),
  clientEpis: z.array(z.object({ id: z.string(), label: z.string() })),
  agencyEpis: z.array(z.object({ id: z.string(), label: z.string() })),
  hasEpcs: z.nativeEnum(EnumPositionStudyProtectionEquipmentsHasEpcs),
  mainEpcs: z.array(z.object({ id: z.string(), label: z.string() })),
  otherEpcs: z.string(),
});
