import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
} from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionStudiesWithStatistics, PositionstudiesService } from 'src/Services/API';

import { QueryKeys } from '../types';

const DEFAULT_EDP_PER_PAGE = 7;

export const useFetchPositionStudies = (
  filters: Partial<
    Omit<
      // We use required because as any is autogenerated in arg typing, resulting in weak typing
      Required<
        Parameters<typeof PositionstudiesService.positionStudiesControllerGetPositionStudies>
      >[0],
      'agencyId' | 'brandCode'
    >
  >,
  paginate?: boolean,
  view?: string,
  extraOptions?: UseInfiniteQueryOptions<
    PositionStudiesWithStatistics,
    unknown,
    PositionStudiesWithStatistics,
    PositionStudiesWithStatistics,
    (
      | string
      | Partial<
          Omit<
            // We use required because as any is autogenerated in arg typing, resulting in weak typing
            Required<
              Parameters<typeof PositionstudiesService.positionStudiesControllerGetPositionStudies>
            >[0],
            'agencyId' | 'brandCode'
          >
        >
      | undefined
    )[]
  > & {
    skipCountRequest?: boolean;
  }
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);

  const queryResult = useInfiniteQuery(
    [
      QueryKeys.fetchPositionStudies,
      filters,
      currentAgencyId,
      currentBrand?.brandCodeApiHeader,
      view,
    ],
    async ({ pageParam = 0 }: QueryFunctionContext) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      if (!currentAgencyId) {
        return Promise.reject('No current agency');
      }

      const offset = paginate ? pageParam * DEFAULT_EDP_PER_PAGE : 0;
      const limit = paginate ? DEFAULT_EDP_PER_PAGE : undefined;

      const positionStudiesResponse: PositionStudiesWithStatistics =
        await PositionstudiesService.positionStudiesControllerGetPositionStudies({
          offset,
          limit,
          agencyId: currentAgencyId ?? '',
          brandCode: currentBrand.brandCodeApiHeader,
          ...filters,
        });

      if (!extraOptions?.skipCountRequest) {
        const totalPositionStudiesCount =
          await PositionstudiesService.positionStudiesControllerGetPositionStudiesCount({
            agencyId: currentAgencyId ?? '',
            brandCode: currentBrand.brandCodeApiHeader,
            ...filters,
          });

        return {
          ...positionStudiesResponse,
          count: totalPositionStudiesCount,
        };
      }

      return positionStudiesResponse;
    },
    {
      getNextPageParam: (lastPage: PositionStudiesWithStatistics, allPages) => {
        if (!paginate) return undefined;

        const allStudiesCount = allPages.reduce(
          (acc, page) => acc + (page.positionStudies?.length ?? 0),
          0
        );
        const lastPageCount = lastPage.count ?? 0;

        return allStudiesCount < lastPageCount ? allPages.length : undefined;
      },
      enabled: currentBrand !== undefined,
      staleTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );

  const countDisplayedStudies =
    queryResult.data?.pages.reduce((acc, page) => acc + (page.positionStudies?.length ?? 0), 0) ??
    0;

  return {
    ...queryResult,
    countDisplayedStudies,
  };
};
