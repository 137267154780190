import { ComboBox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCompanyServices } from 'src/Hooks/Companies/useFetchCompanyServices';
import { CompanyService } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import { getDevice } from '../../../../Redux/UserPreferences/Selectors';
import { PracticalContext } from '../PracticalContext';

import styles from './ServiceSelection.module.scss';
import { Props } from './ServiceSelection.types';

const ServiceSelection = ({ companyId }: Props) => {
  const practicalContext = useContext(PracticalContext);
  const { isLoading, isError, isSuccess, data } = useFetchCompanyServices(companyId ?? '');
  const isMobile = useSelector(getDevice);

  const serviceResolver = useController({ name: 'service', control: practicalContext.control });
  return (
    <div>
      <h2>service associé</h2>
      <WithLightTitle
        title="liste des services associés"
        className={isMobile ? styles.mobileComboboxContainer : styles.comboboxContainer}
      >
        {isLoading && (
          <ContentLoader height="3.5rem" width="50%" uniqueKey="ServiceSelection">
            <rect x="0" rx="4" ry="4" width="2000" height="3.5rem" />
          </ContentLoader>
        )}

        {isError && (
          <ErrorMessage message="Une erreur est survenue dans la récupération des services" />
        )}
        {isSuccess && (
          <ComboBox
            id="servicePicker"
            value={serviceResolver.field.value}
            keyValueExtractor={(service: CompanyService) => ({
              key: service.serviceId ?? '',
              value: service.name ?? '',
            })}
            onChange={service => {
              serviceResolver.field.onChange(service);
            }}
            searchResults={data ?? []}
            useNativeOptionFiltering
            fetchStatus={toFetchStatus({ isError, isLoading, isSuccess })}
          />
        )}
      </WithLightTitle>
    </div>
  );
};

export default ServiceSelection;
