import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import { ProfileSchemaType } from './Profile.types';

export const ProfileContext = React.createContext({
  control: {} as Control<ProfileSchemaType>,
  watch: ((_name: string) => null) as UseFormWatch<ProfileSchemaType>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submit: (): void => {},
});
