import * as z from 'zod';

import { WorkingHoursItem } from 'src/Services/API';

import { workingHoursItem, defaultWorkingHoursItem } from './WorkingHourItem';

export const workingHoursSchema = z.array(workingHoursItem);

const defaultWorkingHours = [defaultWorkingHoursItem];

export function removeUndefinedWorkingHours(
  possiblyUndefinedWorkingHours: z.infer<typeof workingHoursSchema>
) {
  return possiblyUndefinedWorkingHours.filter(
    (workingHoursItem): workingHoursItem is WorkingHoursItem =>
      !!workingHoursItem?.startTime && !!workingHoursItem?.endTime && !!workingHoursItem?.days
  );
}

export function getWorkingHoursDefaultValue(psWorkingHours?: WorkingHoursItem[]) {
  return (psWorkingHours?.length ?? 0) > 0 ? psWorkingHours : defaultWorkingHours;
}
