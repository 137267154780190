import * as z from 'zod';

import { EnumSalaryTimeRate, Salary, SMIC } from 'src/Services/API';

export enum SalaryFormat {
  'fixe' = 'fixe',
  'fourchette' = 'fourchette',
}

function isEmptyOrUndefined(value?: string) {
  return value === undefined || value === '';
}

function isGreaterThanSMIC(
  amount: string,
  salaryTimeRate: EnumSalaryTimeRate,
  smic?: SMIC['smic']
) {
  const parsedAmount = parseFloat(amount);

  switch (salaryTimeRate) {
    case EnumSalaryTimeRate.horaire:
      return parsedAmount >= (smic?.hourlyAmount ?? 0);
    case EnumSalaryTimeRate.mensuelle:
      return parsedAmount >= (smic?.monthlyAmount ?? 0);
    case EnumSalaryTimeRate.annuelle:
      return parsedAmount >= (smic?.annualAmount ?? 0);
  }
}

const salaryRawSchema = z.object({
  salaryTimeRate: z.nativeEnum(EnumSalaryTimeRate),
  salaryFormat: z.string(),
  isSmic: z.boolean(),
  salaryFixedAmount: z.string().optional(),
  salaryInBetween: z
    .object({
      salaryLowBound: z.string().optional(),
      salaryHighBound: z.string().optional(),
    })
    .optional(),
});

/**
 * With in between refinement
 */
const salarySchema = salaryRawSchema
  .refine(({ salaryFormat, salaryFixedAmount, isSmic }) => {
    if (salaryFormat === SalaryFormat.fixe && !isSmic) {
      return !isEmptyOrUndefined(salaryFixedAmount);
    }

    return true;
  }, 'Veuillez renseigner un montant')
  .refine(({ salaryFormat, salaryInBetween }) => {
    if (salaryFormat === SalaryFormat.fourchette) {
      return !isEmptyOrUndefined(salaryInBetween?.salaryLowBound);
    }

    return true;
  }, 'Veuillez renseigner une fourchette basse')
  .refine(({ salaryFormat, salaryInBetween }) => {
    if (
      salaryFormat === SalaryFormat.fourchette &&
      !isEmptyOrUndefined(salaryInBetween?.salaryLowBound)
    ) {
      return !isEmptyOrUndefined(salaryInBetween?.salaryHighBound);
    }

    return true;
  }, 'Veuillez renseigner une fourchette haute')
  .refine(({ salaryFormat, salaryInBetween }) => {
    if (
      salaryFormat === SalaryFormat.fourchette &&
      !isEmptyOrUndefined(salaryInBetween?.salaryLowBound) &&
      !isEmptyOrUndefined(salaryInBetween?.salaryHighBound)
    ) {
      return (
        parseFloat(salaryInBetween?.salaryLowBound ?? '') <
        parseFloat(salaryInBetween?.salaryHighBound ?? '')
      );
    }

    return true;
  }, 'La fourchette basse doit être inférieure à la fourchette haute');

/**
 * With greater than SMIC refinement
 */
export function getSalarySchema(smic?: SMIC['smic']) {
  return salarySchema
    .refine(({ salaryFormat, salaryFixedAmount, salaryTimeRate, isSmic }) => {
      if (salaryFormat === SalaryFormat.fixe && !isSmic && salaryFixedAmount) {
        return isGreaterThanSMIC(salaryFixedAmount, salaryTimeRate, smic);
      }

      return true;
    }, 'Le montant doit être supérieur au SMIC')
    .refine(({ salaryFormat, salaryTimeRate, salaryInBetween }) => {
      if (
        salaryFormat === SalaryFormat.fourchette &&
        !isEmptyOrUndefined(salaryInBetween?.salaryLowBound)
      ) {
        return isGreaterThanSMIC(salaryInBetween?.salaryLowBound ?? '', salaryTimeRate, smic);
      }

      return true;
    }, 'Le montant doit être supérieur au SMIC');
}

export function convertToSalary(zodSalary: z.infer<typeof salarySchema>): Salary | undefined {
  if (zodSalary.salaryFormat === SalaryFormat.fourchette) {
    if (
      zodSalary.salaryInBetween === undefined ||
      isEmptyOrUndefined(zodSalary.salaryInBetween?.salaryLowBound) ||
      isEmptyOrUndefined(zodSalary.salaryInBetween?.salaryHighBound)
    ) {
      return undefined;
    }

    return {
      amounts: [
        parseFloat(zodSalary.salaryInBetween?.salaryLowBound ?? ''),
        parseFloat(zodSalary.salaryInBetween?.salaryHighBound ?? ''),
      ],
      timeRate: zodSalary.salaryTimeRate,
      isSmic: zodSalary.isSmic,
    };
  }

  if (!zodSalary.isSmic && isEmptyOrUndefined(zodSalary.salaryFixedAmount)) return undefined;

  return {
    amounts: [parseFloat(zodSalary.salaryFixedAmount ?? '')],
    timeRate: zodSalary.salaryTimeRate,
    isSmic: zodSalary.isSmic,
  };
}

export function getSalaryDefaultValue(salary?: Salary): z.infer<typeof salaryRawSchema> {
  const isFixedSalary = salary?.amounts.length === 1;
  const isInBetweenSalary = salary?.amounts.length === 2;

  return {
    isSmic:
      salary?.isSmic ??
      !(
        (isFixedSalary && salary?.amounts[0].toString()) ||
        (isInBetweenSalary && salary?.amounts[0].toString() && salary?.amounts[1].toString())
      ),
    salaryTimeRate: salary?.timeRate ?? EnumSalaryTimeRate.horaire,
    salaryFixedAmount: isFixedSalary ? salary?.amounts[0].toString() : undefined,
    salaryFormat: isInBetweenSalary ? SalaryFormat.fourchette : SalaryFormat.fixe,
    salaryInBetween: {
      salaryLowBound: isInBetweenSalary ? salary?.amounts[0].toString() : undefined,
      salaryHighBound: isInBetweenSalary ? salary?.amounts[1].toString() : undefined,
    },
  };
}
