import { useQuery } from '@tanstack/react-query';

import { QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualificationRelatedSkills = (qualificationId?: string) => {
  return useQuery(
    [QueryKeys.fetchQualificationRelatedSkills, qualificationId],
    () => {
      return QualificationsService.qualificationsControllerGetQualificationRelatedSkills({
        qualificationId: qualificationId ?? '',
      });
    },
    {
      enabled: qualificationId !== undefined,
      staleTime: 600000,
    }
  );
};
