import { LogoutOptions } from '@auth0/auth0-react';

import history from 'src/Utils/history';

import { AxiosInterceptors } from './types';

let getAccessTokenSilently: (() => Promise<string>) | null = null;
let logout: ((options?: LogoutOptions | undefined) => void) | null = null;

export const security = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: () => Promise<string>) => (getAccessTokenSilently = func),
  logout: () => logout,
  setLogout: (func: (options?: LogoutOptions | undefined) => void) => (logout = func),
};

const axiosInterceptors: AxiosInterceptors = {
  requestInterceptor: async config => {
    const token = await security.getAccessTokenSilently()?.();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  },
  errorInterceptor: async error => {
    const { response, config } = error;
    if (config && response?.status === 401) {
      security.logout()?.({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI });
      history.push('/login');
    }

    return Promise.reject(error);
  },
};

export default axiosInterceptors;
