import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Habilitation, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDrivingLicensesHabilitations = (
  extraOptions?: UseQueryOptions<Habilitation[], unknown, Habilitation[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchDrivingLicensesHabilitations],
    async () => {
      return RepositoriesService.repositoriesControllerGetDrivingLicensesHabilitations();
    },
    { ...extraOptions }
  );
};
