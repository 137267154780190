import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import z from 'zod';

import { diplomasSchema } from './Diplomas.component';

export const DiplomasContext = React.createContext({
  control: {} as Control<z.infer<typeof diplomasSchema>>,
  watch: ((_name: string) => null) as UseFormWatch<z.infer<typeof diplomasSchema>>,
  setValue: ((_name: string, _value: unknown) => null) as UseFormSetValue<
    z.infer<typeof diplomasSchema>
  >,
  diplomasHandleSubmit: () => () => Promise.resolve(),
});
