import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, Risk } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchStrenuousFactors = (
  extraOptions?: UseQueryOptions<Risk[], unknown, Risk[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchStrenuousFactors],
    async () => {
      const strenuousFactors =
        await RepositoriesService.repositoriesControllerGetWorkingConditions();
      return strenuousFactors;
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
