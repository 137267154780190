import classNames from 'classnames';

import {
  DefaultIcon,
  SectorBTP,
  SectorEvent,
  SectorHotel,
  SectorIndustry,
  SectorService,
  SectorTransportation,
} from 'src/Assets';
import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { useFetchSector } from 'src/Hooks/Qualifications';
import { ACTIVITY_SECTORS } from 'src/Utils/constants';

import styles from './SectorSelection.module.scss';
import { Props } from './SectorSelection.types';

const iconMapping: { [id: string]: JSX.Element } = {
  [ACTIVITY_SECTORS.BTP]: <SectorBTP />,
  [ACTIVITY_SECTORS.TRADE_AND_SERVICES]: <SectorService />,
  [ACTIVITY_SECTORS.TRANSPORTATION]: <SectorTransportation />,
  [ACTIVITY_SECTORS.INDUSTRY]: <SectorIndustry />,
  [ACTIVITY_SECTORS.EVENTS]: <SectorEvent />,
  [ACTIVITY_SECTORS.HOTELS]: <SectorHotel />,
};

const SectorSelection = ({
  selectedSector,
  onSectorChange,
  isVariableSize,
  displayWarning,
  ...selectionIconButtonsFormProps
}: Props) => {
  const { isLoading, isError, isSuccess, data: sectors } = useFetchSector();

  return (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      displaySelected={false}
      isLoading={isLoading}
      loadingItems={6}
      isError={isError}
      error="Une erreur est survenue lors de la récupération des secteurs d'activités"
      isSuccess={isSuccess}
      selectIconButtonLoaderClassName={styles.container}
      data={
        sectors?.map(sector => ({
          key: sector.id,
          label: sector.label,
          icon: iconMapping[sector.id] ?? <DefaultIcon />,
          selected: sector.id === selectedSector?.id,
          onClick: () => {
            if (sector.id !== selectedSector?.id) {
              onSectorChange(sector);
            } else {
              onSectorChange({ id: '', label: '' });
            }
          },
        })) ?? []
      }
      isVariableSize={isVariableSize}
      selectIconButtonClassName={classNames({ [styles.warningSectors]: displayWarning })}
    />
  );
};

export default SectorSelection;
