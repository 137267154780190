import {
  Checkbox,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { EuroSign } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { useFetchSMICAmount } from 'src/Hooks/PositionStudies';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import styles from './SalaryFixedAmount.module.scss';
import { Props } from './SalaryFixedAmount.types';

const SalaryFixedAmount = ({ value, onChange }: Props) => {
  const isMobile = useSelector(getDevice);
  const { data } = useFetchSMICAmount();

  return (
    <div className={isMobile ? styles.mobileContainer : styles.container}>
      <div className={styles.smicBoxContainer}>
        <Checkbox
          label="SMIC horaire brut"
          checked={value.isSmic}
          onChange={() => {
            onChange({ ...value, isSmic: !value.isSmic });
          }}
        />
      </div>
      <WithLightTitle title="montant">
        <TextInput
          key={value.isSmic ? 'SMIC' : 'salaryFixedAmount'}
          type="number"
          defaultValue={
            value.isSmic ? data?.smic.hourlyAmount?.toString() : value.salaryFixedAmount
          }
          disabled={value.isSmic}
          containerClassName={classnames({ [styles.salaryDisabled]: value.isSmic })}
          onChange={({ target }) => {
            onChange({
              ...value,
              salaryFixedAmount: (target as HTMLInputElement).value,
            });
          }}
          rightIcon={<EuroSign />}
        />
      </WithLightTitle>
    </div>
  );
};

export default SalaryFixedAmount;
