import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, Skill } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchSkills = (
  { keyword, type }: { keyword: string; type: string },
  extraOptions?: UseQueryOptions<Skill[], unknown, Skill[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchSkills, type, keyword],
    async () => {
      return RepositoriesService.repositoriesControllerGetSkills({
        keyword,
        type,
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
