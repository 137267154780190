import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionstudiesService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useGeneratePositionStudyPdf = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const user = useAuthenticatedUser();
  return useMutation(
    [MutationKeys.generatePositionStudyPdf],
    async (positionStudyId: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const positionStudy =
        await PositionstudiesService.positionStudiesControllerGetPositionStudyPdf(
          { id: positionStudyId, body: { brandCode: currentBrand?.brandCodeApiHeader, user } },
          { responseType: 'arraybuffer' }
        );
      return positionStudy;
    },
    {
      ...extraOptions,
    }
  );
};
