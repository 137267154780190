import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, Habilitation } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchHabilitations = (
  { keyword }: { keyword: string },
  extraOptions?: UseQueryOptions<Habilitation[], unknown, Habilitation[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchHabilitations, keyword],
    async () => {
      return RepositoriesService.repositoriesControllerGetHabilitations({
        keyword,
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
