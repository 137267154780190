import { Message } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './CompletionStatusMessages.module.scss';
import { Props, completionErrorMessagesMapping } from './CompletionStatusMessages.types';

const CompletionStatusMessagesComponent = ({ missingRequiredFields, customMessage }: Props) => {
  return (
    <div>
      <Message.Warning className={styles.completionMessage}>
        <ul className={styles.messagesList}>
          {customMessage ? (
            <li>{customMessage}</li>
          ) : (
            missingRequiredFields?.map((missingRequiredField, index: number) => (
              <li key={index}>
                {
                  completionErrorMessagesMapping[
                    missingRequiredField as keyof typeof completionErrorMessagesMapping
                  ]
                }
              </li>
            ))
          )}
        </ul>
      </Message.Warning>
    </div>
  );
};

export default CompletionStatusMessagesComponent;
