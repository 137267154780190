import { UseMutationOptions, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { DetailedPositionStudy, PositionstudiesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useDuplicateEDPWithNewQualification = (
  extraOptions?: UseMutationOptions<
    DetailedPositionStudy,
    unknown,
    { positionStudyId: string; body: { positionStudyName: string; qualificationId: string } }
  >
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.duplicateEDPWithNewQualification, currentBrand?.brandCodeApiHeader],
    async (params: {
      positionStudyId: string;
      body: { positionStudyName: string; qualificationId: string };
    }) => {
      if (!currentBrand?.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const updatedPositionStudy =
        await PositionstudiesService.positionStudiesControllerDuplicatePositionStudyWithNewQualification(
          {
            id: params.positionStudyId,
            body: {
              qualificationId: params.body.qualificationId,
              positionStudyName: params.body.positionStudyName,
              brandCode: currentBrand.brandCodeApiHeader,
            },
          }
        );
      return updatedPositionStudy;
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudies'] });
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudiesCount'] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
