import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router';

import { IllusEDPNoStudy } from 'src/Assets';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import PositionStudyCard from 'src/Components/PositionStudyCard';
import StudyActions from 'src/Components/StudyActions';
import { useFetchPositionStudies } from 'src/Hooks/PositionStudies';
import { getEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './PositionStudyList.module.scss';
import { Props } from './PositionStudyList.types';

const PositionStudyList = ({ companyId }: Props) => {
  const history = useHistory();
  const {
    isSuccess,
    isLoading,
    isError,
    data: positionStudiesPages,
    isFetching,
    hasNextPage,
    fetchNextPage,
    countDisplayedStudies,
  } = useFetchPositionStudies({
    companyId,
  });
  return (
    <div>
      {isLoading && (
        <>
          <div className={styles.numberOfPositionStudies}>
            <ContentLoader
              height="2rem"
              width="12rem"
              uniqueKey="edpNumber"
              backgroundColor="#d7d7d7"
            >
              <rect x="0%" y="10" rx="4" ry="4" width="100%" height="15" />
            </ContentLoader>
          </div>

          {new Array(5).fill(undefined).map((_, index) => (
            <PositionStudyCard.Loading key={`ps-card-loading-${index}`} />
          ))}
        </>
      )}

      {isError && <ErrorMessage message="Une erreur est survenue dans la récupération des EDP" />}

      {isSuccess && (
        <>
          <div className={styles.numberOfPositionStudies}>
            {pluralFormat(countDisplayedStudies, 'EDP enregistrée')}
          </div>
          {countDisplayedStudies < 1 && <IllusEDPNoStudy />}
          {positionStudiesPages?.pages.map(positionStudyGroup =>
            positionStudyGroup.positionStudies?.map(positionStudy => (
              <PositionStudyCard
                title={positionStudy.positionStudyName ?? ''}
                completionStatus={positionStudy.completionStatus}
                createdAt={positionStudy.creationDate}
                updatedAt={positionStudy.modificationDate}
                service={positionStudy.service?.name}
                rightActions={
                  <StudyActions
                    companyId={positionStudy.company?.companyId}
                    positionStudyId={positionStudy.positionStudyId}
                    positionStudyName={positionStudy.positionStudyName}
                    companyName={positionStudy.company?.name}
                  />
                }
                onClick={() => {
                  history.push(
                    getEditionRoute({
                      companyId,
                      positionStudyId: positionStudy.positionStudyId,
                      section: 'hr/profile',
                    })
                  );
                }}
              />
            ))
          )}
          <div className={styles.displayMore}>
            {isFetching && (
              <ContentLoader
                height="5rem"
                width="8rem"
                uniqueKey="displayMore"
                backgroundColor="#d7d7d7"
              >
                <rect x="0%" y="0" rx="4" ry="4" width="100%" height="50" />
              </ContentLoader>
            )}
            {!isFetching && hasNextPage && (
              <Button.Tertiary
                onClick={() => {
                  fetchNextPage();
                }}
              >
                afficher plus
              </Button.Tertiary>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PositionStudyList;
