import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './HorizontalCardWithIconLoader.module.scss';

const HorizontalCardWithIconLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="4.5rem" width="100%" uniqueKey="hours">
      <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
    </ContentLoader>
  </div>
);

export default HorizontalCardWithIconLoader;
