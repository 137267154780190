import {
  Button,
  HorizontalTextIconCard,
  Modal,
  PopupActions,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  Pencil,
  PlusCircle,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';

import { EDPSkillsHeader } from 'src/Assets';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './SkillTaskModal.module.scss';
import { Props, tasksModalSchema } from './SkillTaskModal.types';

const SkillTaskModal = ({ skill, onValidate, onClose }: Props) => {
  const ref = useRef<PopupActions>(null);
  const { control, handleSubmit, setValue } = useFormWithZodResolver({
    schema: tasksModalSchema,
    defaultValues: {
      currentTask: '',
      tasks: skill.tasks ?? [],
    },
  });

  const tasksResolver = useController({
    name: 'tasks',
    control,
  });

  const currentTaskSubmit = useCallback(() => {
    handleSubmit(data => {
      tasksResolver.field.onChange([...data.tasks, data.currentTask]);
      setValue('currentTask', '');
    })();
  }, [handleSubmit, tasksResolver.field, setValue]);

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        currentTaskSubmit();
      }
    },
    [currentTaskSubmit]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => document.removeEventListener('keydown', handleEnter);
  }, [handleEnter]);

  return (
    <Modal
      ref={ref}
      title="détail de la compétence"
      icon={<EDPSkillsHeader />}
      onClose={onClose}
      trigger={<PopupMenu.Item icon={<Pencil />} text="consulter / modifier" keepOpenOnClick />}
      footerActionsLeft={[
        <Button.Tertiary onClick={() => setValue('tasks', skill.tasks ?? [])}>
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            onClose?.();
            ref.current?.close();
          }}
        >
          annuler
        </Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onValidate?.(tasksResolver.field.value);
            onClose?.();
            ref.current?.close();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title="nom de compétence" className={styles.titleContainer}>
        <div>{skill?.label}</div>
      </WithLightTitle>
      <WithLightTitle title="tâches pour cette mission">
        <TextInput
          rightIcon={
            <div onClick={currentTaskSubmit}>
              <PlusCircle />
            </div>
          }
          name={'currentTask'}
          placeholder={`saisir une tâche et appuyer sur "Entrée" pour l'ajouter`}
          control={control}
        />
      </WithLightTitle>
      {tasksResolver.field.value.length > 0 && (
        <WithLightTitle
          title={`${pluralFormat(tasksResolver.field.value.length, 'tâche ajoutée')}`}
          className={styles.listTitleContainer}
        >
          <div className={styles.listContainer}>
            {tasksResolver.field.value.map(task => (
              <HorizontalTextIconCard
                key={task}
                label={task}
                containerClassName={styles.task}
                rightComponent={
                  <Button.Tertiary
                    className={styles.trashIcon}
                    onClick={() =>
                      tasksResolver.field.onChange(
                        tasksResolver.field.value.filter(taskItem => taskItem !== task)
                      )
                    }
                  >
                    <Trashcan />
                  </Button.Tertiary>
                }
              />
            ))}
          </div>
        </WithLightTitle>
      )}
    </Modal>
  );
};

export default SkillTaskModal;
