import SelectQualifications from 'src/Containers/Selects/SelectQualifications';
import { Qualification } from 'src/Services/API';

import { SectorSelection } from './SectorSelection';
import { Props } from './SectorSelectionModalContent.types';

const SectorSelectionModalContent = ({
  selectedSector,
  onSectorChange,
  selectedQualification,
  onQualificationChange,
}: Props) => {
  return (
    <>
      <SectorSelection
        title="sélectionnez un secteur d'activité"
        selectedSector={selectedSector}
        onSectorChange={onSectorChange}
      />
      <SelectQualifications
        selectedQualification={selectedQualification}
        setQualification={(qualification?: Qualification) => {
          onQualificationChange(qualification);
        }}
        title={'et/ou ajoutez une qualification'}
      />
    </>
  );
};

export default SectorSelectionModalContent;
