import { PositionStudyGeneralInformation } from 'src/Services/API';

export const formatGeneralInformationForModification = (
  brandCode?: string,
  generalInformation?: PositionStudyGeneralInformation
) => {
  return {
    positionStudyName: generalInformation?.positionStudyName ?? '',
    companyId: generalInformation?.company?.companyId ?? '',
    qualificationId: generalInformation?.qualification?.id ?? '',
    agencyId: generalInformation?.agencyId ?? '',
    brandCode: brandCode ?? '',
  };
};
