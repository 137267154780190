import {
  DurationPicker,
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.component';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader/RequiredFieldHeader.component';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { toFetchStatus } from 'src/Services/Async';
import { hoursToNumber, numberToHours } from 'src/Utils/durationHelpers';

import {
  BonusRatesSelection,
  convertToSalary,
  ExperienceSelection,
  getSalaryDefaultValue,
  getWorkingHoursDefaultValue,
  removeUndefinedWorkingHours,
  SalarySelection,
  StatusSelection,
  WorkingHours,
} from './Components';
import { removeEmptyBonusRateId } from './Components/BonusRatesSelection/BonusRatesSelection.schema';
import styles from './Profile.module.scss';
import { useProfileSchema } from './Profile.schema';
import { ProfileContext } from './ProfileContext';

const Profile = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const profileSection = fetchPositionStudy.data?.profileSection;
  const profileSchema = useProfileSchema();

  const dispatch = useDispatch();
  const requiredFields = useSelector(getRequiredFields);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: profileSchema,
    defaultValues: {
      positionStatus: profileSection?.positionStatus,
      practice: profileSection?.practice ?? -1,
      weekHours: numberToHours(profileSection?.weekHours),
      workingHours: getWorkingHoursDefaultValue(profileSection?.workingHours),
      salary: getSalaryDefaultValue(profileSection?.salary),
      bonusRates: profileSection?.bonusRates,
    },
  });

  const positionStatus = watch('positionStatus');
  const practice = watch('practice');
  const weekHours = watch('weekHours');
  const salary = watch('salary');
  const bonusRates = watch('bonusRates');

  const missingRequiredFields = [
    positionStatus === undefined && 'positionStatus',
    (practice === undefined || practice === -1) && 'practice',
    (weekHours === undefined || (weekHours.hours === 0 && weekHours.minutes === 0)) && 'weekHours',
  ].filter(Boolean);

  const profileHandleSubmit = useCallback(
    () =>
      handleSubmit(data => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            profileSection: {
              positionStatus: data.positionStatus ?? positionStatus,
              practice: data.practice,
              weekHours: hoursToNumber(data.weekHours),
              workingHours: removeUndefinedWorkingHours(data.workingHours),
              salary: convertToSalary(data.salary),
              bonusRates: removeEmptyBonusRateId(data.bonusRates ?? bonusRates),
            },
          },
        });
        reset(data, { keepValues: true });
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation, bonusRates, positionStatus]
  );

  const submit = profileHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionStatus, practice, weekHours, salary]);

  useEffect(() => {
    const hasMissingPositionStatus = missingRequiredFields.includes('positionStatus');
    const hasMissingPractice = missingRequiredFields.includes('practice');
    const hasWeekHours = missingRequiredFields.includes('weekHours');

    const newRequiredFields = {
      ...requiredFields,
      hr: {
        ...requiredFields.hr,
        profile: {
          ...requiredFields.hr.profile,
          positionStatus: hasMissingPositionStatus,
          practice: hasMissingPractice,
          weekHours: hasWeekHours,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionStatus, practice, weekHours]);

  return (
    <ProfileContext.Provider
      value={{
        control,
        watch,
        submit,
      }}
    >
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.container}>
          {missingRequiredFields.length > 0 && (
            <CompletionStatusMessagesComponent missingRequiredFields={missingRequiredFields} />
          )}
          <h2 className={styles.title}>information globale</h2>
          <div className={classnames({ [styles.elementContainer]: !isMobile })}>
            <RequiredFieldHeader fieldHeader="statut" isMobile={isMobile} />
            <StatusSelection />
            {positionStatus === undefined && (
              <div className={styles.warningMessage}>statut manquant</div>
            )}
          </div>

          <div className={classnames({ [styles.elementContainer]: !isMobile })}>
            <RequiredFieldHeader fieldHeader="expérience" isMobile={isMobile} />
            <ExperienceSelection />
            {practice === -1 && <div className={styles.warningMessage}>expérience à compléter</div>}
          </div>
          <div className={classnames({ [styles.elementContainer]: !isMobile })}>
            <RequiredFieldHeader fieldHeader="base horaire hebdomadaire" isMobile={isMobile} />
            <DurationPicker
              name="weekHours"
              control={control}
              containersClassName={classnames({
                [styles.warningDuration]: weekHours.hours === 0 && weekHours.minutes === 0,
              })}
            />
            {weekHours.hours === 0 && weekHours.minutes === 0 && (
              <div className={styles.warningMessage}>horaire manquant</div>
            )}
          </div>
          <>
            <WorkingHours />
          </>
          <div className={styles.separator} />
          <h2 className={styles.title}>montant salaire</h2>
          <SalarySelection />
          <div className={styles.separator} />
          <h2 className={styles.title}>primes</h2>
          <div className={isMobile ? styles.mobileBonusContainer : styles.bonusContainer}>
            <BonusRatesSelection />
          </div>
        </div>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </ProfileContext.Provider>
  );
};

export default Profile;
