import {
  Button,
  Modal,
  PopupActions,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusTransfert,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { IllusEDPWarning } from 'src/Assets';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrand,
} from 'src/Redux/Perimeter/Selectors';
import { EnumDetailedPositionStudyCompletionStatus } from 'src/Services/API';
import { missionCreationPilotAgencies } from 'src/Utils/missionCreationPilotAgencies';

import styles from './MissionCreationFlashModal.module.scss';
import { Props } from './MissionCreationFlashModal.types';

const MissionCreationFlashModal = ({ positionStudyId, positionStudyCompletionStatus }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const brandCode = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);
  const onClose = useCallback(() => {
    innerRef.current?.close();
  }, []);
  const openFlash = () => {
    ReactGA.event('Requête Flash Création de Commande', {
      ...userInfo,
    });
    window.open(
      `${process.env.REACT_APP_FLASH_URL}?edpId=${positionStudyId}&brandCode=${brandCode?.brandCodeApiHeader}`,
      '_blank',
      'noopener,noreferrer'
    );
  };
  return positionStudyCompletionStatus !== EnumDetailedPositionStudyCompletionStatus.UP_TO_DATE &&
    missionCreationPilotAgencies.includes(currentAgencyId ?? '') ? (
    <Modal
      ref={innerRef}
      title="bon à savoir"
      icon={<IllusEDPWarning className={styles.icon} />}
      trigger={<PopupMenu.Item keepOpenOnClick icon={<PlusCircle />} text="créer une commande" />}
      onClose={() => {
        onClose();
      }}
      footerActionsRight={[
        <Button.Primary
          onClick={() => {
            onClose();
          }}
        >
          ok
        </Button.Primary>,
      ]}
    >
      <p className={styles.text}>
        la création de commande ne peut se faire qu'à partir d'une EDP en statut à jour, veuillez
        compléter cette EDP.
      </p>
    </Modal>
  ) : (
    <Modal
      ref={innerRef}
      title="redirection vers Flash"
      icon={<IllusTransfert className={styles.icon} />}
      trigger={<PopupMenu.Item keepOpenOnClick icon={<PlusCircle />} text="créer une commande" />}
      onClose={() => {
        onClose();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            openFlash();
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <p className={styles.text}>
        Vous allez être redirigé vers Flash afin de créer une commande à partir des données de cette
        EDP
      </p>
    </Modal>
  );
};

export default MissionCreationFlashModal;
