import React from 'react';

import { ComputerScience as ComputerScienceIcon, SuggestedComputerScience } from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const ComputerScience = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.INFORMATIQUE}
      skillIcon={<ComputerScienceIcon />}
      suggestedSkillIcon={<SuggestedComputerScience />}
    />
  );
};

export default ComputerScience;
