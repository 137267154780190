import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IllusEDPNoStudyWhite } from 'src/Assets';
import DisplayModeSelector from 'src/Components/DisplayModeSelector';
import { DISPLAY_MODE } from 'src/Components/DisplayModeSelector/DisplayModeSelector.component';
import StatisticsComponent from 'src/Components/Statistics/Statistics.component';
import { STATISTICS_TYPES } from 'src/Components/Statistics/Statistics.types';
import StudyActions from 'src/Components/StudyActions';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getCardDisplayMode, getDevice } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { EnumPositionStudyCompletionStatus, PositionStudiesWithStatistics } from 'src/Services/API';
import { getEditionRoute } from 'src/Services/Routing';

import { EnumViewType } from '../Home.types';

import HomePositionStudyCard from './GridCard';
import GridOrListCardLoadMore from './GridOrListCardLoadMore';
import LoadMoreLoader from './GridOrListCardLoadMore/LoadMoreLoader';
import styles from './LastPositionStudies.module.scss';
import { Props } from './LastPositionStudies.types';
import { getTitle, mapStatisticsTypeToCompletionStatus } from './LastPositionStudies.utils';
import HomePositionStudyListCard from './ListCard';

export const LastPositionStudies = ({
  fetchPositionStudiesResult,
  totalPositionStudiesCount,
  displayedPositionStudiesCount,
  view,
}: Props) => {
  const isMobile = useSelector(getDevice);
  const unit = useSelector(getCurrentAgency);
  const history = useHistory();
  const dispatch = useDispatch();
  const [statusFilter, setStatusFilter] = useState(STATISTICS_TYPES.ALL);
  const extractCounts = () => {
    const lastPage = fetchPositionStudiesResult.data?.pages.slice(-1)[0];
    return {
      upToDateCount: lastPage?.upToDateCount ?? 0,
      pendingUpdateCount: lastPage?.pendingUpdateCount ?? 0,
      missingInfoCount: lastPage?.missingInfoCount ?? 0,
      riskyUpToDateCount: lastPage?.riskyUpToDateCount ?? 0,
      riskyPendingUpdateCount: lastPage?.riskyPendingUpdateCount ?? 0,
      riskyMissingInfoCount: lastPage?.riskyMissingInfoCount ?? 0,
    };
  };

  const {
    upToDateCount,
    pendingUpdateCount,
    missingInfoCount,
    riskyUpToDateCount,
    riskyPendingUpdateCount,
    riskyMissingInfoCount,
  } = extractCounts();
  const [filteredStudiesNb, setFiltedStudiesNb] = useState<number>(-1);
  // Very big screen (31inch) is approximatively 3 rows of 8 cards
  const savedDisplayMode = useSelector(getCardDisplayMode);
  const displayMode = isMobile ? DISPLAY_MODE.GRID : savedDisplayMode;

  const onDisplayModeChange = useCallback(
    (newDisplayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(newDisplayMode));
    },
    [dispatch]
  );

  const CardOrGrid =
    displayMode === DISPLAY_MODE.GRID ? HomePositionStudyCard : HomePositionStudyListCard;
  const filteredPositionStudies = (positionStudyGroup: PositionStudiesWithStatistics) => {
    if (view === EnumViewType.LATEST) {
      return positionStudyGroup.positionStudies;
    }
    let completionStatusToMatch: EnumPositionStudyCompletionStatus | null = null;
    if (statusFilter !== STATISTICS_TYPES.RISKY_POSITION) {
      if (view === EnumViewType.ALL) {
        completionStatusToMatch = mapStatisticsTypeToCompletionStatus(statusFilter);
      }
      return (
        positionStudyGroup?.positionStudies?.filter(positionStudy => {
          return completionStatusToMatch
            ? positionStudy.completionStatus === completionStatusToMatch
            : true;
        }) ?? []
      );
    } else {
      return positionStudyGroup?.positionStudies?.filter(positionStudy => {
        return positionStudy.mase?.listRiskPositionFlag || positionStudy.mase?.riskPositionFlag;
      });
    }
  };

  useEffect(() => {
    setStatusFilter(STATISTICS_TYPES.ALL);
  }, [unit]);

  return (
    <div className={classnames(styles.greenBackground)}>
      <div className={styles.displayContainer}>
        <div className={styles.row}>
          <p className={styles.title}>
            {getTitle(
              fetchPositionStudiesResult,
              totalPositionStudiesCount,
              displayedPositionStudiesCount,
              view,
              unit ?? '',
              statusFilter,
              upToDateCount,
              pendingUpdateCount,
              missingInfoCount,
              riskyUpToDateCount,
              riskyPendingUpdateCount,
              riskyMissingInfoCount
            )}
          </p>
          {!isMobile && (
            <DisplayModeSelector
              displayMode={displayMode}
              onDisplayModeIndexUpdate={onDisplayModeChange}
            />
          )}
        </div>
        {fetchPositionStudiesResult.isSuccess &&
          !fetchPositionStudiesResult.isLoading &&
          !fetchPositionStudiesResult.isFetching &&
          view === EnumViewType.ALL &&
          totalPositionStudiesCount > 0 && (
            <StatisticsComponent
              upToDateCount={upToDateCount}
              pendingUpdateCount={pendingUpdateCount}
              missingInfoCount={missingInfoCount}
              riskyUpToDateCount={riskyUpToDateCount}
              riskyPendingUpdateCount={riskyPendingUpdateCount}
              riskyMissingInfoCount={riskyMissingInfoCount}
              total={totalPositionStudiesCount}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
            />
          )}
        <div
          className={classnames({
            [styles.studies]: true,
            [styles.grid]: displayMode === DISPLAY_MODE.GRID,
          })}
        >
          {!fetchPositionStudiesResult.isLoading &&
            !fetchPositionStudiesResult.isFetching &&
            fetchPositionStudiesResult.isSuccess &&
            fetchPositionStudiesResult.data?.pages.map(positionStudyGroup => {
              const filteredStudies = filteredPositionStudies(positionStudyGroup);
              if (filteredStudies && filteredStudies.length !== filteredStudiesNb) {
                setFiltedStudiesNb(filteredStudies.length);
              }
              return (
                filteredStudies &&
                filteredStudies.length > 0 &&
                filteredStudies.map(positionStudy => (
                  <CardOrGrid
                    key={positionStudy.positionStudyId}
                    qualification={positionStudy.qualification?.label ?? ''}
                    name={positionStudy.positionStudyName ?? ''}
                    completionStatus={positionStudy.completionStatus}
                    createdAt={positionStudy.creationDate}
                    updatedAt={positionStudy.modificationDate}
                    service={positionStudy.company?.name}
                    siret={positionStudy.company?.siret}
                    serviceAddress={`${positionStudy.company?.location?.zipCode} - ${positionStudy.company?.location?.cityName}`}
                    onClick={() => {
                      history.push(
                        getEditionRoute({
                          companyId: positionStudy.company?.companyId,
                          positionStudyId: positionStudy.positionStudyId,
                          section: 'hr/profile',
                        })
                      );
                    }}
                    rightActions={
                      <StudyActions
                        companyId={positionStudy.company?.companyId}
                        positionStudyId={positionStudy.positionStudyId}
                        positionStudyName={positionStudy.positionStudyName}
                        companyName={positionStudy.company?.name}
                      />
                    }
                  />
                ))
              );
            })}
          {(fetchPositionStudiesResult.isLoading ||
            fetchPositionStudiesResult.isFetching ||
            fetchPositionStudiesResult.isFetchingNextPage) &&
            new Array(7)
              .fill(undefined)
              .map((_, index) =>
                displayMode === DISPLAY_MODE.GRID ? (
                  <HomePositionStudyCard.Loading key={`homepositionstudycard-loading-${index}`} />
                ) : (
                  <HomePositionStudyListCard.Loading
                    key={`homepositionstudylist-loading-${index}`}
                  />
                )
              )}
          {fetchPositionStudiesResult.isSuccess &&
            !fetchPositionStudiesResult.isFetchingNextPage &&
            totalPositionStudiesCount === 0 && (
              <IllusEDPNoStudyWhite
                className={classnames({ [styles.noStudyImageMobile]: isMobile })}
              />
            )}
          {fetchPositionStudiesResult.isError && (
            <div>Une erreur est survenue dans la récupération des EDP</div>
          )}
          {fetchPositionStudiesResult.isSuccess && fetchPositionStudiesResult.hasNextPage && (
            <div
              onClick={() => {
                if (!fetchPositionStudiesResult.isFetchingNextPage) {
                  fetchPositionStudiesResult.fetchNextPage();
                }
              }}
            >
              {!fetchPositionStudiesResult.isFetchingNextPage &&
              !fetchPositionStudiesResult.isFetching ? (
                <GridOrListCardLoadMore displayMode={displayMode} />
              ) : (
                <LoadMoreLoader displayMode={displayMode} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LastPositionStudies;
