import {
  BlueBullet,
  GreenBullet,
  RedBullet,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { EnumPositionStudyCompletionStatus } from 'src/Services/API';

export const completionStatusMapping = {
  [EnumPositionStudyCompletionStatus.MISSING_INFO]: {
    icon: <RedBullet />,
    label: 'informations manquantes',
  },
  [EnumPositionStudyCompletionStatus.PENDING_UPDATE]: {
    icon: <BlueBullet />,
    label: 'mise à jour attendue',
  },
  [EnumPositionStudyCompletionStatus.UP_TO_DATE]: {
    icon: <GreenBullet />,
    label: 'à jour',
  },
};
export interface Props {
  title: string;
  completionStatus?: EnumPositionStudyCompletionStatus;
  createdAt?: Date;
  updatedAt?: Date;
  service?: string;
  onClick: () => void;
  rightActions: React.ReactNode;
}
