import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useContext, useEffect } from 'react';

import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumPositionStudyLicensesHasLicense } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import DrivingLicenseList from './DrivingLicenseList';
import styles from './DrivingLicenses.module.scss';
import { drivingLicensesSchema } from './DrivingLicenses.schema';

const DrivingLicenses = () => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const initialHabilitations = fetchPositionStudy.data?.habilitations;

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: drivingLicensesSchema,
    defaultValues: {
      hasLicense: fetchPositionStudy.data?.licenses?.hasLicense,
      drivingLicenses: fetchPositionStudy.data?.licenses?.licenses,
      habilitations: initialHabilitations,
    },
  });
  const hasLicense = watch('hasLicense');
  const habilitations = watch('habilitations');

  const drivingLicensesHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            licenses: {
              licenses:
                hasLicense === EnumPositionStudyLicensesHasLicense.Yes
                  ? values.drivingLicenses ?? []
                  : [],
              hasLicense: values.hasLicense,
            },
            habilitations: values.habilitations ?? [],
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, hasLicense, reset, updateMutation]
  );

  const submit = drivingLicensesHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLicense]);

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2 className={styles.title}>votre choix</h2>
          <WithLightTitle title="le candidat doit-il posséder des permis de conduire ?">
            <TripleChoiceButtonForm name="hasLicense" control={control} />
          </WithLightTitle>
          {hasLicense === EnumPositionStudyLicensesHasLicense.Yes && (
            <DrivingLicenseList control={control} habilitations={habilitations} submit={submit} />
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};
export default DrivingLicenses;
