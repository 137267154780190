import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader/RequiredFieldHeader.component';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchCompanyWorkingLocations } from 'src/Hooks/Companies';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { CompanyAddress } from 'src/Services/API';

import styles from './CompanyWorkingLocationPicker.module.scss';
import { Props } from './CompanyWorkingLocationPicker.types';

const CompanyWorkingLocationPicker = ({
  setSelectedCompanyWorkingLocation,
  selectedCompanyWorkingLocation,
}: Props) => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const { data: positionStudy } = positionStudyModificationContext.fetchPositionStudy;
  const companyId = positionStudy?.generalInformation.company?.companyId;
  const { isLoading, isError, isSuccess, data } = useFetchCompanyWorkingLocations(companyId ?? '');
  const placeholder = isMobile
    ? 'choisir parmi les adresses Osmose'
    : 'choisir parmi les adresses Osmose disponibles';
  return (
    <div>
      <RequiredFieldHeader fieldHeader="adresses disponibles avec Osmose" isMobile={isMobile} />
      {isLoading && (
        <ContentLoader height="3.5rem" width="50%" uniqueKey="StatusSelection">
          <rect x="0" rx="4" ry="4" width="2000" height="3.5rem" />
        </ContentLoader>
      )}

      {isError && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des adresses Osmose" />
      )}
      {isSuccess && (
        <DropDown
          placeholder={data ? placeholder : 'Aucune adresse renseignée sur ce compte'}
          items={data?.filter(item => item.cityName !== undefined) ?? []}
          keyValueExtractor={(searchResult: CompanyAddress) => ({
            key: `${searchResult.inseeCode}-${
              searchResult.zipCode
            }-${searchResult.address?.toLowerCase()}`,
            value: `${searchResult.address?.toLowerCase() ?? ''} ${searchResult.zipCode ?? ''} ${
              searchResult.cityName ?? ''
            }`,
          })}
          onSelectItem={(item?: CompanyAddress) => {
            setSelectedCompanyWorkingLocation(item);
          }}
          selectedItem={
            selectedCompanyWorkingLocation?.address !== undefined ||
            selectedCompanyWorkingLocation?.cityName !== undefined
              ? selectedCompanyWorkingLocation
              : undefined
          }
          canBeReset
          className={styles.workingLocationDropDown}
        />
      )}
    </div>
  );
};

export default CompanyWorkingLocationPicker;
