import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDevice } from '../../Redux/UserPreferences/Selectors';

import styles from './PositionStudyCard.module.scss';
import { Props, completionStatusMapping } from './PositionStudyCard.types';

const PositionStudyCard = ({
  title,
  completionStatus,
  createdAt,
  updatedAt,
  service,
  onClick,
  rightActions,
}: Props) => {
  const isMobile = useSelector(getDevice);

  return (
    <div className={isMobile ? styles.mobileContainer : styles.container} onClick={onClick}>
      <div className={styles.wrapper}>
        <div style={{ flex: 1 }}>
          <div className={isMobile ? styles.mobileTitle : styles.title}>{title}</div>
          <div className={styles.description}>
            <div>{service}</div>
            <div>
              {isMobile ? (
                <>
                  <p>date de création {moment(createdAt).format('L')},</p>
                  <p>dernière modification {moment(updatedAt).format('L')}</p>
                </>
              ) : (
                `date de création ${moment(createdAt).format('L')}, dernière modification ${moment(
                  updatedAt
                ).format('L')}`
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          {completionStatus && (
            <div className={styles.completionStatusContainer}>
              <div className={styles.completionStatusIcon}>
                {completionStatusMapping[completionStatus].icon}
              </div>
              <p className={styles.completionStatus}>
                {completionStatusMapping[completionStatus].label}
              </p>
            </div>
          )}
          {rightActions}
        </div>
      </div>
    </div>
  );
};

export default PositionStudyCard;
