import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, LanguagesRepository } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchLanguages = (
  extraOptions?: UseQueryOptions<LanguagesRepository, unknown, LanguagesRepository, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.useFetchLanguages],
    async () => {
      return RepositoriesService.repositoriesControllerGetLanguages();
    },
    extraOptions
  );
};
