import { DropDown, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BlueChip,
  GreenChip,
  RedChip,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useCallback } from 'react';

import styles from './Statistics.module.scss';
import { Props, STATISTICS_TYPES } from './Statistics.types';

const StatisticsComponent = ({
  upToDateCount,
  pendingUpdateCount,
  missingInfoCount,
  riskyUpToDateCount,
  riskyPendingUpdateCount,
  riskyMissingInfoCount,
  total,
  statusFilter,
  setStatusFilter,
}: Props) => {
  const upToDatePercentage = ((upToDateCount / total) * 100).toFixed(2);
  const pendingUpdatePercentage = ((pendingUpdateCount / total) * 100).toFixed(2);
  const missingInfoPercentage = ((missingInfoCount / total) * 100).toFixed(2);
  const riskyTotal = riskyUpToDateCount + riskyPendingUpdateCount + riskyMissingInfoCount;
  const riskyUpToDatePercentage = ((riskyUpToDateCount / riskyTotal) * 100).toFixed(2);
  const riskyPendingUpdatePercentage = ((riskyPendingUpdateCount / riskyTotal) * 100).toFixed(2);
  const riskyMissingInfoPercentage = ((riskyMissingInfoCount / riskyTotal) * 100).toFixed(2);
  const changeStatusFilter = useCallback(
    (currentStatus = STATISTICS_TYPES.ALL) => {
      setStatusFilter(currentStatus);
    },
    [setStatusFilter]
  );

  return (
    <div className={styles.statisticsContainer}>
      <div className={styles.dropDownContainer}>
        <WithLightTitle title="affichage" titleClassName={styles.dropDownTitle}>
          <DropDown
            className={styles.dropDown}
            items={Object.values(STATISTICS_TYPES)}
            placeholder="toutes les edp"
            selectedItem={statusFilter}
            onSelectItem={changeStatusFilter}
            keyValueExtractor={(item: STATISTICS_TYPES) => {
              return { key: item, value: item };
            }}
          />
        </WithLightTitle>
      </div>
      <div className={styles.progressBarContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.statusLabel}>détails par statut</div>
          <div className={styles.keysContainer}>
            <GreenChip className={styles.chip} />
            <div className={styles.firstKey}>à jour</div>
            <BlueChip className={styles.chip} />
            <div className={styles.secondKey}>mise à jour attendue</div>
            <RedChip className={styles.chip} />
            <div className={styles.thirdKey}>informations manquantes</div>
          </div>
        </div>
        <div className={styles.progressBar}>
          {statusFilter !== STATISTICS_TYPES.RISKY_POSITION ? (
            <>
              <div
                className={classnames(styles.progressSegment, styles.upToDate)}
                style={{ width: `${upToDatePercentage}%` }}
                onClick={() => changeStatusFilter(STATISTICS_TYPES.UP_TO_DATE)}
              >
                {upToDateCount !== 0 ? upToDateCount : null}
              </div>
              <div
                className={classnames(styles.progressSegment, styles.pendingUpdate)}
                style={{ width: `${pendingUpdatePercentage}%` }}
                onClick={() => changeStatusFilter(STATISTICS_TYPES.PENDING_UPDATE)}
              >
                {pendingUpdateCount !== 0 ? pendingUpdateCount : null}
              </div>
              <div
                className={classnames(styles.progressSegment, styles.missingInfo)}
                style={{ width: `${missingInfoPercentage}%` }}
                onClick={() => changeStatusFilter(STATISTICS_TYPES.MISSING_INFO)}
              >
                {missingInfoCount !== 0 ? missingInfoCount : null}
              </div>
            </>
          ) : (
            <>
              <div
                className={classnames(styles.progressSegment, styles.upToDate)}
                style={{ width: `${riskyUpToDatePercentage}%` }}
              >
                {riskyUpToDateCount !== 0 ? riskyUpToDateCount : null}
              </div>
              <div
                className={classnames(styles.progressSegment, styles.pendingUpdate)}
                style={{ width: `${riskyPendingUpdatePercentage}%` }}
              >
                {riskyPendingUpdateCount !== 0 ? riskyPendingUpdateCount : null}
              </div>
              <div
                className={classnames(styles.progressSegment, styles.missingInfo)}
                style={{ width: `${riskyMissingInfoPercentage}%` }}
              >
                {riskyMissingInfoCount !== 0 ? riskyMissingInfoCount : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticsComponent;
