import { EDPIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import Apps from './Apps';
import Parameters from './Parameters';
import styles from './TopBar.module.scss';

const TopBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <EDPIcon />
        <div className={styles.separator} />
        <h1 className={styles.title}>étude de poste</h1>
      </div>
      <div className={styles.subContainer}>
        <Apps />
        <Parameters />
      </div>
    </div>
  );
};
export default TopBar;
