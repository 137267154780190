import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useMemo, useState } from 'react';

import { useFetchDiplomas } from 'src/Hooks/Training/useFetchDiplomas';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Priority } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './DiplomasPicker.types';

const DiplomasPicker = ({ onChange, selectedDiplomas }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchDiplomas({ keyword });
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  return (
    <AutoSuggest
      id="diplomas"
      value={selectedDiplomas}
      searchResults={
        data?.map(item => {
          return { ...item, priority: Priority.OPTIONAL };
        }) ?? []
      }
      onSearch={keyword => throttledSetKeyword(keyword)}
      keyValueExtractor={diploma => ({
        key: diploma.id,
        value: diploma.label ?? '',
      })}
      onChange={onChange}
      minLengthToSearch={3}
      fetchStatus={
        keyword.length > 2
          ? toFetchStatus({ isSuccess, isError, isLoading })
          : FETCH_STATUS.FULFILLED
      }
    />
  );
};

export default DiplomasPicker;
