import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getDevice } from '../../Redux/UserPreferences/Selectors';

import styles from './TripleChoiceButton.module.scss';
import { KeyLabel, Props } from './TripleChoiceButtonForm.types';

export const yesNoUnknownAnswers: KeyLabel[] = [
  { key: 'Yes', label: 'oui' },
  { key: 'No', label: 'non' },
  { key: 'Unknown', label: 'info inconnue' },
];

const TripleChoiceButtonForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  const isMobile = useSelector(getDevice);

  return (
    <div className={classNames({ [styles.mobileMargin]: isMobile })}>
      <ExclusiveSelectionGroup<TFieldValues, KeyLabel, string | undefined, TName>
        {...props}
        values={yesNoUnknownAnswers}
        getSelectedFormValue={element => element?.key}
        getSelectedValue={value => {
          return (
            yesNoUnknownAnswers.find(_ => _.key === value) ?? {
              key: 'Unknown',
              label: 'info inconnue',
            }
          );
        }}
        getStringValue={({ label }) => label}
        getKey={({ key }) => key}
        className={props.className ?? classNames({ [styles.mobileTripleChoice]: isMobile })}
        buttonClassName={
          props.buttonClassName ?? classNames({ [styles.mobileTripleChoiceButton]: isMobile })
        }
      />
    </div>
  );
};

export default TripleChoiceButtonForm;
