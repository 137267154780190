import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export enum ScrollDirection {
  DOWN,
  UP,
}

export function useScrollDirection(id: string) {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const url = useParams();

  useEffect(() => {
    const item = document.getElementById(id);
    if (item) {
      let lastScrollY = item.scrollTop;

      const updateScrollDirection = () => {
        const scrollY = item.scrollTop;
        const direction = scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP;
        if (scrollY < 10) {
          setScrollDirection(ScrollDirection.UP);
        } else if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 8 || scrollY - lastScrollY < -8)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      item?.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        item?.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }
  }, [id, scrollDirection, url]);

  return scrollDirection;
}
