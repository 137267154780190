import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  DefaultIcon,
  QualifAgentAdministratif,
  QualifAgentFabrication,
  QualifAgentMaintenance,
  QualifAideComptable,
  QualifAssistant,
  QualifAssistantCommercial,
  QualifAssistantLogistique,
  QualifBarman,
  QualifCableur,
  QualifCariste,
  QualifCaster,
  QualifChauffagiste,
  QualifChefDeRang,
  QualifCommis,
  QualifCommunityManager,
  QualifComptable,
  QualifConducteurLivreur,
  QualifConseillerTelephonique,
  QualifCuisinier,
  QualifDemenageur,
  QualifDistributeurDeJournaux,
  QualifElectricien,
  QualifElectricienChantier,
  QualifEmployeRestauration,
  QualifEnqueteur,
  QualifGuideAccompagnateur,
  QualifHommeChambre,
  QualifHotesseAccueil,
  QualifHotesseEvenementiel,
  QualifHotesseStandardiste,
  QualifInventoriste,
  QualifLibreService,
  QualifMacon,
  QualifMagasinier,
  QualifManutentionnaire,
  QualifMecanicienMonteur,
  QualifMenuisier,
  QualifMenuisierAgenceur,
  QualifMontageAssemblage,
  QualifOperateurSaisie,
  QualifOuvrierExecution,
  QualifPeintre,
  QualifPlaquiste,
  QualifPlombier,
  QualifPlongeur,
  QualifPreparateur,
  QualifReceptionniste,
  QualifSecretaireComptable,
  QualifServeur,
  QualifSoudeur,
  QualifTechnicienMaintenance,
  QualifTechnicienReseauInfo,
  QualifTechnicienSurface,
  QualifTireurCable,
  QualifVendeurAlimentaire,
  QualifVendeurHorsAlimentaire,
} from 'src/Assets';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import HorizontalCardWithIcon from 'src/Components/HorizontalCardWithIcon';
import SelectQualifications from 'src/Containers/Selects/SelectQualifications';
import { useFetchTopQualifications } from 'src/Hooks/Qualifications';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { Qualification } from 'src/Services/API';

import styles from './EDPTopQualificationSelectionModalContent.module.scss';
import { Props } from './EDPTopQualificationSelectionModalContent.types';

const iconMapping = {
  imttlPmYWCn8N8S7pOArist3mEuZNFxhldjZZ0Y1qMA1: <QualifGuideAccompagnateur />,
  '3XeETFF_Zq8Jr-YeJZvfR8t3mEuZNFxhldjZZ0Y1qMA1': <QualifHotesseEvenementiel />,
  NidOZKXelSWZpbqf7w3Ic8t3mEuZNFxhldjZZ0Y1qMA1: <QualifEnqueteur />,
  pQ8ypEnW1vrNaiyto8uDkct3mEuZNFxhldjZZ0Y1qMA1: <QualifServeur />,
  'G6jw-TXAuNs2G733F3LTqct3mEuZNFxhldjZZ0Y1qMA1': <QualifManutentionnaire />,
  Ns3Bd5lccAXr_dI4ZKfLi8t3mEuZNFxhldjZZ0Y1qMA1: <QualifDistributeurDeJournaux />,
  AXSZkJvjVE4i6FCb41UWgct3mEuZNFxhldjZZ0Y1qMA1: <QualifCaster />,
  MYhTxKAqsWYomvOIyO2M2Mt3mEuZNFxhldjZZ0Y1qMA1: <QualifCommunityManager />,
  '_FU37VIi_4X-W-o5M06PUct3mEuZNFxhldjZZ0Y1qMA1': <QualifConseillerTelephonique />,
  '25VL5PedazAG-392uhR8sct3mEuZNFxhldjZZ0Y1qMA1': <QualifHotesseAccueil />,
  'YyLRoxoYFP94E86rL-BSrct3mEuZNFxhldjZZ0Y1qMA1': <QualifHotesseStandardiste />,
  'RwfTrNLlnkies-_Cxj_aS8t3mEuZNFxhldjZZ0Y1qMA1': <QualifAgentAdministratif />,
  e3vuQRflxWHnGdAwT81GpMt3mEuZNFxhldjZZ0Y1qMA1: <QualifOperateurSaisie />,
  w_XYji2wf9gNPzqG2XJsWct3mEuZNFxhldjZZ0Y1qMA1: <QualifAssistant />,
  dKxTlRdF4JIB1iLiS2xEm8t3mEuZNFxhldjZZ0Y1qMA1: <QualifVendeurHorsAlimentaire />,
  'TJpeq52hs5g4WwCB1-Mq6st3mEuZNFxhldjZZ0Y1qMA1': <QualifSecretaireComptable />,
  i2kHuJd9Goe79PYdxhrJ2st3mEuZNFxhldjZZ0Y1qMA1: <QualifAssistantCommercial />,
  W4yXE7aKWLE34ZYv90gSJMt3mEuZNFxhldjZZ0Y1qMA1: <QualifComptable />,
  OR_H5aNiNwZn0vqHrDo0iMt3mEuZNFxhldjZZ0Y1qMA1: <QualifAideComptable />,
  oJNbCJZgXJj6NRZkxk0qtMt3mEuZNFxhldjZZ0Y1qMA1: <QualifLibreService />,
  eMKzpdrfr6al7dcMJjF5a8t3mEuZNFxhldjZZ0Y1qMA1: <QualifVendeurAlimentaire />,
  'OaZCtbq3Qv-rH-c6epyrCct3mEuZNFxhldjZZ0Y1qMA1': <QualifHommeChambre />,
  zPZBxiZ67t0SmV67nEz7N8t3mEuZNFxhldjZZ0Y1qMA1: <QualifReceptionniste />,
  xgd4qk1zUkqULeb9IozGGst3mEuZNFxhldjZZ0Y1qMA1: <QualifCuisinier />,
  IvrfVQcTbhdoHAbyNhIIXMt3mEuZNFxhldjZZ0Y1qMA1: <QualifCommis />,
  QXIDf95_aEz6VTG9la9428t3mEuZNFxhldjZZ0Y1qMA1: <QualifPlongeur />,
  Uf5e_7X4V_4ua2wh8fje28t3mEuZNFxhldjZZ0Y1qMA1: <QualifEmployeRestauration />,
  '9TjXEunjLpuqJh7i7fu8yct3mEuZNFxhldjZZ0Y1qMA1': <QualifChefDeRang />,
  ff0dtKe17DVI8HlmsE3wmst3mEuZNFxhldjZZ0Y1qMA1: <QualifBarman />,
  T105GrvO8PMenhzVd2pPK8t3mEuZNFxhldjZZ0Y1qMA1: <QualifAssistantLogistique />,
  KWrw49G3BWlutXg64jrHyct3mEuZNFxhldjZZ0Y1qMA1: <QualifConducteurLivreur />,
  hUqAj6sDNEH5difsKAA2ost3mEuZNFxhldjZZ0Y1qMA1: <QualifMagasinier />,
  'lwM48yqD9Ds-4ycJ-gsbQ8t3mEuZNFxhldjZZ0Y1qMA1': <QualifPreparateur />,
  gQwMyXSguNetHkCyI8oeYst3mEuZNFxhldjZZ0Y1qMA1: <QualifInventoriste />,
  mpeJjMufGdzjKFXpz6LkUMt3mEuZNFxhldjZZ0Y1qMA1: <QualifCariste />,
  'iGe5-Yk7cCZumPhdjpMaHst3mEuZNFxhldjZZ0Y1qMA1': <QualifDemenageur />,
  'nor-cyLCtUeeCEyqZB_hp8t3mEuZNFxhldjZZ0Y1qMA1': <QualifAgentMaintenance />,
  KmfwWobFk_mfRkxkiOZE98t3mEuZNFxhldjZZ0Y1qMA1: <QualifTechnicienReseauInfo />,
  '8B8ycWDvqhnTb4qpW7pyMMt3mEuZNFxhldjZZ0Y1qMA1': <QualifTechnicienSurface />,
  yyTNId288RIc5t8GGCj2wst3mEuZNFxhldjZZ0Y1qMA1: <QualifAgentFabrication />,
  iMEjTe7UyqSUrCm4eF9slct3mEuZNFxhldjZZ0Y1qMA1: <QualifMontageAssemblage />,
  ln6cFJuyQLMtClzPdx_JHst3mEuZNFxhldjZZ0Y1qMA1: <QualifTechnicienMaintenance />,
  'QnJWesSc-lHVJDzqIRpI_8t3mEuZNFxhldjZZ0Y1qMA1': <QualifCableur />,
  h6TPRCl_ovGHvc7OlEMPgct3mEuZNFxhldjZZ0Y1qMA1: <QualifSoudeur />,
  kYQSjMTSPcmSqBKUO2fyYst3mEuZNFxhldjZZ0Y1qMA1: <QualifMecanicienMonteur />,
  H1Vts5M5dEvHY8Gb_MxTnct3mEuZNFxhldjZZ0Y1qMA1: <QualifElectricien />,
  rwlLS5EYfnsW_4cA_OPjfct3mEuZNFxhldjZZ0Y1qMA1: <QualifOuvrierExecution />,
  '3Jvyza7MjWB_JTFoXFKSAct3mEuZNFxhldjZZ0Y1qMA1': <QualifMacon />,
  KHnXqoErXfD0_IfnG9ebdct3mEuZNFxhldjZZ0Y1qMA1: <QualifElectricienChantier />,
  '0ykvLRQJjBx3r3CvclisJMt3mEuZNFxhldjZZ0Y1qMA1': <QualifTireurCable />,
  FrCWfaX2TD80pzk60_KVZst3mEuZNFxhldjZZ0Y1qMA1: <QualifMenuisierAgenceur />,
  '3EwKAcp6r_1GqTr9ds0wBct3mEuZNFxhldjZZ0Y1qMA1': <QualifChauffagiste />,
  '3EzOk-oA_o0wPcHFjkioEMt3mEuZNFxhldjZZ0Y1qMA1': <QualifPlombier />,
  'UIOvAhfgrDZImupDi-Sou8t3mEuZNFxhldjZZ0Y1qMA1': <QualifMenuisier />,
  _qZBA1AlMygWYguZdJD6ost3mEuZNFxhldjZZ0Y1qMA1: <QualifPeintre />,
  EXQm4PIM3NTvAuWc2RONost3mEuZNFxhldjZZ0Y1qMA1: <QualifPlaquiste />,
};

const EDPTopQualificationSelectionModalContent = ({
  selectedQualification,
  onQualificationChange,
  selectedSector,
}: Props) => {
  const selectedBrand = useSelector(getCurrentBrand);

  const societyId = selectedBrand?.societyId ?? '';

  const { isError, isLoading, isSuccess, data } = useFetchTopQualifications(
    selectedSector?.id ?? '',
    societyId
  );

  const topQualificationWithIcon = data?.map(qualification => {
    return {
      id: qualification.id,
      label: qualification.label,
      icon: iconMapping[qualification.id as keyof typeof iconMapping],
    };
  });

  return (
    <WithLightTitle title={`qualifications du secteur ${selectedSector?.label}`}>
      {isLoading && (
        <>
          {new Array(10).fill(undefined).map((_, index) => {
            return (
              <HorizontalCardWithIcon.Loading key={`horizontal-card-with-icon-loader-${index}`} />
            );
          })}
        </>
      )}

      {isError && (
        <ErrorMessage
          message={`Une erreur est survenue lors de la récuparation du référentiel des top qualifications${
            selectedSector?.label ? ` associées au secteur ${selectedSector?.label}` : ''
          }.\n Tentez de rafraichir votre page ou de rechercher directement la qualification ci-dessous.`}
        />
      )}

      {isSuccess && (
        <>
          {topQualificationWithIcon?.map(topQualification => {
            const isSelected = selectedQualification?.id === topQualification.id;

            return (
              <HorizontalCardWithIcon
                key={topQualification.id}
                icon={topQualification.icon ?? <DefaultIcon />}
                label={topQualification.label}
                selected={isSelected}
                onClick={() => {
                  isSelected
                    ? onQualificationChange(undefined)
                    : onQualificationChange(topQualification);
                }}
              />
            );
          })}
        </>
      )}
      <div className={styles.searchInputContainer}>
        <SelectQualifications
          selectedQualification={selectedQualification}
          blackList={data?.map(qualification => qualification.id)}
          setQualification={(qualification?: Qualification) => {
            onQualificationChange(qualification);
          }}
          title={'ou choisir directement une qualification'}
        />
      </div>
    </WithLightTitle>
  );
};

export default EDPTopQualificationSelectionModalContent;
