import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, Risk } from 'src/Services/API';

import { QueryKeys } from '../types';

const fetchSIR = async () => {
  const sirs = await RepositoriesService.repositoriesControllerGetSir();
  return sirs;
};

export const useFetchSIR = (
  extraOptions?: UseQueryOptions<Risk[], unknown, Risk[], QueryKeys[]>
) => {
  return useQuery([QueryKeys.fetchSIRs], fetchSIR, {
    staleTime: 86400000,
    ...extraOptions,
  });
};
