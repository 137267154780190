import {
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import React, { createContext, useCallback, useState } from 'react';

import history from 'src/Utils/history';

const initialContext = {
  isAuthenticated: true,
  isLoading: false,
  user: {
    given_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_NAME,
    family_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_FAMILY_NAME,
    profile: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_SIID,
    email: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_EMAIL,
  },
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => {
    const response = {
      id_token: 'id_token',
      access_token: 'access_token',
      expires_in: 1234,
    };

    // we return Promise<any> as seen on the real implementation of the original getAccessTokenSilently
    // as overloading type is too tricky for typescript
    // (cf. getAccessTokenSilently type in Auth0ContextInterface for more info)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Promise.resolve(options?.detailedResponse ? response : 'token') as Promise<any>;
  },
  logout: () => {
    console.log('logout');
  },
  loginWithRedirect: async (options?: RedirectLoginOptions) => {
    console.log('loginWithRedirect', options);
    return Promise.resolve();
  },
};

export const MockedAuthContext =
  createContext<
    Pick<
      Auth0ContextInterface,
      | 'getAccessTokenSilently'
      | 'logout'
      | 'isAuthenticated'
      | 'isLoading'
      | 'loginWithRedirect'
      | 'user'
    >
  >(initialContext);

export const MockedAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    history.push('/login');
  }, []);

  const loginWithRedirect = useCallback((options?: RedirectLoginOptions) => {
    if (
      window.location.pathname === '/login' &&
      options &&
      Object.keys(options).length === 0 &&
      options.constructor === Object
    ) {
      history.push('/');
    }
    setIsAuthenticated(false);
    return Promise.resolve();
  }, []);

  return (
    <MockedAuthContext.Provider
      value={{ ...initialContext, isAuthenticated, logout, loginWithRedirect }}
    >
      {children}
    </MockedAuthContext.Provider>
  );
};
