import {
  HorizontalCardFoldable,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { pluralFormat } from 'src/Utils/pluralFormat';

import HorizontalCardWithIcon from '../HorizontalCardWithIcon';

import styles from './FoldableSelectionList.module.scss';
import { Props } from './FoldableSelectionList.types';

const FoldableSelectionList = <T extends unknown>({
  icon,
  data,
  title,
  onClick,
  className,
  lightTitle,
  keyExtractor,
  selectedCount,
  labelExtractor,
  isSelectedExtractor,
}: Props<T>) => {
  return (
    <WithLightTitle
      title={lightTitle}
      className={className}
      rightTitleComponent={
        <div className={styles.selectedCountLabel}>
          {pluralFormat(selectedCount, 'sélectionné')}
        </div>
      }
    >
      <HorizontalCardFoldable
        containerClassName={styles.foldableListHeader}
        label={title}
        leftIcon={icon}
      >
        {data.map(item => {
          const key = keyExtractor(item);
          const label = labelExtractor(item);
          return (
            <HorizontalCardWithIcon
              className={styles.foldableListItem}
              key={key}
              label={label}
              icon={null}
              selected={isSelectedExtractor(item)}
              onClick={() => onClick(item)}
            />
          );
        })}
      </HorizontalCardFoldable>
    </WithLightTitle>
  );
};

export default FoldableSelectionList;
