import { TextInput, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { EuroSign } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import styles from './SalaryInBetweenAmount.module.scss';
import { Props } from './SalaryInBetweenAmount.types';

const SalaryInBetweenAmount = ({ value, onChange }: Props) => {
  const isMobile = useSelector(getDevice);
  return (
    <div className={isMobile ? styles.mobileContainer : styles.container}>
      <WithLightTitle title="fourchette entre">
        <TextInput
          key="salaryLowBound"
          type="number"
          containerClassName={isMobile ? styles.mobileTextInput : styles.textInput}
          defaultValue={value.salaryInBetween?.salaryLowBound}
          onChange={({ target }) => {
            onChange({
              ...value,
              salaryInBetween: {
                ...value.salaryInBetween,
                salaryLowBound: (target as HTMLInputElement).value,
              },
            });
          }}
          rightIcon={<EuroSign />}
        />
      </WithLightTitle>
      <WithLightTitle title="et">
        <TextInput
          key="salaryHighBound"
          type="number"
          containerClassName={isMobile ? styles.mobileTextInput : styles.textInput}
          defaultValue={value.salaryInBetween?.salaryHighBound}
          onChange={({ target }) => {
            onChange({
              ...value,
              salaryInBetween: {
                ...value.salaryInBetween,
                salaryHighBound: (target as HTMLInputElement).value,
              },
            });
          }}
          rightIcon={<EuroSign />}
        />
      </WithLightTitle>
    </div>
  );
};

export default SalaryInBetweenAmount;
