import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import EDPRenameModal from './EDPRenameModal.component';

const MemoEDPRenameModal = React.memo(EDPRenameModal);

const EnhancedEDPRenameModal = () => {
  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return <MemoEDPRenameModal onClose={onClose} />;
};

export default EnhancedEDPRenameModal;
