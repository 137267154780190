import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { PositionstudiesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchPositionStudyFileData = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  return useMutation(
    [MutationKeys.fetchPositionStudyFileData],
    async (fileId: string) => {
      const positionStudy =
        await PositionstudiesService.positionStudiesControllerGetPositionStudyFileData(
          { id: fileId },
          { responseType: 'arraybuffer' }
        );
      return positionStudy;
    },
    {
      ...extraOptions,
    }
  );
};
