import * as z from 'zod';

import { SkillWithPriority } from 'src/Services/API';

export interface Props {
  skill: SkillWithPriority;
  onValidate?: (tasks: string[]) => void;
  onClose?: () => void;
}

export const tasksModalSchema = z
  .object({
    currentTask: z.string(),
    tasks: z.array(z.string()),
  })
  .refine(
    ({ currentTask, tasks }) => {
      return !tasks.includes(currentTask);
    },
    {
      message: 'La tâche saisie existe déjà',
      path: ['currentTask'],
    }
  )
  .refine(
    ({ currentTask }) => {
      return currentTask !== '';
    },
    {
      message: 'Veuillez saisir une tâche valide',
      path: ['currentTask'],
    }
  );
