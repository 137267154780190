import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Copy,
  Pencil,
  ThreeDots,
  RenameField,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import EDPDeletionWarningModal from 'src/Containers/Modals/EDPDeletionWarningModal';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { useDuplicateEDP } from 'src/Hooks/PositionStudies/useDuplicateEDP';
import { getEditionRoute } from 'src/Services/Routing';

import styles from './StudyActions.module.scss';
import { Props } from './StudyActions.types';

const StudyActions = ({ companyId, positionStudyId, positionStudyName, companyName }: Props) => {
  const history = useHistory();
  const EDPRenameModal = useModalWithLocation('rename');
  const [isOpen, setIsOpen] = useState(false);
  const [isDuplicateMenuOpen, setIsDuplicateMenuOpen] = useState(false);
  const duplicateMutation = useDuplicateEDP();
  const { open } = useModalWithLocation('duplicate-study');

  return (
    <>
      <PopupMenu
        position="left top"
        width={248}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setIsOpen(false);
          setIsDuplicateMenuOpen(false);
        }}
        trigger={
          <Button.Tertiary
            className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}
          >
            <ThreeDots className={styles.icon} />
          </Button.Tertiary>
        }
      >
        <PopupMenu.Item
          icon={<Pencil />}
          text="consulter / modifier"
          onClick={() => {
            history.push(getEditionRoute({ companyId, positionStudyId, section: 'hr/profile' }));
          }}
        />
        <EDPDeletionWarningModal positionStudyId={positionStudyId} />
        <PopupMenu
          onClose={() => setIsDuplicateMenuOpen(false)}
          width={296}
          trigger={
            <PopupMenu.Item
              icon={<Copy />}
              key="duplicate"
              text="dupliquer"
              hasSubMenu
              keepOpenOnClick
              openSubMenu={isDuplicateMenuOpen}
              onClick={() => setIsDuplicateMenuOpen(!isDuplicateMenuOpen)}
            />
          }
          position="left top"
        >
          <PopupMenu.Item
            icon={<Copy />}
            text="edp identique"
            onClick={() => duplicateMutation.mutate(positionStudyId ?? '')}
          />
          <PopupMenu.Item
            icon={<Copy />}
            text="edp nouvelle qualification"
            onClick={() =>
              open({
                modalParameters: {
                  companyId,
                  companyName,
                  positionStudyId,
                },
              })
            }
          />
        </PopupMenu>
        <PopupMenu.Item
          icon={<RenameField />}
          text="renommer"
          onClick={() => {
            EDPRenameModal.open({
              modalParameters: { positionStudyId, positionStudyName: positionStudyName },
            });
          }}
        />
      </PopupMenu>
    </>
  );
};

export default StudyActions;
