import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchDepartments } from 'src/Hooks/RH/useFetchDepartments';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { Department } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import RequiredFieldHeader from '../RequiredFieldHeader';

import { Props } from './DepartmentPicker.types';

const DepartmentPicker = ({ setDepartementId, selectedDepartmentId }: Props) => {
  const isMobile = useSelector(getDevice);
  const query = useFetchDepartments();
  const researchedDepartements =
    query.data?.filter(item => item.id !== '99' && item.id !== '97') ?? [];

  return (
    <>
      <RequiredFieldHeader
        fieldHeader="choisir le département du lieu de travail mobile"
        isMobile={isMobile}
      />
      {query.isLoading && (
        <ContentLoader height="3.5rem" width="100%" uniqueKey="StatusSelection">
          <rect x="0" rx="4" ry="4" width="100%" height="3.5rem" />
        </ContentLoader>
      )}
      {query.isError && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des départements" />
      )}
      {query.isSuccess && (
        <ComboBox
          id="departments"
          value={query.data.find(item => item.id === selectedDepartmentId)}
          keyValueExtractor={(departement: Department) => ({
            key: departement.id,
            value: `${departement?.id} - ${departement?.label}`,
          })}
          onChange={departement => {
            setDepartementId(departement?.id);
          }}
          searchResults={researchedDepartements}
          useNativeOptionFiltering
          fetchStatus={toFetchStatus(query)}
          isError={selectedDepartmentId === undefined || selectedDepartmentId.length === 0}
        />
      )}
    </>
  );
};
export default DepartmentPicker;
