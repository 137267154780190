import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  CompaniesService,
  CompanyService,
  OSMConsultantModelDtoConsultantAgencyByCompany,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyServices = (
  companyId: string,
  extraOptions?: UseQueryOptions<
    CompanyService[],
    unknown,
    CompanyService[],
    (string | undefined | OSMConsultantModelDtoConsultantAgencyByCompany)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyServices, companyId, currentBrand],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const services = await CompaniesService.companiesControllerGetCompanyServices({
        id: companyId,
        brandCode: currentBrand.brandCodeApiHeader,
      });

      return services;
    },
    {
      staleTime: 300000,
      ...extraOptions,
    }
  );
};
