import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import TestsPicker from 'src/Components/TestsPicker';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { EnumPositionStudyTestsInternTest } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Tests.module.scss';
import { testsSchema } from './Tests.schema';
import { TestsContext } from './TestsContext';

const Tests = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const initialETests = fetchPositionStudy.data?.tests.eTests;
  const initialInternTest = fetchPositionStudy.data?.tests.internTest;

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: testsSchema,
    defaultValues: {
      hasInternTest: initialInternTest ?? EnumPositionStudyTestsInternTest.Unknown,
      hasETests: initialETests && initialETests.length > 0,
      eTests: initialETests,
    },
  });

  const hasInternTest = watch('hasInternTest');
  const hasETests = watch('hasETests');
  const eTests = watch('eTests');

  const {
    field,
    fieldState: { error },
  } = useController({ name: 'eTests', control });

  const testHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            tests: {
              internTest: values.hasInternTest ?? EnumPositionStudyTestsInternTest.Unknown,
              eTests: values.hasETests ? values.eTests : [],
            },
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = testHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInternTest, hasETests, eTests]);

  return (
    <TestsContext.Provider
      value={{
        control,
        watch,
        submit,
      }}
    >
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.container}>
          <h2 className={styles.title}>test interne</h2>
          <WithLightTitle
            className={isMobile ? styles.mobilePicker : styles.picker}
            title="existe-t-il un test en interne ?"
          >
            <TripleChoiceButtonForm name="hasInternTest" control={control} />
          </WithLightTitle>
          <div className={styles.separator}></div>
          <h2 className={styles.title}>E-testing</h2>
          <div className={styles.toggleContainer}>
            <p>Des tests E-testing sont-ils à faire passer ?</p>
            <ToggleSwitch control={control} name="hasETests" />
          </div>
          {hasETests && (
            <div>
              <WithLightTitle
                className={isMobile ? styles.mobilePicker : styles.picker}
                title="référentiel de tests E-testing"
              >
                <TestsPicker onChange={field.onChange} selectedTests={field.value} />
                <ErrorMessage error={error} />
              </WithLightTitle>
              {field.value.length > 0 && (
                <>
                  <WithLightTitle
                    className={styles.selectedContainer}
                    title={pluralFormat(field.value.length, 'test E-testing ajouté')}
                  >
                    <>
                      {field.value.map(test => {
                        return (
                          <HorizontalTextIconCard
                            key={`tests-component-child-${test.id}`}
                            containerClassName={styles.selectedCard}
                            rightIcon={<Trashcan />}
                            label={test.label ?? ''}
                            onRightIconClick={() => {
                              field.onChange(field.value.filter(item => item.id !== test.id));
                            }}
                          />
                        );
                      })}
                    </>
                  </WithLightTitle>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </TestsContext.Provider>
  );
};

export default Tests;
