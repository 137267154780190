import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';

import LanguagePicker from 'src/Components/LanguagePicker';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumPositionStudyLanguagesHasToSpeakOtherThanFrenchLanguage as YesNoUnknownEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import styles from './LanguageEdition.module.scss';
import { languageSchema, getDefaultValues, getEditedLanguages } from './LanguageEdition.schema';
import { Props } from './LanguageEdition.types';
import { MostUsedLanguageSelection } from './MostUsedLanguageSelection';
import { SelectedLanguageList } from './SelectedLanguageList';

const LanguageEdition = ({ languagesRepository }: Props) => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const languages = fetchPositionStudy.data?.languages;

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: languageSchema,
    defaultValues: getDefaultValues(languagesRepository, languages),
  });

  const hasToSpeakFrench = watch('hasToSpeakFrench');
  const hastToSpeakOtherThanFrenchLanguage = watch('hastToSpeakOtherThanFrenchLanguage');

  const otherThanFrenchLanguagesController = useController({
    name: 'otherThanFrenchLanguages',
    control,
  });

  const languageHandleSubmit = useCallback(
    () =>
      handleSubmit(data => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            languages: getEditedLanguages(languagesRepository, data),
          },
        });
        reset(data);
      }),
    [fetchPositionStudy.data, handleSubmit, languagesRepository, reset, updateMutation]
  );

  const submit = languageHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasToSpeakFrench,
    hastToSpeakOtherThanFrenchLanguage,
    otherThanFrenchLanguagesController.field.value,
  ]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2>nécessité du français</h2>
          <div className={classnames(styles.toggleContainer, styles.lightTitle)}>
            <p>
              le candidat doit-il parler français pour comprendre les consignes nécessaires au poste
              ?
            </p>
            <ToggleSwitch control={control} name="hasToSpeakFrench" />
          </div>

          <div className={styles.separator} />

          <h2>votre choix</h2>
          <WithLightTitle
            title="le candidat doit-il parler d'autres langues ?"
            className={styles.lightTitle}
          >
            <TripleChoiceButtonForm name="hastToSpeakOtherThanFrenchLanguage" control={control} />
          </WithLightTitle>

          {hastToSpeakOtherThanFrenchLanguage === YesNoUnknownEnum.Yes && (
            <>
              <MostUsedLanguageSelection
                title="sélectionner parmi ces langues les plus utilisées"
                {...otherThanFrenchLanguagesController}
                mostUsedLanguages={languagesRepository.mostUsedLanguages}
              />
              <WithLightTitle
                title="sélectionner parmi toutes les langues"
                className={styles.lightTitle}
              >
                <LanguagePicker
                  items={languagesRepository.languages}
                  selectedItems={otherThanFrenchLanguagesController.field.value}
                  onChange={otherThanFrenchLanguagesController.field.onChange}
                />
              </WithLightTitle>
              {otherThanFrenchLanguagesController.field.value.length > 0 && (
                <div className={styles.lightTitle}>
                  <SelectedLanguageList {...otherThanFrenchLanguagesController} />
                </div>
              )}
            </>
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default LanguageEdition;
