import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
  SignatureWithChoice,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusSign, Pencil } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGiveCompanyPhotoRights } from 'src/Hooks/Companies/useGiveCompanyPhotoRights';
import { toFetchStatus } from 'src/Services/Async';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './CompanyRightsModal.module.scss';
import { companyRightsModalSchema } from './CompanyRightsModal.schema';
import { Props } from './CompanyRightsModal.types';

const CompanyRightsModal = ({ companyId, positionStudyId }: Props) => {
  const isMobile = useSelector(getDevice);
  const ref = useRef<PopupActions>(null);
  const giveCompanysRightsMutation = useGiveCompanyPhotoRights();
  const [sign, setSign] = useState<string | undefined>();

  const { control, handleSubmit, watch } = useFormWithZodResolver({
    schema: companyRightsModalSchema,
    defaultValues: {
      contactFirstName: undefined,
      contactLastName: undefined,
      contactMail: undefined,
    },
  });

  const clientName = watch('contactLastName');
  const clientMail = watch('contactMail');

  const addRightsHandleSubmit = () =>
    handleSubmit(async values => {
      if (sign !== undefined) {
        const res: Response = await fetch(sign);
        const blob: Blob = await res.blob();
        const file = new File([blob], 'sign.png', { type: 'image/png' });
        giveCompanysRightsMutation.mutate({
          addRightsBody: {
            contactLastName: values.contactLastName,
            contactMail: values.contactMail,
            contactFirstName: values.contactFirstName,
          },
          file,
          companyId,
          positionStudyId,
        });
      }
    });
  return (
    <Modal
      trigger={
        <div>
          <button className={styles.triggerButton}>
            <Pencil className={styles.pencilIcon} />
            <p>déclarer une cession de droits client</p>
          </button>
        </div>
      }
      ref={ref}
      title="cession de droits"
      icon={<IllusSign className={styles.modalIcon} />}
      footerActionsRight={[
        <Button.Secondary onClick={ref.current?.close}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={sign === undefined || clientMail === undefined || clientName === undefined}
          fetchStatus={toFetchStatus(giveCompanysRightsMutation)}
          onClick={() => {
            addRightsHandleSubmit()();
          }}
          onSuccess={() => ref.current?.close()}
        />,
      ]}
    >
      <div>
        <p className={styles.title}>
          Les images utilisées sont soumises à des droits. Votre client doit autoriser leur
          utilisation auprès de randstad
        </p>
        <div className={isMobile ? styles.nameColumn : styles.nameLine}>
          <WithLightTitle
            title="prénom client"
            className={classnames({ [styles.input]: !isMobile })}
          >
            <TextInput control={control} name="contactFirstName" />
          </WithLightTitle>
          <WithLightTitle title="nom client" className={classnames({ [styles.input]: !isMobile })}>
            <TextInput control={control} name="contactLastName" />
          </WithLightTitle>
        </div>
        <WithLightTitle title="email" className={styles.title}>
          <TextInput control={control} name="contactMail" />
        </WithLightTitle>
        {isMobile ? (
          <WithLightTitle title="type de signature" className={styles.title}>
            <SignatureWithChoice
              size={{ width: window.innerWidth - 35, height: 200 }}
              onSignChange={image => setSign(image)}
              onClear={() => setSign(undefined)}
              isMobile={true}
            />
          </WithLightTitle>
        ) : (
          <SignatureWithChoice
            size={{ width: 560, height: 200 }}
            onSignChange={image => setSign(image)}
            onClear={() => setSign(undefined)}
          />
        )}
      </div>
    </Modal>
  );
};

export default CompanyRightsModal;
