import * as z from 'zod';

import { testEmail } from 'src/Utils/testEmail';

export const companyRightsModalSchema = z
  .object({
    contactFirstName: z.string().optional(),
    contactLastName: z.string(),
    contactMail: z.string(),
  })
  .refine(({ contactMail }) => testEmail(contactMail), {
    message: 'veuillez entrer un format de mail valide (exemple@randstad.fr)',
    path: ['contactMail'],
  });
