import React from 'react';

import {
  MachinesAndEquipment as MachinesAndEquipmentIcon,
  SuggestedMachinesAndEquipment,
} from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const MachinesAndEquipment = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.MACHINES_ET_MATERIELS}
      skillIcon={<MachinesAndEquipmentIcon />}
      suggestedSkillIcon={<SuggestedMachinesAndEquipment />}
    />
  );
};

export default MachinesAndEquipment;
