import { SelectionIconButtonsFormProps } from 'src/Components/SelectionIconButtonsForm';
import { ProtectionEquipment } from 'src/Services/API';

export enum ProtectionEquipmentsTypes {
  agency = 'agency',
  client = 'client',
  collective = 'collective',
}

export interface Props extends Pick<SelectionIconButtonsFormProps, 'title'> {
  selectedProtectionEquipments: ProtectionEquipment[];
  onProtectionEquipmentClick: (ep: ProtectionEquipment) => void;
  type: ProtectionEquipmentsTypes;
}
