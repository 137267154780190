import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PerimeterState } from './Types';

export const initialState: PerimeterState = {
  currentBrand: undefined,
  currentAgency: undefined,
  regionId: undefined,
  zoneId: undefined,
};

const { reducer, actions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setCurrentPerimeter: (_, action: PayloadAction<PerimeterState>) => {
      return action.payload;
    },
    resetCurrent: state => {
      state.currentBrand = undefined;
      state.currentAgency = undefined;
      state.regionId = undefined;
      state.zoneId = undefined;
    },
  },
});

export const perimeterReducer = reducer;
export const perimeterActions = actions;
