import {
  Button,
  PopupActions,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import SkillTaskModal from 'src/Containers/Modals/SkillTaskModal/SkillTaskModal.component';

import styles from './SkillCardButton.module.scss';
import { Props } from './SkillCardButton.types';

const SkillCardButton = ({ skill, onValidate, onClose, onOpen }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<PopupActions>(null);

  return (
    <PopupMenu
      position="left top"
      ref={ref}
      width={248}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
        onOpen();
      }}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <ThreeDots className={styles.icon} />
        </Button.Tertiary>
      }
    >
      <SkillTaskModal
        skill={skill}
        onValidate={onValidate}
        onClose={() => {
          setIsOpen(false);
          ref.current?.close();
        }}
      />
    </PopupMenu>
  );
};

export default SkillCardButton;
