import { useSelector } from 'react-redux';

import {
  BarragePhotovoltaique,
  Barriere,
  BarriereLateraleCamion,
  Baudrier,
  BipRecul,
  Bouchons,
  CarterProtection,
  Casque,
  Chaussures,
  ClasseIIIHumide,
  DefaultIcon,
  DoubleCommande,
  EncoffrementMachineBruyante,
  FiletProtection,
  Gants,
  GardeCorps,
  GyrophareEngin,
  HotteAspirante,
  InsonorisationLocal,
  Lunettes,
  Masque,
  PoigneeLateraleCamion,
  PoigneeVibratile,
  SolAntiderapant,
  StopRoll,
  TapisSensible,
  Ventilation,
  VoieCirculation,
} from 'src/Assets';
import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { useFetchProtectionEquipments } from 'src/Hooks/Security/useFetchProtectionEquipments';
import { ProtectionEquipment } from 'src/Services/API';

import { getDevice } from '../../../../Redux/UserPreferences/Selectors';

import styles from './ProtectionEquipmentsSelection.module.scss';
import { Props } from './ProtectionEquipmentsSelection.types';

const iconMapping: { [id: string]: JSX.Element } = {
  gants: <Gants />,
  baudrier: <Baudrier />,
  chaussures: <Chaussures />,
  casque: <Casque />,
  lunettes: <Lunettes />,
  masque: <Masque />,
  bouchons: <Bouchons />,
  barriereProtection: <Barriere />,
  voie: <VoieCirculation />,
  sol: <SolAntiderapant />,
  gardeCorps: <GardeCorps />,
  filetProtection: <FiletProtection />,
  stopRolls: <StopRoll />,
  barriereLaterale: <BarriereLateraleCamion />,
  poigneeLaterale: <PoigneeLateraleCamion />,
  carterProtection: <CarterProtection />,
  tapis: <TapisSensible />,
  doubleCommande: <DoubleCommande />,
  barrage: <BarragePhotovoltaique />,
  bipRecule: <BipRecul />,
  gyrophare: <GyrophareEngin />,
  poignee: <PoigneeVibratile />,
  materiel: <ClasseIIIHumide />,
  hotte: <HotteAspirante />,
  ventilation: <Ventilation />,
  insonorisation: <InsonorisationLocal />,
  encoffrement: <EncoffrementMachineBruyante />,
};

const ProtectionEquipmentsSelection = ({
  selectedProtectionEquipments,
  onProtectionEquipmentClick,
  type,
  ...selectionIconButtonsFormProps
}: Props) => {
  const {
    data: protectionEquipments,
    isLoading,
    isSuccess,
    isError,
  } = useFetchProtectionEquipments(type);
  const isMobile = useSelector(getDevice);

  return (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      isLoading={isLoading}
      loadingItems={6}
      isError={isError}
      error="Une erreur est survenue lors de la récupération du référentiel des EPIs"
      isSuccess={isSuccess}
      selectIconButtonClassName={styles.protectionEquipmentCard}
      selectIconButtonLoaderClassName={styles.container}
      data={
        protectionEquipments?.map(protectionEquipment => ({
          key: protectionEquipment.id,
          label:
            isMobile &&
            protectionEquipment.label === 'matériels de classe III pour travail en zone humide'
              ? 'matériels de classe III pour zone humide'
              : protectionEquipment.label,
          icon: iconMapping[protectionEquipment.id] ?? <DefaultIcon />,
          selected:
            selectedProtectionEquipments.find(
              (selected: ProtectionEquipment) => selected.id === protectionEquipment.id
            ) !== undefined,
          onClick: () => {
            onProtectionEquipmentClick(protectionEquipment);
          },
        })) ?? []
      }
    />
  );
};

export default ProtectionEquipmentsSelection;
