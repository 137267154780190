import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { MaseItem } from 'src/Services/API';

import { Props } from './CACESPicker.types';

const CACESPicker = ({ setCaces, caces, selectedCaces }: Props) => {
  return (
    <AutoSuggest
      id="caces"
      value={selectedCaces}
      keyValueExtractor={(searchResult: MaseItem) => ({
        key: searchResult.id,
        value: searchResult.label ?? '',
      })}
      searchResults={caces}
      onChange={(caces: MaseItem[]) => {
        setCaces(caces);
      }}
      useNativeOptionFiltering
      minLengthToSearch={1}
    />
  );
};
export default CACESPicker;
