import { QueryClient } from '@tanstack/react-query';

import { PositionstudiesService } from 'src/Services/API';

import { QueryKeys } from '../types';

const deletePositionStudy = async (params: { positionStudyId: string; brandCode: string }) => {
  return PositionstudiesService.positionStudiesControllerDeletePositionStudy({
    id: params.positionStudyId,
    body: { brandCode: params.brandCode },
  });
};

export const positionStudyMutationsConfig = {
  positionstudyDeletion: {
    mutationFn: deletePositionStudy,
    onSettled: (
      _: unknown,
      __: unknown,
      params: {
        positionStudyId: string;
        brandCode: string;
        queryClient: QueryClient;
      }
    ) => {
      params.queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchPositionStudies] });
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};
