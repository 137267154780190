import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionstudiesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export class AddClientRightsBody {
  contactMail!: string;
  contactLastName!: string;
  contactFirstName?: string;
}

export const useGiveCompanyPhotoRights = (
  extraOptions?: UseMutationOptions<
    Buffer,
    unknown,
    { companyId: string; addRightsBody: AddClientRightsBody; positionStudyId: string }
  >
) => {
  const queryClient = useQueryClient();
  const brandCode = useSelector(getCurrentBrand);
  return useMutation(
    [MutationKeys.giveCompanyPhotoRights],
    async (params: {
      companyId: string;
      positionStudyId: string;
      addRightsBody: AddClientRightsBody;
      file: File;
    }) => {
      const result = await PositionstudiesService.positionStudiesControllerAddClientPhotoRights(
        {
          id: params.positionStudyId,
          companyId: params.companyId,
          brandCode: brandCode?.brandCodeApiHeader ?? '',
          file: params.file,
          contactFirstName: params.addRightsBody.contactFirstName ?? '',
          contactLastName: params.addRightsBody.contactLastName,
          contactMail: params.addRightsBody.contactMail,
        },
        { responseType: 'arraybuffer' }
      );
      return result;
    },
    {
      onSuccess: async (data: Buffer, variables) => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCompanyPhotoRights] });
        const currentDate = moment().get('date').toString();
        const currentMonth = (moment().get('month') + 1).toString();
        const currentYear = moment().get('year').toString();
        const pdfName = `cession de droit client ${currentDate}_${currentMonth}_${currentYear}.pdf`;
        const file = new File([data], pdfName);
        await PositionstudiesService.positionStudiesControllerUploadPositionStudyFiles({
          id: variables.positionStudyId,
          file: file,
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchPositionStudyFiles] });
      },
      ...extraOptions,
    }
  );
};
