import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { EnumExplanationTimeUnit } from 'src/Services/API';

import styles from './TimeSelection.module.scss';
import { Props } from './TimeSelection.types';

const timeunits = Object.values(EnumExplanationTimeUnit);
const timeUnitsEnumMap = {
  [EnumExplanationTimeUnit.WEEKS]: 'semaines',
  [EnumExplanationTimeUnit.DAYS]: 'jours',
  [EnumExplanationTimeUnit.HOURS]: 'heures',
  [EnumExplanationTimeUnit.MINUTES]: 'minutes',
};

const TimeSelection = ({ selected, setSelected }: Props) => {
  const isMobile = useSelector(getDevice);
  return (
    <ExclusiveSelectionGroup<string>
      className={classnames({ [styles.mobile]: isMobile })}
      buttonClassName={classnames({ [styles.mobileButton]: isMobile })}
      values={timeunits}
      canBeReset={false}
      getStringValue={key => timeUnitsEnumMap[key as keyof typeof EnumExplanationTimeUnit]}
      getKey={key => key}
      setSelected={setSelected}
      selected={selected}
    />
  );
};

export default TimeSelection;
