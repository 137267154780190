import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  TextArea,
  TextInput,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages';
import ContactPicker from 'src/Components/ContactPicker';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchCompanyContacts } from 'src/Hooks/Companies';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumPositionStudyInstructionsHasHomeFormalisation } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './Instructions.module.scss';
import { instructionSchema } from './Instructions.schema';

const Instructions = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const initialInstructions = fetchPositionStudy.data?.instructions;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const requiredFields = useSelector(getRequiredFields);
  const dispatch = useDispatch();

  const companyId = fetchPositionStudy.data?.generalInformation.company?.companyId;
  const { data } = useFetchCompanyContacts(companyId ?? '');

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: instructionSchema,
    defaultValues: {
      hasInstructionsToForward: initialInstructions?.hasInstructionsToForward ?? false,
      instructionToFollow: initialInstructions?.instructionToFollow,
      hasHomeFormalisation:
        initialInstructions?.hasHomeFormalisation ??
        EnumPositionStudyInstructionsHasHomeFormalisation.Unknown,
      contactWelcomeToPost: initialInstructions?.contactWelcomeToPost,
      contactForFirstDay: initialInstructions?.contactForFirstDay ?? {
        lastName: '',
        firstName: '',
        phone: '',
      },
    },
  });

  const hasInstructionsToForward = watch('hasInstructionsToForward');
  const instructionToFollow = watch('instructionToFollow');
  const hasHomeFormalisation = watch('hasHomeFormalisation');
  const welcomeToPostContactId = watch('contactWelcomeToPost');

  const instructionsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            instructions: values,
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = instructionsHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    const newRequiredFields = {
      ...requiredFields,
      security: {
        ...requiredFields.security,
        instructions: {
          hasHomeFormalisation: hasHomeFormalisationUnknown,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInstructionsToForward, welcomeToPostContactId, hasHomeFormalisation]);

  const hasHomeFormalisationUnknown =
    hasHomeFormalisation === EnumPositionStudyInstructionsHasHomeFormalisation.Unknown;

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {hasHomeFormalisationUnknown && (
            <CompletionStatusMessagesComponent
              customMessage={`il faut répondre par oui ou non pour la formalisation `}
            />
          )}
          <h2 className={styles.title}>votre choix</h2>

          <div className={isMobile ? styles.mobileToggleContainer : styles.toggleContainer}>
            <p>
              y a-t-il des consignes clés à transmettre aux intérimaires avant la prise de poste ?
            </p>
            <ToggleSwitch control={control} name="hasInstructionsToForward" />
          </div>
          {hasInstructionsToForward ? (
            <WithLightTitle
              title={
                isMobile ? 'consignes à transmettre' : 'description des consignes à transmettre'
              }
              className={styles.smallTopPadding}
              rightTitleComponent={
                <div className={styles.characterAmount}>
                  {instructionToFollow.length}/3999 caractères
                </div>
              }
            >
              <TextArea
                name="instructionToFollow"
                control={control}
                className={styles.textArea}
                onBlurCapture={submit}
              />
            </WithLightTitle>
          ) : null}

          <hr />
          <div className={styles.elementContainer}>
            <h2 className={styles.title}>contact à demander le 1er jour de la mission</h2>
            <div
              className={
                isMobile
                  ? styles.mobileContactContainer
                  : classnames(styles.nameBlock, styles.smallTopPadding)
              }
            >
              <WithLightTitle title="prénom" className={styles.withFlex}>
                <TextInput
                  control={control}
                  name="contactForFirstDay.firstName"
                  onBlurCapture={submit}
                />
              </WithLightTitle>
              <div style={{ width: '1rem' }} />
              <WithLightTitle title="nom" className={styles.withFlex}>
                <TextInput
                  control={control}
                  name="contactForFirstDay.lastName"
                  onBlurCapture={submit}
                />
              </WithLightTitle>
            </div>
            <WithLightTitle
              title="numéro mobile"
              className={classnames(styles.smallTopPadding, styles.widthFitContent)}
            >
              <TextInput
                containerClassName={styles.phoneNumber}
                control={control}
                placeholder="ex : 06 23 45 67 89"
                name="contactForFirstDay.phone"
                type="tel"
                onBlurCapture={submit}
              />
            </WithLightTitle>
            <WithLightTitle
              className={styles.smallTopPadding}
              title="ou pré-remplir depuis un contact Osmose"
            >
              <ContactPicker
                onChange={contactId => {
                  const contact = data?.find(contact => contact.contactId === contactId);
                  setValue('contactForFirstDay.firstName', contact?.firstName ?? '');
                  setValue('contactForFirstDay.lastName', contact?.name ?? '');
                  setValue('contactForFirstDay.phone', contact?.phone ?? '');
                  submit();
                }}
                name="contactForFirstDay"
                control={control}
                companyId={companyId ?? ''}
              />
            </WithLightTitle>
          </div>

          <hr />
          <h2 className={styles.title}>
            accueil et formateur de l'intérimaire au poste de travail
          </h2>

          <WithLightTitle title="sélectionner dans la liste des contacts Osmose">
            <ContactPicker
              name="contactWelcomeToPost"
              companyId={companyId ?? ''}
              customId="contactWelcomeToPost"
              control={control}
            />
          </WithLightTitle>

          <div className={classnames(styles.errorContainer, styles.elementContainer)}>
            <RequiredFieldHeader
              fieldHeader="existe-t-il un exemplaire de la formalisation de l'accueil et de la formation ?"
              isMobile={isMobile}
            />
            <TripleChoiceButtonForm
              control={control}
              name="hasHomeFormalisation"
              buttonClassName={classnames({
                [styles.warningHomeFormalisation]: hasHomeFormalisationUnknown,
              })}
            />
            {hasHomeFormalisationUnknown && (
              <div className={styles.errorMessage}>doit être à oui ou à non</div>
            )}
          </div>
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Instructions;
