import { FETCH_STATUS } from 'src/Redux/Types';
import { Sector } from 'src/Services/API';

export interface Props {
  onValidate: (selectedName: string, qualificationId: string, sector?: Sector) => void;
  onSuccess: () => void;
  fetchStatus: FETCH_STATUS;
}

export enum EDP_CREATION_STEP {
  SECTOR_SELECTION,
  QUALIFICATION_SELECTION,
  NAME_SELECTION,
}
