export interface Props {
  missingRequiredFields?: (string | false)[];
  customMessage?: string;
}

export const completionErrorMessagesMapping = {
  selectedSector: 'un secteur est nécessaire',
  zipCode: 'une adresse est nécessaire pour avoir une edp complète',
  contactId: 'un contact est nécessaire pour avoir une edp complète',
  positionStatus: 'un statut est nécessaire pour avoir une edp complète',
  practice: 'une expérience est nécessaire pour avoir une edp complète',
  weekHours: 'une base horaire hebdomaire est nécessaire pour avoir une edp complète',
  yesOrNoEpis: 'il faut répondre par oui ou non pour les EPI',
  needsEpis: 'si vous répondez oui pour les EPI, vous devez en sélectionner au moins un',
  yesOrNoEpcs: 'il faut répondre par oui ou non pour les EPC',
  needsEpcs: 'si vous répondez oui pour les EPC, vous devez en sélectionner au moins un',
  yesOrNo: 'il faut répondre par oui ou non pour avoir une edp complète',
  needsRisks: 'si vous répondez oui, vous devez sélectionner au moins un risque',
};
