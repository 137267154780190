import { SelectionIconButton } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './SelectionIconButtonComponentWithMobile.module.scss';
import { Props } from './SelectionIconButtonComponentWithMobile.types';

const SelectionIconButtonWithMobileComponent = ({
  icon,
  label,
  selected,
  onClick,
  className,
}: Props) => {
  const isMobile = useSelector(getDevice);

  return (
    <SelectionIconButton
      selected={selected}
      className={classnames(isMobile ? styles.mobileContainer : styles.container, className)}
      onClick={onClick}
      icon={icon}
      labelClassName={isMobile ? styles.mobileLabel : styles.label}
      label={label}
    />
  );
};

export default SelectionIconButtonWithMobileComponent;
