import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, CompanyAddress } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyWorkingLocations = (
  companyId: string,
  extraOptions?: UseQueryOptions<CompanyAddress[], unknown, CompanyAddress[], string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyWorkingLocations, companyId],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const addresses = await CompaniesService.companiesControllerGetCompanyWorkingLocations({
        id: companyId,
        brandCode: currentBrand.brandCodeApiHeader,
      });

      return addresses;
    },
    {
      staleTime: 300000,
      ...extraOptions,
    }
  );
};
