import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { PositionStudyRoute } from 'src/Services/Routing';

import { navigation } from '../PositionStudyModification.types';

import styles from './PositionStudyForm.module.scss';
import { Props } from './PositionStudyForm.types';

const PositionStudyForm = ({
  initialRequiredFields,
  skillsLength,
  selectedSector,
  currentHasRisks,
  currentHasEpis,
  currentHasEpcs,
  simplifiedHeader,
  setSimplifiedHeader,
}: Props) => {
  const params = useParams<PositionStudyRoute['routerParameters']>();
  const history = useHistory();
  const isMobile = useSelector(getDevice);
  const dispatch = useDispatch();

  const firstSection = params?.firstSection ?? '';

  const subRoute = navigation[firstSection]?.routes.find(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  const subRouteIndex = navigation[firstSection]?.routes.findIndex(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  useEffect(() => {
    dispatch(completionStatusActions.setTotalSkills(skillsLength));
    dispatch(completionStatusActions.setRequiredFields(initialRequiredFields));
    dispatch(completionStatusActions.setSelectedSector(selectedSector));
    dispatch(completionStatusActions.setCurrentHasRisks(currentHasRisks));
    dispatch(completionStatusActions.setCurrentHasEpis(currentHasEpis));
    dispatch(completionStatusActions.setCurrentHasEpcs(currentHasEpcs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const scrolled =
          divRef.current.scrollTop >=
          ((simplifiedHeader ? -176 : 0) + divRef.current.clientHeight) / 3;
        setSimplifiedHeader(scrolled);
      }
    };
    const currentDivRef = divRef.current;
    if (currentDivRef) {
      currentDivRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup: remove the event listener
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [simplifiedHeader, setSimplifiedHeader]);

  return (
    <div className={isMobile ? styles.mobileContainer : styles.container} ref={divRef}>
      {!isMobile && <div className={styles.routeNumber}>{`${subRouteIndex + 1}.`}</div>}
      <div className={styles.formContainer}>
        {!isMobile && (
          <div className={styles.titleContainer}>
            <p>{subRoute?.name ?? 'Error'}</p>
          </div>
        )}
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(navigation).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route path={`${route.path}`} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default PositionStudyForm;
