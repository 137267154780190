import {
  HorizontalCardFoldable,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  TextArea,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.component';
import HorizontalCardWithIcon from 'src/Components/HorizontalCardWithIcon';
import SkillsPicker from 'src/Components/SkillsPicker';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchQualificationRelatedSkills } from 'src/Hooks/Skills/useFetchQualificationRelatedSkills';
import { useQuerySkillsState } from 'src/Hooks/Skills/useQuerySkillsState';
import { getRequiredFields, getTotalSkills } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumSkillTypeCode } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './SkillsSubSection.module.scss';
import { Props } from './SkillsSubSection.types';

const schema = z.object({
  skills: z.array(
    z.object({ id: z.string(), label: z.string(), typeCode: z.nativeEnum(EnumSkillTypeCode) })
  ),
  tasks: z.string().optional(),
});

const getSuggestedSkillsLabel = (
  isError: boolean,
  type: EnumSkillTypeCode,
  skillsAmount?: number
) => {
  if (isError) {
    return 'impossible de récupérer la liste des compétences suggérées';
  }
  if (skillsAmount === 0) {
    return 'aucune compétence suggérée pour cette qualification';
  }
  switch (type) {
    case EnumSkillTypeCode.ENVIRONNEMENT_ET_TYPOLOGIES:
      return 'environnements et typologies suggérés';
    case EnumSkillTypeCode.MACHINES_ET_MATERIELS:
      return 'machines et matériel suggérés';
    case EnumSkillTypeCode.TECHNIQUES_ET_PROCEDES:
      return 'techniques et procédés suggérés';
    case EnumSkillTypeCode.MATERIAUX_ET_PRODUITS:
      return 'matériaux et produits suggérés';
    default:
      return 'liste des compétences suggérées';
  }
};

const SkillsSubSection = ({
  type,
  skillIcon,
  suggestedSkillIcon,
  showTasks,
  defaultSectionTitle,
}: Props) => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const qualificationId = fetchPositionStudy.data?.generalInformation.qualification?.id;
  const { data, isSuccess, isError } = useFetchQualificationRelatedSkills(qualificationId);
  const { initialSkills, initialTasks, updateSkills } = useQuerySkillsState(type, updateMutation);
  const isMobile = useSelector(getDevice);
  const requiredFields = useSelector(getRequiredFields);
  const dispatch = useDispatch();

  const totalSkills = useSelector(getTotalSkills);

  const { control, handleSubmit, watch, reset } = useFormWithZodResolver({
    schema,
    defaultValues: {
      skills: initialSkills,
      tasks: initialTasks,
    },
  });

  const { field: skillsField } = useController({ name: 'skills', control });
  const suggestedSkills = data?.filter(skill => skill.typeCode === type);
  const tasks = watch('tasks');

  const skillsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateSkills(values);
        reset(values);
      }),
    [handleSubmit, reset, updateSkills]
  );

  const submit = skillsHandleSubmit();

  const handleSkillChange = (
    newSkills: {
      id: string;
      label: string;
      typeCode: EnumSkillTypeCode;
    }[]
  ) => {
    const delta = newSkills.length - skillsField.value.length;
    skillsField.onChange(newSkills);
    dispatch(completionStatusActions.setTotalSkills(totalSkills + delta));
    submit();
  };

  useEffect(() => {
    const hasMissingSkills = totalSkills < 6;

    const newRequiredFields = {
      ...requiredFields,
      job: {
        ...requiredFields.job,
        skills: hasMissingSkills,
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsField.value]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {totalSkills < 6 && (
            <CompletionStatusMessagesComponent
              customMessage={`il vous manque ${pluralFormat(
                6 - totalSkills,
                'information'
              )} métier (compétences, environnements, machines...) pour avoir une edp complète`}
            />
          )}
          <h2 className={styles.subtitle}>{defaultSectionTitle ?? 'votre choix'}</h2>
          <WithLightTitle
            className={styles.suggestedSkillsSection}
            title="choisir parmi les compétences suggérées ci-dessous"
            titleClassName={classnames({ [styles.mobileTitle]: isMobile })}
            rightTitleComponent={
              (suggestedSkills?.length ?? 0) > 0 && (
                <div className={styles.selectedSuggestionAmount}>
                  {pluralFormat(
                    skillsField.value.filter(
                      selectedSkill =>
                        suggestedSkills?.find(skill => skill.id === selectedSkill.id) !== undefined
                    ).length,
                    'sélectionnée'
                  )}
                </div>
              )
            }
          >
            <HorizontalCardFoldable
              leftIcon={suggestedSkillIcon}
              containerClassName={styles.suggestedSkillsContainer}
              label={getSuggestedSkillsLabel(isError, type, suggestedSkills?.length)}
              disabled={!isSuccess || (suggestedSkills?.length ?? 0) === 0}
            >
              {suggestedSkills?.map(skill => {
                return (
                  <HorizontalCardWithIcon
                    className={styles.suggestedSkillCard}
                    selected={
                      skillsField.value.find(selectedSkill => selectedSkill.id === skill.id) !==
                      undefined
                    }
                    key={skill.id}
                    label={skill.label}
                    onClick={() => handleSkillChange(addOrRemove(skillsField.value, skill))}
                  />
                );
              })}
            </HorizontalCardFoldable>
          </WithLightTitle>
          <WithLightTitle
            title="compétences complémentaires"
            className={
              isMobile
                ? styles.mobileComplementarySkillsSelection
                : styles.complementarySkillsSelection
            }
          >
            <SkillsPicker
              type={type}
              selectedSkills={skillsField.value}
              onChange={handleSkillChange}
            />
          </WithLightTitle>
          {skillsField.value.length > 0 ? (
            <WithLightTitle
              className={styles.selectedSkillsContainer}
              title={pluralFormat(skillsField.value.length, 'compétence ajoutée')}
            >
              {skillsField.value.map(skill => {
                return (
                  <HorizontalTextIconCard
                    containerClassName={styles.selectedSkillCard}
                    leftIcon={
                      suggestedSkills?.find(suggestedSkill => suggestedSkill.id === skill.id)
                        ? suggestedSkillIcon
                        : skillIcon
                    }
                    key={skill.id}
                    label={skill.label}
                    rightIcon={<Trashcan />}
                    onRightIconClick={() => {
                      handleSkillChange(skillsField.value.filter(({ id }) => id !== skill.id));
                    }}
                  />
                );
              })}
            </WithLightTitle>
          ) : (
            <hr />
          )}
          {showTasks && (
            <>
              <h2 className={classnames(styles.subtitle, styles.tasksSubTitle)}>
                tâches à réaliser
              </h2>
              <WithLightTitle
                title="détails (quoi , comment , où)"
                rightTitleComponent={
                  <span className={styles.charCountLabel}>{tasks?.length ?? 0}/959 caractères</span>
                }
              >
                <TextArea
                  name="tasks"
                  maxLength={959}
                  control={control}
                  onBlurCapture={submit}
                  className={styles.taskTextArea}
                  placeholder="décrivez les tâches nécessaires pour connaître ce poste"
                />
              </WithLightTitle>
            </>
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default SkillsSubSection;
