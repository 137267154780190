import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, Diploma } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDiplomas = (
  { keyword }: { keyword: string },
  extraOptions?: UseQueryOptions<Diploma[], unknown, Diploma[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchDiplomas, keyword],
    async () => {
      return RepositoriesService.repositoriesControllerGetDiplomas({
        keyword,
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
