import {
  ExclusiveSelectionGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { EnumSalaryTimeRate } from 'src/Services/API';

import styles from './SalaryBaseSelection.module.scss';
import { Props } from './SalaryBaseSelection.types';

const salaryBases = Object.values(EnumSalaryTimeRate);

const SalaryBaseSelection = ({ isSMICChecked, selected, setSelected }: Props) => {
  const isMobile = useSelector(getDevice);
  return (
    <WithLightTitle title="base salaire">
      <ExclusiveSelectionGroup<string>
        className={classnames({ [styles.mobile]: isMobile })}
        buttonClassName={classnames({ [styles.mobileButton]: isMobile })}
        values={salaryBases}
        canBeReset={false}
        disabled={isSMICChecked}
        getStringValue={value => value}
        getKey={key => key}
        setSelected={setSelected}
        selected={isSMICChecked ? EnumSalaryTimeRate.horaire : selected}
      />
    </WithLightTitle>
  );
};

export default SalaryBaseSelection;
