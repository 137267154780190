import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { RepositoriesService, Risk } from 'src/Services/API';

import { QueryKeys } from '../types';

const fetchRisks = async () => {
  const risks = await RepositoriesService.repositoriesControllerGetRisks();
  return risks;
};

export const useFetchRisks = (
  extraOptions?: UseQueryOptions<Risk[], unknown, Risk[], QueryKeys[]>
) => {
  return useQuery([QueryKeys.fetchRisks], fetchRisks, {
    staleTime: 86400000,
    ...extraOptions,
  });
};

export const usePreFetchRisks = () => {
  const client = useQueryClient();

  useEffect(() => {
    client.prefetchQuery([QueryKeys.fetchRisks], fetchRisks, { staleTime: 86400000 });
  });
};
