import React from 'react';

import { SkillsAndTasks as SkillsAndTasksIcon, SuggestedSkillsAndTasks } from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const SkillsAndTasks = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.COMPETENCES_ET_TACHES_A_EFFECTUER}
      skillIcon={<SkillsAndTasksIcon />}
      suggestedSkillIcon={<SuggestedSkillsAndTasks />}
      defaultSectionTitle="compétences"
      showTasks
    />
  );
};

export default SkillsAndTasks;
