import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { ConsultantsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useGiveConsultantPhotoRights = (extraOptions?: UseMutationOptions<string>) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.giveConsultantPhotoRights],
    async () => {
      const consultantId = await ConsultantsService.consultantsControllerAddConsultantPhotoRights();
      return consultantId;
    },
    {
      onSuccess: () => {
        queryClient.setQueriesData(
          { queryKey: [QueryKeys.fetchConsultantPhotoRights], exact: false },
          true
        );
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchConsultantPhotoRights] });
      },
      ...extraOptions,
    }
  );
};
