import {
  GridCardMore,
  ListCardMore,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React from 'react';

import { DISPLAY_MODE } from 'src/Components/DisplayModeSelector/DisplayModeSelector.component';

import styles from './GridOrListCardLoadMore.module.scss';
import { Props } from './GridOrListCardLoadMore.types';

const GridOrListCardLoadMore = ({ displayMode }: Props) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.gridContainer]: displayMode === DISPLAY_MODE.GRID,
        },
        { [styles.listContainer]: displayMode === DISPLAY_MODE.LIST }
      )}
    >
      {displayMode === DISPLAY_MODE.GRID ? <GridCardMore /> : <ListCardMore />}
      <div
        className={classNames(styles.moreLabel, {
          [styles.gridMoreLabel]: displayMode === DISPLAY_MODE.GRID,
        })}
      >
        charger plus
      </div>
    </div>
  );
};

export default GridOrListCardLoadMore;
