import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { PositionstudiesService, HasClientGivenRights } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useHasCompanyGivenPhotoRights = (
  id?: string,
  extraOptions?: UseQueryOptions<
    HasClientGivenRights,
    unknown,
    HasClientGivenRights,
    (string | undefined)[]
  >
) => {
  return useQuery(
    [QueryKeys.fetchCompanyPhotoRights, id],
    async () => {
      return await PositionstudiesService.positionStudiesControllerGetHasClientPhotoRights({
        companyId: id ?? '',
      });
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      ...extraOptions,
    }
  );
};
