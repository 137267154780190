export enum STATISTICS_TYPES {
  ALL = 'toutes les edp',
  RISKY_POSITION = 'poste à risque',
  UP_TO_DATE = 'à jour',
  PENDING_UPDATE = 'mise à jour attendue',
  MISSING_INFO = 'informations manquantes',
}

export interface Props {
  upToDateCount: number;
  pendingUpdateCount: number;
  missingInfoCount: number;
  riskyUpToDateCount: number;
  riskyPendingUpdateCount: number;
  riskyMissingInfoCount: number;
  total: number;
  statusFilter: STATISTICS_TYPES;
  setStatusFilter: React.Dispatch<React.SetStateAction<STATISTICS_TYPES>>;
}
