import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { PerimeterState } from './Types';

export const getPerimeterState = (state: RootState) => state.perimeter;

export const getCompanies = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.perimeter?.companies
);

export const getCurrentBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentBrand
);

export const getCurrentAgency = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentAgency
);
export const getCurrentZone = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.zoneId
);
export const getCurrentRegion = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.regionId
);

export const getDefaultBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.perimeter?.defaultCompanyId ?? ''
);

export const getAnalyticsUserInfo = createSelector(
  [getPerimeterState],
  ({ regionId, zoneId, currentAgency: agencyId, branchId }: PerimeterState) => ({
    regionId,
    zoneId,
    agencyId,
    branchId,
  })
);

export default getPerimeterState;
