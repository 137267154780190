import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Qualification, QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualifications = (
  extraOptions?: UseMutationOptions<Qualification[], unknown, string>
) => {
  const societyCode = '001';
  return useMutation(
    [QueryKeys.fetchQualifications],
    async (keyword: string) => {
      const qualifications = await QualificationsService.qualificationsControllerGetQualifications({
        keyword,
        societyCode,
      });
      return qualifications;
    },
    { ...extraOptions }
  );
};
