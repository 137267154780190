import * as z from 'zod';

import { EnumPositionStudyRisksHasRisks } from 'src/Services/API';

export const risksSchema = z
  .object({
    hasRisks: z.nativeEnum(EnumPositionStudyRisksHasRisks),
    risks: z.array(z.object({ id: z.string(), label: z.string() })),
    otherRisks: z.string(),
  })
  .refine(
    ({ hasRisks, risks, otherRisks }) => {
      return !(
        hasRisks === EnumPositionStudyRisksHasRisks.Yes &&
        !otherRisks &&
        risks.length === 0
      );
    },
    {
      message: 'Vous devez renseigner au moins un risque si vous avez selectionné oui',
      path: ['hasRisks'],
    }
  );

export type RiskSchemaType = z.infer<typeof risksSchema>;
