import { DefaultIcon } from 'src/Assets';
import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { addOrRemove } from 'src/Utils/addOrRemove';

import { languageIconMapping } from '../LanguageEdition.types';

import { Props } from './MostUsedLanguageSelection.types';

const MostUsedLanguageSelection = ({
  field,
  mostUsedLanguages,
  ...selectionIconButtonsFormProps
}: Props) => {
  return (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      isLoading={false}
      loadingItems={6}
      isError={false}
      isSuccess={true}
      data={mostUsedLanguages.map(language => ({
        key: language.id,
        icon: languageIconMapping[language.id] ?? <DefaultIcon />,
        label: language.label,
        selected: field.value.find(({ id }) => id === language.id) !== undefined,
        onClick: () => {
          field.onChange(addOrRemove(field.value, language));
        },
      }))}
    />
  );
};

export default MostUsedLanguageSelection;
