import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  CompanyContact,
  CompaniesService,
  OSMConsultantModelDtoConsultantAgencyByCompany,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyContacts = (
  companyId: string,
  extraOptions?: UseQueryOptions<
    CompanyContact[],
    unknown,
    CompanyContact[],
    (string | OSMConsultantModelDtoConsultantAgencyByCompany | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyContacts, currentBrand, companyId],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const companyContacts = await CompaniesService.companiesControllerGetCompanyContacts({
        id: companyId,
        brandCode: currentBrand.brandCodeApiHeader,
      });
      return companyContacts.filter(contact => contact.isActiveOnCurrentCompany);
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
