import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ConsultantPerimeter, ConsultantsService } from 'src/Services/API';
import { isValidActivePerimeter } from 'src/Services/Perimeter';

import { useModalWithLocation } from '../Navigation';
import { QueryKeys } from '../types';

export const useFetchPerimeter = (
  extraOptions: UseQueryOptions<ConsultantPerimeter, unknown, ConsultantPerimeter, QueryKeys[]>
) => {
  const { isAuthenticated } = useAuth();

  return useQuery(
    [QueryKeys.fetchPerimeter],
    () => {
      return ConsultantsService.consultantsControllerGetPerimeter();
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};

export const useCheckActivePerimeter = () => {
  const dispatch = useDispatch();
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const { open } = useModalWithLocation('perimeter-selection');
  const { location } = useHistory();

  useFetchPerimeter({
    onSuccess: perimeter => {
      if (
        location.pathname !== '/perimeter-selection' &&
        !isValidActivePerimeter({ currentBrand, currentAgency, perimeter })
      ) {
        dispatch(perimeterActions.resetCurrent());
        open({});
      }
    },
  });
};
