import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import { TestsSchemaType } from './Tests.types';

export const TestsContext = React.createContext({
  control: {} as Control<TestsSchemaType>,
  watch: ((_name: string) => null) as UseFormWatch<TestsSchemaType>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submit: (): void => {},
});
