import {
  ExclusiveSelectionGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { EnumPositionStudyLearningPositionCriticality } from 'src/Services/API';

import styles from './PositionCriticalitySelection.module.scss';
import { Props } from './PositionCriticalitySelection.types';

const criticalities = Object.values(EnumPositionStudyLearningPositionCriticality);
const criticalitiesEnumMap = {
  [EnumPositionStudyLearningPositionCriticality.SIMPLE]: 'simple',
  [EnumPositionStudyLearningPositionCriticality.SEMI_CRITICAL]: 'semi-critique',
  [EnumPositionStudyLearningPositionCriticality.CRITICAL]: 'critique',
};

const PositionCriticalitySelection = ({ selected, setSelected }: Props) => {
  const isMobile = useSelector(getDevice);
  return (
    <WithLightTitle title="criticité du poste">
      <ExclusiveSelectionGroup<string>
        className={classnames({ [styles.mobile]: isMobile })}
        buttonClassName={classnames({ [styles.mobileButton]: isMobile })}
        values={criticalities}
        canBeReset={false}
        getStringValue={key =>
          criticalitiesEnumMap[key as keyof typeof EnumPositionStudyLearningPositionCriticality]
        }
        getKey={key => key ?? ''}
        setSelected={setSelected}
        selected={selected}
      />
    </WithLightTitle>
  );
};

export default PositionCriticalitySelection;
