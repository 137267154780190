import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { BonusRateCadencies, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchBonusRatesCadencies = (
  extraOptions?: UseQueryOptions<BonusRateCadencies[], unknown, BonusRateCadencies[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchBonusRatesCadencies],
    async () => {
      return await RepositoriesService.repositoriesControllerGetBonusRateCadencies();
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
