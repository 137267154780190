import {
  Button,
  FetchButton,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Wrong } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { IllusEDPCreation } from 'src/Assets';
import { useUpdatePositionStudyName } from 'src/Hooks/PositionStudies/useUpdatePositionStudyName';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { toFetchStatus } from 'src/Services/Async';
import { EDPNameModificationState } from 'src/Services/Routing';

import styles from './EDPRenameModal.module.scss';
import { EDPNameModificationSchema, Props } from './EDPRenameModal.types';

const EDPRenameModal = ({ onClose }: Props) => {
  const location = useLocation<EDPNameModificationState>();
  const modalParameters = location.state.modalParameters;
  const currentBrand = useSelector(getCurrentBrand);
  const renameMutation = useUpdatePositionStudyName();

  const EDPNameModificationZodResolver = useFormWithZodResolver({
    schema: EDPNameModificationSchema,
    defaultValues: {
      EDPName: modalParameters?.positionStudyName,
    },
  });
  const EDPName = EDPNameModificationZodResolver.watch('EDPName');
  return (
    <Modal
      open
      icon={<IllusEDPCreation />}
      title="renommer l'edp"
      onClose={() => {
        onClose();
      }}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() =>
            EDPNameModificationZodResolver.setValue(
              'EDPName',
              modalParameters?.positionStudyName ?? ''
            )
          }
        >
          réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          onClick={EDPNameModificationZodResolver.handleSubmit(() =>
            renameMutation.mutate({
              positionStudyId: modalParameters?.positionStudyId ?? '',
              body: {
                positionStudyName: EDPName,
                brandCode: currentBrand?.brandCodeApiHeader ?? '',
              },
            })
          )}
          fetchStatus={toFetchStatus(renameMutation)}
          onSuccess={onClose}
        />,
      ]}
    >
      <WithLightTitle title="nom pour votre étude" className={styles.container}>
        <TextInput
          name="EDPName"
          control={EDPNameModificationZodResolver.control}
          rightIcon={
            <Button.Tertiary
              onClick={() => EDPNameModificationZodResolver.setValue('EDPName', '')}
              className={styles.crossButton}
            >
              <Wrong />
            </Button.Tertiary>
          }
        />
      </WithLightTitle>
    </Modal>
  );
};

export default EDPRenameModal;
