import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, Department } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDepartments = (
  extraOptions?: UseQueryOptions<Department[], unknown, Department[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchDepartments],
    async () => {
      return RepositoriesService.repositoriesControllerGetDepartments();
    },
    { ...extraOptions }
  );
};
