import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchLanguages } from 'src/Hooks/Training/useFetchLanguages';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import { LanguageEdition } from './LanguageEdition';
import styles from './Languages.module.scss';

const Languages = () => {
  const { isLoading, isError, data: languagesRepository } = useFetchLanguages();
  const isMobile = useSelector(getDevice);

  if (isLoading)
    return (
      <div className={isMobile ? styles.mobileLoader : styles.loader}>
        <Loader heightInRem={isMobile ? 4 : 8} />
      </div>
    );

  if (isError || languagesRepository === undefined)
    return <ErrorMessage message="Une erreur est survenue dans la récupération des langues" />;

  return <LanguageEdition languagesRepository={languagesRepository} />;
};

export default Languages;
