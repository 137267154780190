import {
  DefaultIcon,
  RiskAtmoExplosif,
  RiskBruit,
  RiskChimique,
  RiskChute,
  RiskConfin,
  RiskElectricit,
  RiskFibreAmiante,
  RiskMachineProduction,
  RiskManutentionManuelle,
  RiskOutilsMaterielsElectroportatifs,
  RiskPoussiere,
  RiskRayonnement,
  RiskRoutier,
  RiskTemperature,
  RiskTravailEchafaudage,
  RiskTravailEnginsMouvement,
  RiskTravailExterieur,
  RiskTravailHauteur,
  RiskTravailIsol,
  RiskVibration,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { useFetchRisks } from 'src/Hooks/Security/useFetchRisks';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { Risk } from 'src/Services/API';
import { addOrRemove } from 'src/Utils/addOrRemove';

import styles from './RiskSelection.module.scss';
import { Props } from './RiskSelection.types';

const iconMapping: { [id: string]: JSX.Element } = {
  xEYMZUZcrlwjMTuY0q03X8t3mEuZNFxhldjZZ0Y1qMA1: <RiskChute />,
  uX6yzKOpLyw5Way7t6oBkst3mEuZNFxhldjZZ0Y1qMA1: <RiskTravailHauteur />,
  uDNxIvhSImhODGXOxiV5vMt3mEuZNFxhldjZZ0Y1qMA1: <RiskManutentionManuelle />,
  UjoLv4HSAzww4UGgYHGNBct3mEuZNFxhldjZZ0Y1qMA1: <RiskPoussiere />,
  niV_5V7SYEfvVuJmchqjG8t3mEuZNFxhldjZZ0Y1qMA1: <RiskRoutier />,
  pcfcqrwuGZ2vz_ByFIdKost3mEuZNFxhldjZZ0Y1qMA1: <RiskMachineProduction />,
  'cd3i0Dk-ZaUBz-JXsLDy2st3mEuZNFxhldjZZ0Y1qMA1': <RiskTravailEnginsMouvement />,
  '09Ibc75FQY9MQQFY4w8TzMt3mEuZNFxhldjZZ0Y1qMA1': <RiskOutilsMaterielsElectroportatifs />,
  '4pzGY8v3zzywxUinDJu-O8t3mEuZNFxhldjZZ0Y1qMA1': <RiskTemperature />,
  F0Km9onVdSOF9_5dTExd4st3mEuZNFxhldjZZ0Y1qMA1: <RiskChimique />,
  fJ_eO0UY1Vn1GOmIOERvkMt3mEuZNFxhldjZZ0Y1qMA1: <RiskElectricit />,
  '8PGw2emVgZ6zkinuIV2Wnst3mEuZNFxhldjZZ0Y1qMA1': <RiskRayonnement />,
  '2GdHBKYQ6-gYgLZxGFlTvMt3mEuZNFxhldjZZ0Y1qMA1': <RiskTravailExterieur />,
  '1piT-b1aD7xtqjSy4DXG88t3mEuZNFxhldjZZ0Y1qMA1': <RiskTravailIsol />,
  u67U4AxZwizlH4q5aXqpT8t3mEuZNFxhldjZZ0Y1qMA1: <RiskAtmoExplosif />,
  '5mVmNqL6Ov9ULFltjx9kSct3mEuZNFxhldjZZ0Y1qMA1': <RiskConfin />,
  IumMKExtetwSgq_QicRnSct3mEuZNFxhldjZZ0Y1qMA1: <RiskFibreAmiante />,
  xAdkQX1u6un8goncPyl4Xct3mEuZNFxhldjZZ0Y1qMA1: <RiskVibration />,
  EpCHNndltKnzELdKWJ5uIMt3mEuZNFxhldjZZ0Y1qMA1: <RiskTravailEchafaudage />,
  hJLDp7zr_PcWaoHS1k55bMt3mEuZNFxhldjZZ0Y1qMA1: <RiskBruit />,
};

const RiskSelection = ({ field, submit, otherRisks, ...selectionIconButtonsFormProps }: Props) => {
  const { data: risks, isLoading, isSuccess, isError } = useFetchRisks();
  const dispatch = useDispatch();
  const requiredFields = useSelector(getRequiredFields);
  const bollCond = !(field.value.length !== 0 || otherRisks !== '');
  useEffect(() => {
    const newRequiredFields = {
      ...requiredFields,
      security: {
        ...requiredFields.security,
        risks: {
          risks: bollCond,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bollCond, dispatch]);

  return (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      isLoading={isLoading}
      loadingItems={20}
      isError={isError}
      error="Une erreur est survenue lors de la récupération du référentiel des risques"
      isSuccess={isSuccess}
      selectIconButtonClassName={styles.riskCard}
      selectIconButtonLoaderClassName={styles.container}
      data={
        risks?.map(risk => ({
          key: risk.id,
          label: risk.label,
          icon: iconMapping[risk.id] ?? <DefaultIcon />,
          selected: field.value.find((selected: Risk) => selected.id === risk.id) !== undefined,
          onClick: () => {
            field.onChange(addOrRemove(field.value, risk));
            submit();
          },
        })) ?? []
      }
    />
  );
};

export default RiskSelection;
