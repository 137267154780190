import * as z from 'zod';

export const newBonusId = 'newBonusId';

export const bonusRateSchema = z
  .object({
    id: z.string().optional(),
    amount: z.number(),
    label: z.string(),
    cadency: z.object({
      id: z.string(),
      label: z.string(),
    }),
    liable: z.boolean().optional(),
  })
  .array();

export function removeEmptyBonusRateId(bonusList: z.infer<typeof bonusRateSchema>) {
  return bonusList?.map(bonus => {
    return {
      ...bonus,
      id: bonus.id === newBonusId ? undefined : bonus.id,
    };
  });
}
