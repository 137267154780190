import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  CreatePositionStudyParams,
  DetailedPositionStudy,
  PositionstudiesService,
} from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCreatePositionStudy = (
  extraOptions?: UseMutationOptions<
    DetailedPositionStudy,
    unknown,
    Omit<CreatePositionStudyParams, 'brandCode'>
  >
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createPositionStudy],
    async (positionStudy: Omit<CreatePositionStudyParams, 'brandCode'>) => {
      if (!currentBrand?.brandCodeApiHeader || !positionStudy.agencyId) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const createdPositionStudy =
        await PositionstudiesService.positionStudiesControllerCreatePositionStudy({
          body: { ...positionStudy, brandCode: currentBrand.brandCodeApiHeader },
        });

      ReactGA.event("Création d'une EDP", {
        ...userInfo,
      });

      return createdPositionStudy;
    },
    {
      onSuccess: (...args) => {
        const [createdPositionStudy] = args;
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudies'] });
        queryClient.setQueryData(
          [
            'fetchPositionStudyById',
            createdPositionStudy.generalInformation?.positionStudyId,
            currentBrand?.brandCodeApiHeader,
          ],
          createdPositionStudy
        );
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
