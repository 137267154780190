import {
  Checkbox,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { Diploma } from 'src/Assets';
import {
  EnumPositionStudyStudyLevelLevel,
  EnumPositionStudyStudyLevelPriority,
} from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { DiplomasContext } from '../DiplomasContext';

import styles from './StudyLevelSelection.module.scss';
import StudyLevelSelectionBadge from './StudyLevelSelectionBadge';
import { findStudyLevelFromLevel, STUDY_LEVEL_MAPPING_TO_STRING } from './utils';

export const StudyLevelSelection = () => {
  const diplomasContext = useContext(DiplomasContext);

  const selectedStudyLevels = diplomasContext.watch('studyLevels');

  const onClickCheckbox = (modifiedLevel: EnumPositionStudyStudyLevelLevel) => {
    diplomasContext.setValue(
      `studyLevels`,
      Object.keys(EnumPositionStudyStudyLevelLevel).map(basicLevel => {
        const basicStudyLevel = findStudyLevelFromLevel(
          basicLevel as EnumPositionStudyStudyLevelLevel,
          selectedStudyLevels
        );
        return {
          level: basicLevel as EnumPositionStudyStudyLevelLevel,
          selected:
            basicLevel === modifiedLevel
              ? !basicStudyLevel?.selected ?? true
              : basicStudyLevel?.selected ?? false,
          priority:
            basicLevel === modifiedLevel
              ? EnumPositionStudyStudyLevelPriority.UNKNOWN
              : basicStudyLevel?.priority ?? EnumPositionStudyStudyLevelPriority.UNKNOWN,
        };
      })
    );
    diplomasContext.diplomasHandleSubmit()();
  };

  return (
    <WithLightTitle
      className={styles.selectedContainer}
      title={"niveaux d'études"}
      rightTitleComponent={pluralFormat(
        selectedStudyLevels?.filter(study => study.selected).length,
        'sélectionné'
      )}
    >
      {Object.keys(EnumPositionStudyStudyLevelLevel).map(currentLevel => {
        return (
          <div key={`div-${currentLevel}`}>
            <div className={styles.separator} key={`separator-${currentLevel}`} />
            <HorizontalTextIconCard
              containerClassName={classNames({
                [styles.selectedCard]: findStudyLevelFromLevel(
                  currentLevel as EnumPositionStudyStudyLevelLevel,
                  selectedStudyLevels
                )?.selected,
              })}
              key={currentLevel}
              label={
                STUDY_LEVEL_MAPPING_TO_STRING[currentLevel as EnumPositionStudyStudyLevelLevel]
              }
              leftIcon={
                <div className={styles.leftIcon}>
                  <div className={styles.selectable}>
                    <Checkbox
                      checked={
                        findStudyLevelFromLevel(
                          currentLevel as EnumPositionStudyStudyLevelLevel,
                          selectedStudyLevels
                        )?.selected
                      }
                      onChange={() => {
                        onClickCheckbox(currentLevel as EnumPositionStudyStudyLevelLevel);
                      }}
                    />
                  </div>
                  <div className={styles.diplomaIcon}>
                    <Diploma />
                  </div>
                </div>
              }
              rightComponent={
                findStudyLevelFromLevel(
                  currentLevel as EnumPositionStudyStudyLevelLevel,
                  selectedStudyLevels
                )?.selected && (
                  <StudyLevelSelectionBadge
                    currentLevel={currentLevel as EnumPositionStudyStudyLevelLevel}
                  />
                )
              }
            />
          </div>
        );
      })}
      <div className={styles.separator} />
    </WithLightTitle>
  );
};
