import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, SIROrigin } from 'src/Services/API';

import { QueryKeys } from '../types';

const fetchSIROrigins = async () => {
  const sirs = await RepositoriesService.repositoriesControllerGetSirOrigins();
  return sirs;
};

export const useFetchSIROrigins = (
  extraOptions?: UseQueryOptions<SIROrigin[], unknown, SIROrigin[], QueryKeys[]>
) => {
  return useQuery([QueryKeys.fetchSIROrigins], fetchSIROrigins, {
    staleTime: 86400000,
    ...extraOptions,
  });
};
