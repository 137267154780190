import React, { useContext, useState } from 'react';

import BadgePicker from 'src/Components/BadgePicker';
import {
  EnumPositionStudyStudyLevelLevel,
  EnumPositionStudyStudyLevelPriority,
} from 'src/Services/API';

import { DiplomasContext } from '../../DiplomasContext';
import { findStudyLevelFromLevel } from '../utils';

import styles from './StudyLevelSelectionBadge.module.scss';
import { Props } from './StudyLevelSelectionBadge.types';
import { STUDY_LEVEL_PRIORITY_ITEMS } from './utils';

const StudyLevelSelectionBadge = ({ currentLevel }: Props) => {
  const diplomasContext = useContext(DiplomasContext);
  const selectedStudyLevels = diplomasContext.watch('studyLevels');

  const [selectedPriority, setSelectedPriority] = useState<EnumPositionStudyStudyLevelPriority>(
    findStudyLevelFromLevel(currentLevel, selectedStudyLevels)?.priority ??
      EnumPositionStudyStudyLevelPriority.UNKNOWN
  );

  const updateSelectedPriority = (newKeyPriority: string | number) => {
    setSelectedPriority(newKeyPriority as EnumPositionStudyStudyLevelPriority);
    diplomasContext.setValue(
      `studyLevels`,
      Object.keys(EnumPositionStudyStudyLevelLevel).map(basicStudyLevel => {
        return {
          level: basicStudyLevel as EnumPositionStudyStudyLevelLevel,
          selected:
            findStudyLevelFromLevel(
              basicStudyLevel as EnumPositionStudyStudyLevelLevel,
              selectedStudyLevels
            )?.selected ?? false,
          priority:
            basicStudyLevel === currentLevel
              ? (newKeyPriority as EnumPositionStudyStudyLevelPriority)
              : findStudyLevelFromLevel(
                  basicStudyLevel as EnumPositionStudyStudyLevelLevel,
                  selectedStudyLevels
                )?.priority ?? EnumPositionStudyStudyLevelPriority.UNKNOWN,
        };
      })
    );
    diplomasContext.diplomasHandleSubmit()();
  };

  return (
    <div className={styles.selectable}>
      <BadgePicker
        items={STUDY_LEVEL_PRIORITY_ITEMS}
        selectedItem={selectedPriority}
        onChange={updateSelectedPriority}
      />
    </div>
  );
};

export default StudyLevelSelectionBadge;
