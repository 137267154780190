import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { forwardRef } from 'react';

import styles from './AddButton.module.scss';
import { Props } from './AddButton.types';

const AddButton = forwardRef<HTMLButtonElement, Props>(
  ({ text, className, disabled, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        className={classnames(styles.container, { [styles.disabled]: disabled }, className)}
        disabled={disabled ?? false}
        {...buttonProps}
      >
        <PlusCircle
          className={classnames(styles.plusCircleIcon, { [styles.disabled]: disabled })}
        />
        <p>{text}</p>
      </button>
    );
  }
);

export default AddButton;
