import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Euro } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ErrorMessage } from '@randstad-lean-mobile-factory/react-form-fields';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  DefaultIcon,
  NonSoumiseBus,
  NonSoumiseChantier,
  NonSoumisePanier,
  NonSoumiseRepas,
  NonSoumiseRepasEquipeJour,
  NonSoumiseRepasEquipeNuit,
  NonSoumiseSalissure,
  NonSoumiseSandwich,
  Soumise13Mois,
  SoumiseBus,
  SoumiseChantier,
  SoumiseDouche,
  SoumisePanier,
  SoumisePause,
  SoumiseRepas,
  SoumiseRepasEquipeJour,
  SoumiseRepasEquipeNuit,
  SoumiseSalissure,
  SoumiseSandwich,
  SoumiseTeamJour,
  SoumiseTeamNuit,
  SoumiseVetements,
} from 'src/Assets';
import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { SelectionIconButtonWithMobile } from 'src/Components/SelectionIconButtonWithMobile';
import { useFetchPresetBonuses } from 'src/Hooks/Bonus/useFetchPresetBonuses';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import styles from './BonusRatesCategories.module.scss';
import { Props } from './BonusRatesCategories.types';

export const unliableIconMapping: Record<string, ReactNode> = {
  transport: <NonSoumiseBus />,
  chantier: <NonSoumiseChantier />,
  panier: <NonSoumisePanier />,
  repas: <NonSoumiseRepas />,
  'repas équipe nuit': <NonSoumiseRepasEquipeNuit />,
  'repas équipe jour': <NonSoumiseRepasEquipeJour />,
  salissure: <NonSoumiseSalissure />,
  'casse croute': <NonSoumiseSandwich />,
};

export const liableIconMapping: Record<string, ReactNode> = {
  transport: <SoumiseBus />,
  chantier: <SoumiseChantier />,
  panier: <SoumisePanier />,
  repas: <SoumiseRepas />,
  'repas équipe nuit': <SoumiseRepasEquipeNuit />,
  'repas équipe jour': <SoumiseRepasEquipeJour />,
  salissure: <SoumiseSalissure />,
  'casse croute': <SoumiseSandwich />,
  pause: <SoumisePause />,
  '13ème mois': <Soumise13Mois />,
  'équipe nuit': <SoumiseTeamNuit />,
  'équipe jour': <SoumiseTeamJour />,
  douche: <SoumiseDouche />,
  habillage: <SoumiseVetements />,
};

const BonusRatesCategories = ({ field, error }: Props) => {
  const isMobile = useSelector(getDevice);
  const { data: presetBonuses, isLoading, isError, isSuccess } = useFetchPresetBonuses();

  return (
    <>
      <SelectionIconButtonsForm
        title="indemnité non assujetti"
        displaySelected={false}
        isLoading={isLoading}
        loadingItems={6}
        isError={isError}
        error="Une erreur est survenue lors de la récupération du référentiel des primes"
        isSuccess={isSuccess}
        data={presetBonuses
          ?.filter(preset => !!unliableIconMapping[preset.id])
          .map(preset => ({
            key: preset.id,
            icon: unliableIconMapping[preset.id] ?? <DefaultIcon />,
            label: preset.id,
            selected: field.value?.id === preset.id && !field.value?.liable,
            onClick: () =>
              field.value?.id === preset.id && field.value?.liable === false
                ? field.onChange(undefined)
                : field.onChange({ id: preset.id, liable: false }),
          }))}
      />

      <SelectionIconButtonsForm
        title="indemnité assujetti"
        displaySelected={false}
        isLoading={isLoading}
        loadingItems={14}
        isError={isError}
        error="Une erreur est survenue lors de la récupération du référentiel des primes"
        isSuccess={isSuccess}
        data={presetBonuses
          ?.filter(preset => !!liableIconMapping[preset.id])
          .map(preset => ({
            key: preset.id,
            icon: liableIconMapping[preset.id] ?? <DefaultIcon />,
            label: preset.id,
            selected: field.value?.id === preset.id && field.value?.liable,
            onClick: () =>
              field.value?.id === preset.id && field.value?.liable === true
                ? field.onChange(undefined)
                : field.onChange({ id: preset.id, liable: true }),
          }))}
      />

      <WithLightTitle title="autre prime">
        <SelectionIconButtonWithMobile.Component
          label="nouvelle prime"
          icon={<Euro />}
          selected={field.value?.id === ''}
          onClick={() =>
            field.value?.id === ''
              ? field.onChange(undefined)
              : field.onChange({ id: '', liable: false })
          }
          className={isMobile ? styles.otherBonusMobile : styles.otherBonusDesktop}
        />
      </WithLightTitle>
      <ErrorMessage error={error} />
    </>
  );
};

export default BonusRatesCategories;
