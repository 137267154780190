import * as z from 'zod';

import { EnumPositionStudyTestsInternTest } from 'src/Services/API';

export const testsSchema = z
  .object({
    hasInternTest: z.nativeEnum(EnumPositionStudyTestsInternTest),
    hasETests: z.boolean(),
    eTests: z.array(z.object({ id: z.string(), label: z.string() })),
  })
  .refine(
    ({ hasETests, eTests }) => {
      return !(hasETests && eTests.length === 0);
    },
    {
      message: 'Vous devez renseigner au moins un e-test',
      path: ['eTests'],
    }
  );
