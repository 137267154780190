import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { useDuplicateEDPWithNewQualification } from 'src/Hooks/PositionStudies/useDuplicateEDPWithNewQualification';
import { toFetchStatus } from 'src/Services/Async';
import { EDPCreationState, getEditionRoute } from 'src/Services/Routing';

import EDPCreationModal from '..';

const EDPDuplicationModal = () => {
  const location = useLocation<EDPCreationState>();
  const duplicatePositionStudyMutation = useDuplicateEDPWithNewQualification();
  const positionStudyId = location.state.modalParameters?.positionStudyId;
  const history = useHistory();
  return (
    <EDPCreationModal
      onValidate={(selectedName: string, qualificationId: string) => {
        duplicatePositionStudyMutation.mutate({
          positionStudyId: positionStudyId ?? '',
          body: {
            positionStudyName: selectedName,
            qualificationId: qualificationId,
          },
        });
      }}
      onSuccess={() =>
        history.push(
          getEditionRoute({
            companyId: duplicatePositionStudyMutation.data?.generalInformation?.company?.companyId,
            positionStudyId:
              duplicatePositionStudyMutation.data?.generalInformation?.positionStudyId,
            section: 'hr/profile',
          })
        )
      }
      fetchStatus={toFetchStatus(duplicatePositionStudyMutation)}
    />
  );
};

export default EDPDuplicationModal;
