import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ElectricVoltage, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchElectricHabilitations = (
  extraOptions?: UseQueryOptions<ElectricVoltage[], unknown, ElectricVoltage[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchElectricHabilitations],
    async () => {
      return RepositoriesService.repositoriesControllerGetElectricHabilitations();
    },
    { ...extraOptions }
  );
};
