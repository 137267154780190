import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './HorizontalCardWithIcon.module.scss';
import { Props } from './HorizontalCardWithIcon.types';

const HorizontalCardWithIcon = ({
  label,
  icon,
  selected,
  onClick,
  className,
  subLabel,
  iconClassName,
}: Props) => (
  <div
    className={classnames(styles.container, { [styles.selected]: selected }, className)}
    onClick={onClick}
  >
    <div className={classnames(styles.icon, iconClassName)}>{icon}</div>
    <div className={classnames(styles.identifier, { [styles.selectedText]: selected })}>
      {label}
      <div className={styles.subLabelClassname}>{subLabel}</div>
    </div>
    {selected && (
      <div className={styles.checkIcon}>
        <Check />
      </div>
    )}
  </div>
);

export default HorizontalCardWithIcon;
