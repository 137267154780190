import { Animation, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import React from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  LicenseA,
  LicenseA1,
  LicenseB,
  LicenseBE,
  LicenseC,
  LicenseC1,
  LicenseC1E,
  LicenseCE,
  LicenseD,
  LicenseD1,
  LicenseD1E,
  LicenseDE,
} from 'src/Assets';
import HorizontalCardWithIcon from 'src/Components/HorizontalCardWithIcon/HorizontalCardWithIcon.component';
import { useFetchDrivingLicenses } from 'src/Hooks/Training/useFetchDrivingLicenses';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { getDevice } from '../../../../Redux/UserPreferences/Selectors';
import DrivingLicenseHabilitationModal from '../DrivingLicenseHabilitationModal';

import styles from './DrivingLicenseList.module.scss';
import { Props } from './DrivingLicenseList.types';

const truckLicensesIds = ['C', 'EC', 'C1', 'C1E'];
const iconMapping = {
  A: <LicenseA />,
  A1: <LicenseA1 />,
  B: <LicenseB />,
  C: <LicenseC />,
  D: <LicenseD />,
  EB: <LicenseBE />,
  EC: <LicenseCE />,
  ED: <LicenseDE />,
  C1: <LicenseC1 />,
  C1E: <LicenseC1E />,
  D1E: <LicenseD1E />,
  D1: <LicenseD1 />,
};

const DrivingLicenseList = ({ control, habilitations, submit }: Props) => {
  const drivingLicenseQuery = useFetchDrivingLicenses();
  const licenses = drivingLicenseQuery.data ?? [];
  const isMobile = useSelector(getDevice);

  const licensesWithIcon = licenses.map(element => {
    return {
      licenseInformation: element,
      icon: iconMapping[element.id as keyof typeof iconMapping],
    };
  });

  const drivingLicenseController = useController({ name: 'drivingLicenses', control });
  const drivingLicenseHabilitationsController = useController({
    name: 'habilitations',
    control,
  });
  return (
    <div className={styles.container}>
      <WithLightTitle
        title="sélectionner parmi la liste suivante"
        rightTitleComponent={pluralFormat(
          drivingLicenseController.field.value?.length ?? 0,
          'sélectionné'
        )}
      >
        {drivingLicenseQuery.isSuccess &&
          licensesWithIcon.map(license => {
            const isSelected =
              drivingLicenseController.field.value
                ?.map(licenseItem => licenseItem.id)
                .includes(license.licenseInformation.id) ?? false;
            return (
              <>
                <HorizontalCardWithIcon
                  label={license.licenseInformation.id}
                  subLabel={license.licenseInformation.label}
                  className={classNames({ [styles.mobileHeight]: isMobile })}
                  icon={license.icon}
                  iconClassName={styles.iconClassname}
                  selected={isSelected}
                  onClick={() => {
                    drivingLicenseController.field.onChange(
                      addOrRemove(
                        drivingLicenseController.field.value ?? [],
                        license.licenseInformation
                      )
                    );
                    submit();
                  }}
                />
                <Animation.Unroll
                  visible={isSelected && truckLicensesIds.includes(license.licenseInformation.id)}
                >
                  <DrivingLicenseHabilitationModal
                    currentHabilitations={habilitations ?? []}
                    onValidate={newHabilitations => {
                      drivingLicenseHabilitationsController.field.onChange(newHabilitations);
                      submit();
                    }}
                  />
                </Animation.Unroll>
              </>
            );
          })}
      </WithLightTitle>
    </div>
  );
};

export default DrivingLicenseList;
