import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, EmployeeStatus } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchEmployeeStatuses = (
  extraOptions?: UseQueryOptions<EmployeeStatus[], unknown, EmployeeStatus[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchEmployeeStatuses],
    async () => {
      const employeeStatuses =
        await RepositoriesService.repositoriesControllerGetEmployeeStatuses();
      return employeeStatuses;
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
