import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { getDevice } from '../../Redux/UserPreferences/Selectors';
import { SelectionIconButtonWithMobile } from '../SelectionIconButtonWithMobile';

import styles from './SelectionIconButtonsForm.module.scss';
import { Props } from './SelectionIconButtonsForm.types';

const SelectionIconButtonsForm = ({
  title,
  isLoading,
  loadingItems,
  isError,
  isSuccess,
  isVariableSize,
  selectIconButtonClassName,
  selectIconButtonLoaderClassName,
  error,
  data,
  displaySelected = true,
}: Props) => {
  const isMobile = useSelector(getDevice);
  const selected =
    displaySelected && isSuccess && data
      ? pluralFormat(data.filter(({ selected }) => selected).length, 'sélectionné')
      : undefined;

  return (
    <>
      <div className={styles.gridTitleContainer}>
        <h3 className={styles.subtitle}>{title}</h3>
        <p className={isMobile ? styles.mobileSelectedNumber : styles.selectedNumber}>{selected}</p>
      </div>
      <div
        className={classnames(
          isMobile ? styles.mobileGridContainer : styles.gridContainer,
          isVariableSize && !isMobile ? styles.variableSizeButtons : ''
        )}
      >
        {isLoading &&
          new Array(loadingItems).fill(undefined).map((_, index) => {
            return (
              <div className={isMobile ? styles.mobileWidth : ''}>
                <SelectionIconButtonWithMobile.Loading
                  key={index}
                  className={selectIconButtonLoaderClassName}
                />
              </div>
            );
          })}

        {isError && <ErrorMessage message={error} />}

        {isSuccess && (
          <>
            {data?.map((iconButtonProps, index: number) => {
              return (
                <div key={index} className={isMobile ? styles.mobileWidth : ''}>
                  <SelectionIconButtonWithMobile.Component
                    {...iconButtonProps}
                    className={selectIconButtonClassName}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default SelectionIconButtonsForm;
