import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { MoinsCircle, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  InclusiveSelectionGroup,
  TimePicker,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import commonStyles from 'src/Utils/commonStyles.module.scss';

import { ProfileContext } from '../../../ProfileContext';

import styles from './WorkingHourItem.module.scss';
import { Props } from './WorkingHourItem.types';

export const daysRef = [
  { key: 'Lun', label: 'L' },
  { key: 'Mar', label: 'M' },
  { key: 'Mer', label: 'M' },
  { key: 'Jeu', label: 'J' },
  { key: 'Ven', label: 'V' },
  { key: 'Sam', label: 'S' },
  { key: 'Dim', label: 'D' },
];

const WorkingHourItem = ({
  index,
  remove,
  className,
  defaultValue,
  isLastWorkingHourItem,
}: Props) => {
  const isMobile = useSelector(getDevice);
  const profileContext = useContext(ProfileContext);
  const name = `workingHours.${index}` as const;

  const workingHourDaysValue = profileContext.watch(`${name}.days`);

  useEffect(() => {
    if (profileContext.control._getDirty()) {
      profileContext.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingHourDaysValue]);
  return (
    <div
      className={classNames(
        isMobile ? commonStyles.column : commonStyles.row,
        isMobile ? styles.mobileContainer : styles.container,
        className
      )}
    >
      <WithLightTitle title="horaires de travail">
        <div className={commonStyles.row}>
          <TimePicker
            name={`${name}.startTime`}
            control={profileContext.control}
            onBlurCapture={profileContext.submit}
            defaultValue={defaultValue.startTime}
            className={isMobile ? styles.mobileFirstTimePicker : styles.firstTimePicker}
          />
          <TimePicker
            name={`${name}.endTime`}
            control={profileContext.control}
            onBlurCapture={profileContext.submit}
            defaultValue={defaultValue.endTime}
            className={isMobile ? styles.mobileSecondTimePicker : styles.secondTimePicker}
          />
        </div>
      </WithLightTitle>
      <div className={isMobile ? styles.mobileDaysContainer : styles.daysContainer}>
        <WithLightTitle title="jours">
          <InclusiveSelectionGroup
            buttonClassname={isMobile ? styles.mobileDayButton : styles.dayButton}
            name={`${name}.days`}
            control={profileContext.control}
            defaultValue={defaultValue.days}
            values={daysRef}
            getKey={({ key }) => key}
            getStringValue={({ label }) => label}
            getSelectedFormValue={values => values.map(({ key }) => key)}
            getSelectedValues={(keys?: string[]) =>
              keys
                ?.map(currenKey => daysRef.find(({ key }) => key === currenKey))
                .filter((value): value is { key: string; label: string } => value !== undefined) ??
              []
            }
          />
        </WithLightTitle>
      </div>
      <button
        className={isMobile ? styles.mobileRemoveButton : styles.removeButton}
        onClick={remove}
      >
        {isMobile ? <Trashcan /> : <MoinsCircle className={styles.removeButtonIcon} />}
      </button>
      {isMobile && !isLastWorkingHourItem && <div className={styles.separator} />}
    </div>
  );
};

export default WorkingHourItem;
