import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { DetailedPositionStudy } from 'src/Services/API';

import { useFetchPositionStudyById } from '.';

export const useFetchPositionStudyFromURL = (
  extraOptions?: UseQueryOptions<
    DetailedPositionStudy,
    unknown,
    DetailedPositionStudy,
    (string | undefined)[]
  >
) => {
  const positionStudyId = useParams<{ positionStudyId: string }>().positionStudyId;
  return useFetchPositionStudyById(positionStudyId, extraOptions);
};
