import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation, Switch, Router, Route } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import { useCheckActivePerimeter } from 'src/Hooks/Perimeter';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import {
  CompanyResultRoute,
  CompanyResultState,
  LocationState,
  PositionStudyRoute,
} from 'src/Services/Routing';
import history from 'src/Utils/history';

import CompanyStudies from '../CompanyStudies';
import Home from '../Home';
import PositionStudyModification from '../PositionStudyModification';

import styles from './MainPage.module.scss';
import MobileTopBar from './MobileTopBar';
import TopBar from './TopBar';

const companyRoute: CompanyResultRoute = {
  route: '/company/:companyId?',
  component: CompanyStudies,
};
const positionStudyRoute: PositionStudyRoute = {
  route: '/company/:companyId?/study/:positionStudyId/:firstSection?/:secondSection?',
  component: PositionStudyModification,
};

const MainPage = () => {
  useCheckActivePerimeter();
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const companyResultLocation = useLocation<CompanyResultState>();
  const companyResultLocationState = companyResultLocation.state;
  const isMobile = useSelector(getDevice);

  return (
    <div className={styles.container}>
      {isMobile ? <MobileTopBar /> : <TopBar />}
      <Router history={history}>
        <Switch location={background || location}>
          <PrivateRoute exact path={match.path} component={Home} />
          {/* TODO remove children */}
          <PrivateRoute
            exact
            path={companyRoute.route}
            children={
              <companyRoute.component
                routeParameters={companyResultLocationState?.routeParameters}
              />
            }
          />
          <Route path={positionStudyRoute.route} component={PositionStudyModification} />
        </Switch>
      </Router>
    </div>
  );
};

export default MainPage;
