import {
  HorizontalCardFoldable,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusRLCACESSmall,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as z from 'zod';

import {
  CacesChantier,
  CacesChariot,
  CacesEchafaudage,
  CacesGrue,
  CacesNacelle,
  CacesPontsRoulants,
  DefaultIcon,
} from 'src/Assets';
import CACESPicker from 'src/Components/CACESPicker';
import HorizontalCardWithIcon from 'src/Components/HorizontalCardWithIcon/HorizontalCardWithIcon.component';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchCaces } from 'src/Hooks/Training/useFetchCaces';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './Caces.module.scss';

const schema = z.object({
  habilitations: z.array(z.object({ id: z.string(), label: z.string() })),
});

const iconMapping = {
  'Engins de chantier': <CacesChantier />,
  'Chariots élévateurs': <CacesChariot />,
  Grues: <CacesGrue />,
  'Nacelles élévatrices': <CacesNacelle />,
  Echafaudages: <CacesEchafaudage />,
  'Ponts roulants': <CacesPontsRoulants />,
};

const Caces = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const { data, isSuccess, isLoading } = useFetchCaces();
  const cacesWithIcon = data?.map(cacesCategory => {
    return {
      caces: cacesCategory.caces,
      categoryLabel: cacesCategory.categoryLabel,
      categoryId: cacesCategory.categoryId,
      icon: iconMapping[cacesCategory.categoryId as keyof typeof iconMapping] ?? <DefaultIcon />,
    };
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema,
    defaultValues: {
      habilitations: fetchPositionStudy.data?.habilitations,
    },
  });
  const [unfoldedCacesCategory, setUnfoldedCacesCategory] = useState<string | undefined>(undefined);

  const { field } = useController({ name: 'habilitations', control });
  const cacesInHabilitations = field.value.filter(habilitation =>
    data
      ?.map(cacesCategory => cacesCategory.caces)
      .flat(1)
      .map(item => item.id)
      .includes(habilitation.id)
  );

  const cacesHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            habilitations: values.habilitations ?? [],
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = cacesHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2>votre choix</h2>
          <WithLightTitle
            title="choisir parmi les catégories ci-dessous"
            className={styles.picker}
            rightTitleComponent={pluralFormat(cacesInHabilitations.length, 'sélectionné')}
          >
            <div className={styles.separator}></div>
            {isLoading &&
              new Array(6).fill(undefined).map((_, index) => (
                <ContentLoader
                  key={`caces-content-loader-${index}`}
                  height="4rem"
                  width="100%"
                  uniqueKey="criteriaButton"
                >
                  <rect x="2%" y="20" rx="4" ry="4" width="85%" height="20" />
                </ContentLoader>
              ))}
            {isSuccess &&
              cacesWithIcon?.map(cacesCategoryWithIcon => {
                const selectedCacesIdsInCategory = cacesInHabilitations
                  .map(selectedCacesItem => selectedCacesItem.id)
                  .filter(selectedCacesId =>
                    cacesCategoryWithIcon.caces
                      .map(cacesItem => cacesItem.id)
                      .includes(selectedCacesId)
                  );
                return (
                  <React.Fragment key={`fragment-${cacesCategoryWithIcon.categoryId}`}>
                    <HorizontalCardFoldable
                      label={cacesCategoryWithIcon.categoryLabel}
                      leftIcon={cacesCategoryWithIcon.icon}
                      isOpen={unfoldedCacesCategory === cacesCategoryWithIcon.categoryId}
                      onRightIconClick={
                        unfoldedCacesCategory === cacesCategoryWithIcon.categoryId
                          ? () => setUnfoldedCacesCategory(undefined)
                          : () => setUnfoldedCacesCategory(cacesCategoryWithIcon.categoryId)
                      }
                      rightComponent={
                        selectedCacesIdsInCategory &&
                        selectedCacesIdsInCategory.length > 0 && (
                          <div className={styles.rightSide}>{`${
                            selectedCacesIdsInCategory.length
                          } sélectionné${selectedCacesIdsInCategory.length > 1 ? 's' : ''}`}</div>
                        )
                      }
                      children={cacesCategoryWithIcon.caces.map(cacesItem => {
                        return (
                          <HorizontalCardWithIcon
                            className={isMobile ? styles.mobileCacesCard : styles.cacesCard}
                            selected={
                              field.value.find(
                                selectedCaces => selectedCaces.id === cacesItem.id
                              ) !== undefined
                            }
                            key={cacesItem.id}
                            label={cacesItem.label}
                            onClick={() => field.onChange(addOrRemove(field.value, cacesItem))}
                          />
                        );
                      })}
                    />
                    <div className={styles.separator}></div>
                  </React.Fragment>
                );
              })}
          </WithLightTitle>
          <WithLightTitle
            title="ou rechercher parmi l'ensemble des CACES"
            className={styles.generalPicker}
          >
            <CACESPicker
              caces={
                data !== undefined ? data?.map(cacesCategory => cacesCategory.caces).flat(1) : []
              }
              selectedCaces={field.value}
              setCaces={field.onChange}
            />
          </WithLightTitle>
          {cacesInHabilitations.length > 0 && (
            <WithLightTitle
              className={styles.selectedContainer}
              title={pluralFormat(cacesInHabilitations.length, 'CACES sélectionné')}
            >
              {cacesInHabilitations.map(caces => {
                return (
                  <HorizontalTextIconCard
                    containerClassName={styles.selectedCard}
                    leftIcon={<IllusRLCACESSmall />}
                    key={caces.id}
                    label={caces.label}
                    rightIcon={<Trashcan />}
                    onRightIconClick={() => {
                      field.onChange(field.value.filter(({ id }) => id !== caces.id));
                    }}
                  />
                );
              })}
            </WithLightTitle>
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Caces;
