import { Animation, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  ExclusiveSelectionGroup,
  TextArea,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as z from 'zod';

import { SIREye, StrenuousFactorsIllus } from 'src/Assets';
import FoldableSelectionList from 'src/Components/FoldableSelectionList';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchSIR } from 'src/Hooks/Security/useFetchSIR';
import { useFetchSIROrigins } from 'src/Hooks/Security/useFetchSIROrigins';
import { useFetchStrenuousFactors } from 'src/Hooks/Security/useFetchStrenuousFactors';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  EnumPositionStudyHandicapsExposedToCmrAgents,
  EnumPositionStudyHandicapsExposedToForbiddenWork,
  EnumPositionStudyHandicapsExposedToStreniousFactors,
  EnumPositionStudyHandicapsHasEstlablishedListOfRiskyPositions,
  EnumPositionStudyHandicapsIncompatibleWithHandicaps,
  EnumPositionStudyHandicapsIsARiskyPosition,
  EnumPositionStudyHandicapsNeedSir,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { addOrRemove } from 'src/Utils/addOrRemove';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './Handicaps.module.scss';

const schema = z
  .object({
    hasEstlablishedListOfRiskyPositions: z.nativeEnum(
      EnumPositionStudyHandicapsHasEstlablishedListOfRiskyPositions
    ),
    isARiskyPosition: z.nativeEnum(EnumPositionStudyHandicapsIsARiskyPosition),
    needSir: z.nativeEnum(EnumPositionStudyHandicapsNeedSir),
    exposedToForbiddenWork: z.nativeEnum(EnumPositionStudyHandicapsExposedToForbiddenWork),
    exposedToStreniousFactors: z.nativeEnum(EnumPositionStudyHandicapsExposedToStreniousFactors),
    incompatibleWithHandicaps: z.nativeEnum(EnumPositionStudyHandicapsIncompatibleWithHandicaps),
    handicapIncompatibilityDetail: z.string().max(500).optional(),
    sirs: z.array(z.object({ id: z.string(), label: z.string() })),
    strenuousFactors: z.array(z.object({ id: z.string() })),
    exposedToCmrAgents: z.nativeEnum(EnumPositionStudyHandicapsExposedToCmrAgents),
    sirOrigin: z.string().optional(),
  })
  .refine(
    ({ exposedToStreniousFactors, strenuousFactors }) => {
      return (
        exposedToStreniousFactors !== EnumPositionStudyHandicapsExposedToStreniousFactors.Yes ||
        strenuousFactors.length > 0
      );
    },
    {
      message: 'Vous devez sélectionner au moins un facteur de pénibilité',
      path: ['strenuousFactors'],
    }
  )
  .refine(
    ({ needSir, sirs }) => {
      return needSir !== EnumPositionStudyHandicapsNeedSir.Yes || sirs.length > 0;
    },
    { message: 'Vous devez sélectionner au moins un suivi', path: ['sirs'] }
  );

const Handicaps = () => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const initialValues = fetchPositionStudy.data?.handicaps;
  const isMobile = useSelector(getDevice);

  const { data: sirList } = useFetchSIR();
  const { data: strenuousFactors } = useFetchStrenuousFactors();
  const { data: sirOrigins } = useFetchSIROrigins();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema,
    defaultValues: {
      hasEstlablishedListOfRiskyPositions: initialValues?.hasEstlablishedListOfRiskyPositions,
      isARiskyPosition: initialValues?.isARiskyPosition,
      needSir: initialValues?.needSir,
      exposedToForbiddenWork: initialValues?.exposedToForbiddenWork,
      exposedToStreniousFactors: initialValues?.exposedToStreniousFactors,
      incompatibleWithHandicaps: initialValues?.incompatibleWithHandicaps,
      handicapIncompatibilityDetail: initialValues?.handicapIncompatibilityDetail,
      sirs: initialValues?.sirs ?? [],
      strenuousFactors: initialValues?.strenuousFactors.map(id => ({ id })) ?? [],
      sirOrigin: initialValues?.sirOrigin ?? '',
      exposedToCmrAgents: initialValues?.exposedToCmrAgents,
    },
  });

  const hasEstlablishedListOfRiskyPositions = watch('hasEstlablishedListOfRiskyPositions');
  const isARiskyPosition = watch('isARiskyPosition');
  const needsSir = watch('needSir');
  const sirOrigin = watch('sirOrigin');
  const exposedToForbiddenWork = watch('exposedToForbiddenWork');
  const exposedToStreniousFactors = watch('exposedToStreniousFactors');
  const exposedToCmrAgents = watch('exposedToCmrAgents');
  const incompatibleWithHandicaps = watch('incompatibleWithHandicaps');
  const handicapIncompatibilityDetail = watch('handicapIncompatibilityDetail');

  const {
    field: sirField,
    fieldState: { error: sirError },
  } = useController({ name: 'sirs', control });

  const {
    field: strenuousFactorsField,
    fieldState: { error: strenuousFactorError },
  } = useController({ name: 'strenuousFactors', control });

  const handicapsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            handicaps: {
              ...values,
              handicapIncompatibilityDetail:
                values.incompatibleWithHandicaps ===
                EnumPositionStudyHandicapsIncompatibleWithHandicaps.Yes
                  ? values.handicapIncompatibilityDetail
                  : undefined,
              sirs: values.needSir === EnumPositionStudyHandicapsNeedSir.Yes ? values.sirs : [],
              sirOrigin:
                values.needSir === EnumPositionStudyHandicapsNeedSir.Yes
                  ? values.sirOrigin
                  : undefined,
              strenuousFactors:
                values.exposedToStreniousFactors ===
                EnumPositionStudyHandicapsExposedToStreniousFactors.Yes
                  ? values.strenuousFactors.map(({ id }) => id)
                  : [],
              exposedToCmrAgents: values.exposedToCmrAgents,
            },
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = handicapsHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasEstlablishedListOfRiskyPositions,
    isARiskyPosition,
    needsSir,
    sirField.value,
    sirOrigin,
    exposedToForbiddenWork,
    exposedToStreniousFactors,
    strenuousFactorsField.value,
    exposedToCmrAgents,
    incompatibleWithHandicaps,
  ]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2 className={styles.title}>votre choix</h2>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>
              Votre entreprise a t-elle établi la liste des postes à risques particuliers ?
            </p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm
                name="hasEstlablishedListOfRiskyPositions"
                control={control}
              />
            </div>
          </div>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>Ce poste fait-il partie des postes à risques ?</p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="isARiskyPosition" control={control} />
            </div>
          </div>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>Ce poste nécessite t-il un Suivi Individuel Renforcé ?</p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="needSir" control={control} />
            </div>
          </div>
          <ErrorMessage error={sirError} />
          {needsSir === EnumPositionStudyHandicapsNeedSir.Yes && (
            <>
              <FoldableSelectionList
                className={isMobile ? styles.mobileFoldableContent : styles.foldableContent}
                title="liste des Suivis Individuels Renforcés"
                lightTitle="lesquels ?"
                icon={<SIREye />}
                selectedCount={sirField.value.length}
                data={sirList ?? []}
                onClick={sir => sirField.onChange(addOrRemove(sirField.value, sir))}
                keyExtractor={sir => sir.id}
                labelExtractor={sir => sir.label}
                isSelectedExtractor={sir =>
                  sirField.value.find(selectedSir => selectedSir.id === sir.id) !== undefined
                }
              />
              <WithLightTitle
                title="quelle est leur origine ?"
                className={styles.sirOriginSelection}
              >
                <ExclusiveSelectionGroup
                  control={control}
                  name="sirOrigin"
                  values={sirOrigins ?? []}
                  getKey={item => item.id}
                  getStringValue={item => item.label}
                  getSelectedValue={value => sirOrigins?.find(origin => origin.id === value)}
                  getSelectedFormValue={item => item?.id}
                />
              </WithLightTitle>
            </>
          )}

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>
              Ce poste est-il exposé à des travaux interdits aux intérimaires ?
            </p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="exposedToForbiddenWork" control={control} />
            </div>
          </div>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>Ce poste est-il exposé à des facteurs de pénibilité ?</p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="exposedToStreniousFactors" control={control} />
            </div>
          </div>
          <ErrorMessage error={strenuousFactorError} />
          {exposedToStreniousFactors ===
            EnumPositionStudyHandicapsExposedToStreniousFactors.Yes && (
            <FoldableSelectionList
              className={isMobile ? styles.mobileFoldableContent : styles.foldableContent}
              title="liste des facteurs de pénibilité"
              lightTitle="lesquels ?"
              icon={<StrenuousFactorsIllus />}
              selectedCount={strenuousFactorsField.value.length}
              data={strenuousFactors ?? []}
              onClick={({ id }) =>
                strenuousFactorsField.onChange(addOrRemove(strenuousFactorsField.value, { id }))
              }
              keyExtractor={factor => factor.id}
              labelExtractor={factor => factor.label}
              isSelectedExtractor={factor =>
                strenuousFactorsField.value.find(
                  selectedFactor => selectedFactor.id === factor.id
                ) !== undefined
              }
            />
          )}

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>Ce poste est-il exposé à des agents CMR ?</p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="exposedToCmrAgents" control={control} />
            </div>
          </div>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>
              y a-t-il à ce poste des contraintes incompatibles avec des typologies de handicap ?
            </p>
            <div className={classNames({ [styles.mobileTripleChoice]: isMobile })}>
              <TripleChoiceButtonForm name="incompatibleWithHandicaps" control={control} />
            </div>
          </div>
          <Animation.Unroll
            visible={
              incompatibleWithHandicaps === EnumPositionStudyHandicapsIncompatibleWithHandicaps.Yes
            }
          >
            <WithLightTitle
              title="lesquelles ?"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {handicapIncompatibilityDetail?.length ?? 0}/500 caractères
                </span>
              }
              className={isMobile ? styles.mobileFoldableContent : styles.foldableContent}
            >
              <TextArea
                className={styles.textArea}
                maxLength={500}
                control={control}
                name="handicapIncompatibilityDetail"
                onBlurCapture={submit}
              />
            </WithLightTitle>
          </Animation.Unroll>
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Handicaps;
