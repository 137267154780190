import {
  Button,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Wrong } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './EDPNameSelectionModalComponent.module.scss';
import { Props } from './EDPNameSelectionModalComponent.types';

const EDPNameSelectionModalComponent = ({ selectedName, onNameChange }: Props) => (
  <WithLightTitle title="suggestion de nom pour votre étude">
    <TextInput
      onChange={e => onNameChange((e.target as HTMLInputElement).value)}
      value={selectedName}
      rightIcon={
        <Button.Tertiary onClick={() => onNameChange('')} className={styles.crossButton}>
          <Wrong />
        </Button.Tertiary>
      }
    />
  </WithLightTitle>
);

export default EDPNameSelectionModalComponent;
