import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  OSMConsultantModelDtoConsultantAgencyByCompany,
  RepositoriesService,
  SMIC,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchSMICAmount = (
  extraOptions?: UseQueryOptions<
    SMIC,
    unknown,
    SMIC,
    (QueryKeys | OSMConsultantModelDtoConsultantAgencyByCompany | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);

  return useQuery(
    [QueryKeys.fetchSMICAmount, currentBrand],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return RepositoriesService.repositoriesControllerGetSmic();
    },
    {
      staleTime: 86400000,
      enabled: currentBrand !== undefined,
      ...extraOptions,
    }
  );
};
