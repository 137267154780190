import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, ETest } from 'src/Services/API';
import { toSentenceCase } from 'src/Utils/toSentenceCase';

import { QueryKeys } from '../types';

export const useFetchTestList = (
  extraOptions?: UseQueryOptions<ETest[], unknown, ETest[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchTestList],
    async () => {
      const result = await RepositoriesService.repositoriesControllerGetETesting();
      return result.map(test => {
        return { ...test, label: toSentenceCase(test.label) };
      });
    },
    { ...extraOptions }
  );
};
