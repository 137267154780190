import { UseMutationResult } from '@tanstack/react-query';

import {
  DetailedPositionStudy,
  EnumSkillTypeCode,
  Skill,
  UpdatePositionStudyBody,
} from 'src/Services/API';

import { useFetchPositionStudyFromURL } from '../PositionStudies';

export const useQuerySkillsState = (
  skillType: EnumSkillTypeCode,
  mutation: UseMutationResult<
    DetailedPositionStudy,
    unknown,
    { initialPositionStudy: DetailedPositionStudy; modification: Partial<UpdatePositionStudyBody> },
    unknown
  >
) => {
  const positionStudy = useFetchPositionStudyFromURL().data;
  const initialSkills = positionStudy?.skillSection.skills;
  const initialTasks = positionStudy?.skillSection.tasks;
  const selectedSkills = initialSkills?.filter(({ typeCode }) => typeCode === skillType);

  return {
    updateSkills: ({ skills, tasks }: { skills: Skill[]; tasks?: string }) => {
      mutation.mutate({
        // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initialPositionStudy: positionStudy!,
        modification: {
          skillSection: {
            skills: [
              ...(initialSkills?.filter(({ typeCode }) => typeCode !== skillType) ?? []),
              ...skills,
            ],
            tasks: tasks ?? '',
          },
        },
      });
    },
    initialSkills: selectedSkills,
    initialTasks,
  };
};
