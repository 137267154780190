import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompanyDetails, CompaniesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyDetails = (
  companyId: string,
  extraOptions?: UseQueryOptions<CompanyDetails, unknown, CompanyDetails, string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyDetails, companyId],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return CompaniesService.companiesControllerGetCompanyDetail({
        id: companyId,
        brandCode: currentBrand.brandCodeApiHeader,
      });
    },
    {
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};
