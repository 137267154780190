import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { PracticalSchemaType } from './Practical.types';

export const PracticalContext = React.createContext({
  control: {} as Control<PracticalSchemaType>,
  watch: ((_name: string) => null) as UseFormWatch<PracticalSchemaType>,
  setValue: ((_name: string, _value: unknown) => null) as UseFormSetValue<PracticalSchemaType>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submit: (): void => {},
});
