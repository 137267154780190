import * as z from 'zod';

export const workingHoursItem = z
  .object({
    startTime: z.string().optional(),
    endTime: z.string().optional(),
    days: z.array(z.string()).optional(),
  })
  .refine(
    ({ startTime, endTime, days }) => {
      return startTime || (!endTime && (!days || days?.length === 0));
    },
    {
      message: 'Requis',
      path: ['startTime'],
    }
  )
  .refine(
    ({ startTime, endTime, days }) => {
      return endTime || (!startTime && (!days || days?.length === 0));
    },
    {
      message: 'Requis',
      path: ['endTime'],
    }
  )
  .refine(
    ({ startTime, endTime, days }) => {
      return (days?.length ?? 0) > 0 || (!startTime && !endTime);
    },
    {
      message: 'Ajoutez au moins un jour',
      path: ['days'],
    }
  );

export const defaultWorkingHoursItem = { startTime: '', endTime: '', days: [] };
