import {
  Animation,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  useFormWithZodResolver,
  TextArea,
  ErrorMessage,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.component';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader/RequiredFieldHeader.component';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { usePreFetchRisks } from 'src/Hooks/Security/useFetchRisks';
import { getRequiredFields, getSelectedSector } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import {
  EnumPositionStudyInstructionsHasHomeFormalisation,
  EnumPositionStudyRisksHasRisks,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { ACTIVITY_SECTORS } from 'src/Utils/constants';

import styles from './Risks.module.scss';
import { risksSchema } from './Risks.schema';
import RiskSelection from './RiskSelection';

const Risks = () => {
  usePreFetchRisks();
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const selectedSector = useSelector(getSelectedSector);
  const requiredFields = useSelector(getRequiredFields);
  const dispatch = useDispatch();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: risksSchema,
    defaultValues: {
      hasRisks: fetchPositionStudy.data?.risks?.hasRisks,
      risks: fetchPositionStudy.data?.risks?.risks,
      otherRisks: fetchPositionStudy.data?.risks?.otherRisks,
    },
  });

  const hasRisks = watch('hasRisks');
  const otherRisks = watch('otherRisks');

  const { field: risksField } = useController({
    name: 'risks',
    control,
  });

  const isNotCommerceSector = selectedSector.id !== ACTIVITY_SECTORS.TRADE_AND_SERVICES;

  const missingRequiredFields = [
    (hasRisks === EnumPositionStudyRisksHasRisks.Yes &&
      risksField.value.length === 0 &&
      otherRisks === '' &&
      isNotCommerceSector &&
      'needsRisks') ||
      (hasRisks === EnumPositionStudyRisksHasRisks.Unknown && isNotCommerceSector && 'yesOrNo'),
  ].filter(Boolean);

  const risksHandleSubmit = useCallback(
    (triggeredByRisksTripleButton?: boolean) =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            risks: values,
            triggeredByRisksTrippleButton: triggeredByRisksTripleButton,
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  useEffect(() => {
    const hasMissingRisks =
      missingRequiredFields.includes('yesOrNo') || missingRequiredFields.includes('needsRisks');
    const newRequiredFields = {
      ...requiredFields,
      security: {
        ...requiredFields.security,
        risks: { risks: hasMissingRisks },
        instructions: {
          hasHomeFormalisation:
            hasRisks !== EnumPositionStudyRisksHasRisks.No &&
            fetchPositionStudy.data?.instructions.hasHomeFormalisation ===
              EnumPositionStudyInstructionsHasHomeFormalisation.Unknown,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    dispatch(completionStatusActions.setCurrentHasRisks(hasRisks));
    risksHandleSubmit(true)();
    if (isDirty && hasRisks === EnumPositionStudyRisksHasRisks.No) {
      toast.success(
        "dans l'onglet 'consignes et accueil au poste', le champ 'existe-t-il un exemplaire de la formalisation de l'accueil et de la formation' a été fixé à 'non'",
        { autoClose: 5000 }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRisks]);

  const shouldDisplayTripleChoiceWarning =
    isNotCommerceSector && hasRisks === EnumPositionStudyRisksHasRisks.Unknown;

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {missingRequiredFields.length > 0 && isNotCommerceSector && (
            <CompletionStatusMessagesComponent missingRequiredFields={missingRequiredFields} />
          )}
          <h2 className={styles.title}>votre choix</h2>
          <RequiredFieldHeader
            fieldHeader="y a-t-il des risques liés au poste ?"
            isMobile={isMobile}
            expected={isNotCommerceSector}
          />
          <div className={styles.warningContainer}>
            <TripleChoiceButtonForm
              name="hasRisks"
              control={control}
              buttonClassName={classNames({
                [styles.warningRisks]: shouldDisplayTripleChoiceWarning,
              })}
            />
            {shouldDisplayTripleChoiceWarning && (
              <div className={styles.errorMessage}>doit être à oui ou à non</div>
            )}
          </div>

          <Animation.Unroll visible={hasRisks === EnumPositionStudyRisksHasRisks.Yes}>
            <RiskSelection
              title="sélectionner parmi ces risques potentiels"
              submit={risksHandleSubmit()}
              field={risksField}
              otherRisks={otherRisks}
            />

            <WithLightTitle
              title="ou précisez d'autres risques"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {otherRisks?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <div>
                <TextArea
                  name="otherRisks"
                  control={control}
                  className={styles.textArea}
                  maxLength={3999}
                  onBlurCapture={risksHandleSubmit()}
                />
              </div>
            </WithLightTitle>
          </Animation.Unroll>
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Risks;
