import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useMemo, useState } from 'react';

import { useFetchSkills } from 'src/Hooks/Skills';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './SkillsPicker.types';

const SkillsPicker = ({ type, onChange, selectedSkills }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchSkills({ keyword, type });
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  return (
    <AutoSuggest
      id="skills"
      value={selectedSkills}
      searchResults={data ?? []}
      onSearch={keyword => throttledSetKeyword(keyword)}
      keyValueExtractor={skill => ({
        key: skill.id,
        value: skill.label,
      })}
      onChange={onChange}
      minLengthToSearch={3}
      fetchStatus={
        keyword.length > 2
          ? toFetchStatus({ isSuccess, isError, isLoading })
          : FETCH_STATUS.FULFILLED
      }
    />
  );
};

export default SkillsPicker;
