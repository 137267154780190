import {
  Button,
  ComboBox,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTdpUnite } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import {
  getCurrentAgency,
  getCurrentBrand,
  getCurrentRegion,
  getCurrentZone,
} from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { OSMConsultantModelDtoConsultantAgencyByCompany } from 'src/Services/API';

import ErrorFormField from './ErrorFormField';
import styles from './PerimeterSelectionModal.module.scss';

type Schema = {
  brand?: OSMConsultantModelDtoConsultantAgencyByCompany;
  agency?: string;
};

const PerimeterSelectionModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useFetchPerimeter({});
  const isMobile = useSelector(getDevice);

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const currentZone = useSelector(getCurrentZone);
  const currentRegion = useSelector(getCurrentRegion);
  const isClosable = useRef(!!currentBrand && !!currentAgency).current;

  const formMethods = useForm<Schema>({
    defaultValues: { brand: currentBrand, agency: currentAgency },
  });
  const { control, getValues, handleSubmit, reset, setValue, watch } = formMethods;

  const watchBrand = watch('brand');
  useEffect(() => {
    if (watchBrand?.brandCodeApiHeader !== currentBrand?.brandCodeApiHeader) {
      setValue('agency', watchBrand?.agencies?.[0] ?? undefined, {
        shouldValidate: true,
      });
    }
  }, [currentBrand, setValue, watchBrand]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        open
        title={`choisissez vos unités`}
        hideCloseAction={!isClosable}
        closeOnDocumentClick={isClosable}
        onClose={() => {
          history.goBack();
        }}
        icon={<IllusTdpUnite />}
        footerActionsLeft={[
          <Button.Tertiary
            key="perimeter-reset-button"
            onClick={() => {
              reset();
            }}
          >
            {isMobile ? 'réinitialiser' : 'tout réinitialiser'}
          </Button.Tertiary>,
        ]}
        footerActionsRight={[
          isClosable && (
            <Button.Secondary
              onClick={() => {
                history.goBack();
              }}
            >
              annuler
            </Button.Secondary>
          ),
          <Button
            key="perimeter-go-back-button"
            onClick={handleSubmit(({ brand, agency }) => {
              dispatch(
                perimeterActions.setCurrentPerimeter({
                  currentBrand: brand,
                  currentAgency: agency,
                  zoneId: currentZone,
                  regionId: currentRegion,
                })
              );
              history.goBack();
            })}
          >
            valider
          </Button>,
        ]}
      >
        <div className={styles.title}>
          Sélectionnez parmi votre périmètre, la marque et l'unité voulue
        </div>
        <WithLightTitle title="périmètre">
          <div className={styles.perimeterSelectionRow}>
            <Controller
              control={control}
              name="brand"
              rules={{ required: 'Sélectionnez une marque' }}
              render={({ field: { value, name, onChange } }) => {
                return (
                  <div className={styles.comboBoxFormField}>
                    <ComboBox
                      id={name}
                      value={value}
                      searchResults={data?.companies ?? []}
                      useNativeOptionFiltering
                      onChange={onChange}
                      keyValueExtractor={({ brand, brandCodeApiHeader }) => ({
                        key: `${brand?.code}${brandCodeApiHeader}`,
                        value: brand?.name ?? 'Marque inconnue',
                      })}
                    />
                    <ErrorFormField<Schema> name={name} />
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="agency"
              rules={{ required: 'Sélectionnez une agence' }}
              render={({ field: { value, name, onChange } }) => {
                return (
                  <div className={styles.comboBoxFormField}>
                    <ComboBox
                      id={name}
                      value={value}
                      searchResults={getValues('brand')?.agencies ?? []}
                      useNativeOptionFiltering
                      onChange={onChange}
                      keyValueExtractor={agency => ({ key: agency, value: agency })}
                    />
                    <ErrorFormField<Schema> name={name} />
                  </div>
                );
              }}
            />
          </div>
        </WithLightTitle>
      </Modal>
    </FormProvider>
  );
};

export default PerimeterSelectionModal;
