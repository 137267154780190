import { ComboBox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useMemo } from 'react';

import { useFetchQualifications } from 'src/Hooks/Qualifications';
import { Qualification } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './SelectQualifications.types';

const SelectQualifications = ({
  setQualification,
  selectedQualification,
  blackList,
  title,
}: Props) => {
  const mutation = useFetchQualifications();
  const throttledMethod = useMemo(() => throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <WithLightTitle title={title ?? ''}>
      <ComboBox
        id="qualifications"
        value={
          blackList?.includes(selectedQualification?.id ?? '') ? undefined : selectedQualification
        }
        minLengthToSearch={1}
        keyValueExtractor={(searchResult: Qualification) => ({
          key: searchResult.id ?? '',
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          if (word !== '') {
            throttledMethod(word);
          }
        }}
        onChange={qualification => {
          setQualification(qualification ?? undefined);
        }}
        searchResults={
          mutation.data?.filter(qualification => !blackList?.includes(qualification.id)) ?? []
        }
        placeholder="1 caractère minimum"
        fetchStatus={toFetchStatus(mutation)}
      />
    </WithLightTitle>
  );
};

export default SelectQualifications;
