import * as z from 'zod';

import { EnumPositionStudyLicensesHasLicense } from 'src/Services/API';

export const drivingLicensesSchema = z
  .object({
    hasLicense: z.nativeEnum(EnumPositionStudyLicensesHasLicense),
    drivingLicenses: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
    habilitations: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
  })
  .refine(
    ({ hasLicense, drivingLicenses }) => {
      return !(
        hasLicense === EnumPositionStudyLicensesHasLicense.Yes && drivingLicenses?.length === 0
      );
    },
    {
      message: 'Vous devez renseigner au moins un permis si vous avez selectionné oui',
      path: ['hasLicense'],
    }
  );

export type DrivingLicensesSchemaType = z.infer<typeof drivingLicensesSchema>;
