import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

import { useFetchEmployeeStatuses } from 'src/Hooks/Qualifications';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import { ProfileContext } from '../../ProfileContext';

import styles from './StatusSelection.module.scss';

const StatusSelection = () => {
  const isMobile = useSelector(getDevice);
  const profileContext = useContext(ProfileContext);
  const { data, isError, isLoading } = useFetchEmployeeStatuses();
  const positionStatus = profileContext.watch('positionStatus');

  if (isLoading)
    return (
      <ContentLoader height="3.5rem" width="50%" uniqueKey="StatusSelection">
        <rect x="0" rx="4" ry="4" width="140" height="3.5rem" />
        <rect x="150" rx="4" ry="4" width="140" height="3.5rem" />
        <rect x="300" rx="4" ry="4" width="140" height="3.5rem" />
      </ContentLoader>
    );

  if (isError) return <p>Erreur lors de la récupération du référentiel des status</p>;

  if (!data || data?.length === 0) return <p>Le référentiel des status est vide</p>;

  const employeStatusesList = data.map(({ id, label }) => {
    return { key: id, label: label };
  });

  return (
    <ExclusiveSelectionGroup
      verticalDisplay={isMobile}
      values={employeStatusesList}
      name="positionStatus"
      control={profileContext.control}
      getKey={value => value.key}
      getStringValue={value => value.label}
      getSelectedValue={value => {
        const selectedValues = employeStatusesList.filter(({ key }) => key === value);
        return selectedValues.length > 0 ? selectedValues[0] : undefined;
      }}
      getSelectedFormValue={value => {
        return value?.key;
      }}
      buttonClassName={classnames({
        [styles.warningStatus]: !positionStatus,
      })}
    />
  );
};

export default StatusSelection;
