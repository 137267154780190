import { Tooltip } from '@mui/material';
import {
  DragDropList,
  WithLightTitle,
  TextInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  TextArea,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { EnumSkillTypeCode, Priority } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import styles from './Learning.module.scss';
import { learningSchema } from './Learning.schema';
import PositionCriticalitySelection from './PositionCriticalitySelection';
import SkillCard from './SkillCard/SkillCard.component';
import TimeSelection from './TimeSelection';

export const priorityMapping = {
  [Priority.MANDATORY]: { label: 'obligatoire', className: styles.mandatory },
  [Priority.OPTIONAL]: { label: 'optionnel', className: {} },
  [Priority.WISHED]: { label: 'souhaité', className: styles.wished },
};

const Learning = () => {
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: learningSchema,
    defaultValues: {
      skillsWithPriority:
        fetchPositionStudy.data?.learning.skillsWithPriority &&
        fetchPositionStudy.data?.learning.skillsWithPriority.length > 0
          ? fetchPositionStudy.data?.learning.skillsWithPriority
          : fetchPositionStudy.data?.skillSection.skills
              .filter(
                skill => skill.typeCode === EnumSkillTypeCode.COMPETENCES_ET_TACHES_A_EFFECTUER
              )
              .map((skill, idx) => {
                return { ...skill, priority: Priority.OPTIONAL, rank: idx };
              }),
      cadenceDescription: fetchPositionStudy.data?.learning?.cadenceDescription ?? undefined,
      attendantProductivity: fetchPositionStudy.data?.learning?.attendantProductivity ?? undefined,
      workerProductivity: fetchPositionStudy.data?.learning?.workerProductivity ?? undefined,
      explanationTime: fetchPositionStudy.data?.learning?.explanationTime ?? undefined,
      learningTime: fetchPositionStudy.data?.learning?.learningTime ?? undefined,
      positionCriticality: fetchPositionStudy.data?.learning?.positionCriticality ?? undefined,
    },
  });

  const cadenceDescription = watch('cadenceDescription');
  const attendantProductivity = watch('attendantProductivity');
  const workerProductivity = watch('workerProductivity');

  const skillsWithPriorityController = useController({ name: 'skillsWithPriority', control });

  const positionCriticalityController = useController({
    name: 'positionCriticality',
    control: control,
  });

  const explanationTimeController = useController({
    name: 'explanationTime',
    control: control,
  });

  const learningTimeController = useController({
    name: 'learningTime',
    control: control,
  });

  const learningHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            learning: {
              skillsWithPriority: values.skillsWithPriority ?? [],
              cadenceDescription: values.cadenceDescription ?? '',
              attendantProductivity: values.attendantProductivity ?? '',
              workerProductivity: values.workerProductivity ?? '',
              explanationTime: values.explanationTime ?? {
                duration: '',
                unit: undefined,
              },
              learningTime: values.learningTime ?? {
                duration: '',
                unit: undefined,
              },
              positionCriticality: values.positionCriticality,
            },
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = learningHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsWithPriorityController.field.value]);

  function onDragEnd(result: DropResult) {
    const newItems = [...skillsWithPriorityController.field.value];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination?.index ?? 0, 0, removed);
    skillsWithPriorityController.field.onChange(
      newItems.map((item, idx) => {
        return { ...item, rank: idx };
      })
    );
  }

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2>rappel des compétences et tâches à effectuer</h2>
          {skillsWithPriorityController.field.value.length > 0 ? (
            <WithLightTitle title="liste" className={styles.listContainer}>
              <DragDropList
                droppableId="skillsSection"
                onDragEnd={onDragEnd}
                items={skillsWithPriorityController.field.value.map((skill, idx) => {
                  return {
                    id: skill.id,
                    item: (
                      <SkillCard
                        field={skillsWithPriorityController.field}
                        idx={idx}
                        skill={skill}
                      />
                    ),
                  };
                })}
              />
            </WithLightTitle>
          ) : (
            <p className={styles.noSkills}>
              aucune compétence renseignée pour cette EDP, veuillez en renseigner dans l'onglet
              Métier / Compétences et tâches à effectuer
            </p>
          )}

          <div className={styles.separator} />
          <h2 className={styles.cadenceTitle}>cadence</h2>
          <div className={styles.cadenceDescriptionContainer}>
            <WithLightTitle
              title="description de la cadence"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {cadenceDescription?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <TextArea
                name="cadenceDescription"
                maxLength={3999}
                control={control}
                className={styles.textArea}
                placeholder={
                  '1. Ex : Nombre de pièces/produits... par/ heure/minute/jour...\n2. La cadence a-t-elle une influence sur les autres postes ? Lesquels ?'
                }
                onBlurCapture={submit}
              />
            </WithLightTitle>
          </div>
          <div
            className={classnames(styles.explanationTimeAndUnitContainer, {
              [styles.mobileExplanationContainer]: isMobile,
            })}
          >
            <WithLightTitle
              rightTitleComponent={
                !isMobile ? (
                  <Tooltip title="Temps nécessaire aux explications et pendant lequel ni la personne qui fournit les explications, ni l’intérimaire ne contribuent à la production">
                    <div className={styles.informationIcon}>ⓘ</div>
                  </Tooltip>
                ) : undefined
              }
              title="temps d'explication"
              className={classnames(styles.learningTimeContainer, {
                [styles.mobileLearningTimeContainer]: isMobile,
              })}
            >
              <TextInput
                type="number"
                step="0.01"
                key="explanationTime"
                min={0}
                defaultValue={fetchPositionStudy.data?.learning.explanationTime?.duration}
                onChange={({ target }) => {
                  explanationTimeController.field.onChange({
                    duration: (target as HTMLInputElement).value,
                    unit: explanationTimeController.field.value?.unit,
                  });
                  submit();
                }}
              />
              <ErrorMessage error={explanationTimeController.fieldState.error} />
            </WithLightTitle>
            <WithLightTitle
              title="unité"
              className={classnames(styles.timeUnitContainer, {
                [styles.mobiletimeUnitContainer]: isMobile,
              })}
            >
              <TimeSelection
                selected={explanationTimeController.field.value?.unit}
                setSelected={explanationTimeUnit => {
                  explanationTimeController.field.onChange({
                    duration: explanationTimeController.field.value?.duration,
                    unit: explanationTimeUnit,
                  });
                  submit();
                }}
              />
            </WithLightTitle>
          </div>
          <div
            className={classnames(styles.explanationTimeAndUnitContainer, {
              [styles.mobileExplanationContainer]: isMobile,
            })}
          >
            <WithLightTitle
              rightTitleComponent={
                !isMobile ? (
                  <Tooltip title="Temps pendant lequel l’intérimaire réalise le travail (sous la surveillance ou la collaboration de la personne chargée de son apprentissage) mais n'est pas productif à 100%">
                    <div className={styles.informationIcon}>ⓘ</div>
                  </Tooltip>
                ) : undefined
              }
              title="temps d'apprentissage"
              className={classnames(styles.learningTimeContainer, {
                [styles.mobileLearningTimeContainer]: isMobile,
              })}
            >
              <TextInput
                type="number"
                step="0.01"
                key="learningTime"
                defaultValue={fetchPositionStudy.data?.learning.learningTime?.duration}
                min={0}
                onChange={({ target }) => {
                  learningTimeController.field.onChange({
                    duration: (target as HTMLInputElement).value,
                    unit: learningTimeController.field.value?.unit,
                  });
                  submit();
                }}
              />
              <ErrorMessage error={learningTimeController.fieldState.error} />
            </WithLightTitle>
            <WithLightTitle
              title="unité"
              className={classnames(styles.timeUnitContainer, {
                [styles.mobiletimeUnitContainer]: isMobile,
              })}
            >
              <TimeSelection
                selected={learningTimeController.field.value?.unit}
                setSelected={learningTimeUnit => {
                  learningTimeController.field.onChange({
                    duration: learningTimeController.field.value?.duration,
                    unit: learningTimeUnit,
                  });
                  submit();
                }}
              />
            </WithLightTitle>
          </div>
          <div className={styles.attendantProductivityContainer}>
            <WithLightTitle
              title="productivité de l'accompagnant"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {attendantProductivity?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <TextArea
                name="attendantProductivity"
                maxLength={3999}
                control={control}
                className={styles.textArea}
                placeholder={
                  "Quelle est la productivité de la personne chargée de son apprentissage pendant ce temps d'apprentissage (ex : 20% le premier jour/sem, 50% le deuxième jour/sem...)"
                }
                onBlurCapture={submit}
              />
            </WithLightTitle>
          </div>

          <div className={styles.workerProductivityContainer}>
            <WithLightTitle
              title="productivité de l'intérimaire"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {workerProductivity?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <TextArea
                name="workerProductivity"
                maxLength={3999}
                control={control}
                className={styles.textArea}
                placeholder={
                  'Quelle est la productivité de l’intérimaire pendant ce temps d’apprentissage  (ex : 50% le premier jour/sem, 70% le deuxième jour/sem…)'
                }
                onBlurCapture={submit}
              />
            </WithLightTitle>
          </div>
          <PositionCriticalitySelection
            selected={positionCriticalityController.field.value}
            setSelected={positionCriticality => {
              positionCriticalityController.field.onChange(positionCriticality);
              submit();
            }}
          />
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Learning;
