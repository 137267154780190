import { Animation, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  TextArea,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.component';
import TripleChoiceButtonForm from 'src/Components/TripleChoiceButtonForm';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { usePreFetchProtectionEquipments } from 'src/Hooks/Security/useFetchProtectionEquipments';
import { getRequiredFields, getSelectedSector } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import {
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
  ProtectionEquipment,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { ACTIVITY_SECTORS } from 'src/Utils/constants';

import styles from './ProtectionEquipments.module.scss';
import { protectionEquipmentSchema } from './ProtectionEquipments.schema';
import ProtectionEquipmentsSelection from './ProtectionEquipmentsSelection';
import { ProtectionEquipmentsTypes } from './ProtectionEquipmentsSelection/ProtectionEquipmentsSelection.types';

const ProtectionEquipments = () => {
  usePreFetchProtectionEquipments();
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const initialValues = fetchPositionStudy.data?.protectionEquipments;
  const isMobile = useSelector(getDevice);
  const requiredFields = useSelector(getRequiredFields);
  const selectedSector = useSelector(getSelectedSector);
  const dispatch = useDispatch();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: protectionEquipmentSchema,
    defaultValues: {
      hasEpis: initialValues?.hasEpis,
      clientEpis: initialValues?.clientEpis,
      agencyEpis: initialValues?.agencyEpis,
      hasEpcs: initialValues?.hasEpcs,
      mainEpcs: initialValues?.mainEpcs,
      otherEpcs: initialValues?.otherEpcs,
    },
  });

  const hasEpis = watch('hasEpis');
  const hasEpcs = watch('hasEpcs');
  const otherEPCS = watch('otherEpcs');
  const agencyEPIs = watch('agencyEpis');
  const clientEPIs = watch('clientEpis');
  const mainEPCs = watch('mainEpcs');

  const agencyEPIsController = useController({
    name: 'agencyEpis',
    control,
  });

  const clientEPIsController = useController({
    name: 'clientEpis',
    control,
  });

  const mainEPCsController = useController({
    name: 'mainEpcs',
    control,
  });

  const isNotCommerceSector = selectedSector.id !== ACTIVITY_SECTORS.TRADE_AND_SERVICES;

  const shouldDisplayTripleChoiceEpisWarning =
    hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Unknown && isNotCommerceSector;

  const shouldDisplayTripleChoiceEpcsWarning =
    hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Unknown && isNotCommerceSector;

  const EPIHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            protectionEquipments: {
              hasEpis: values.hasEpis,
              hasEpcs: values.hasEpcs,
              clientEpis:
                values.hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Yes
                  ? values.clientEpis
                  : [],
              agencyEpis:
                values.hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Yes
                  ? values.agencyEpis
                  : [],
              mainEpcs:
                values.hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Yes
                  ? values.mainEpcs
                  : [],
              otherEpcs:
                values.hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Yes
                  ? values.otherEpcs
                  : '',
            },
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = EPIHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEpis, agencyEPIs, clientEPIs, hasEpcs, mainEPCs]);

  const missingRequiredFields = [
    isNotCommerceSector &&
      hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Unknown &&
      'yesOrNoEpis',
    isNotCommerceSector &&
      hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Yes &&
      agencyEPIsController.field.value.length + clientEPIsController.field.value.length === 0 &&
      'needsEpis',
    isNotCommerceSector &&
      hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Unknown &&
      'yesOrNoEpcs',
    isNotCommerceSector &&
      hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Yes &&
      agencyEPIsController.field.value.length + mainEPCsController.field.value.length === 0 &&
      'needsEpcs',
  ].filter(Boolean);

  useEffect(() => {
    const hasMissingEpis =
      missingRequiredFields.includes('yesOrNoEpis') || missingRequiredFields.includes('needsEpis');
    const hasMissingEpcs =
      missingRequiredFields.includes('yesOrNoEpcs') || missingRequiredFields.includes('needsEpcs');

    const newRequiredFields = {
      ...requiredFields,
      security: {
        ...requiredFields.security,
        protectionEquipment: {
          hasEpis: hasMissingEpis,
          hasEpcs: hasMissingEpcs,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    dispatch(completionStatusActions.setCurrentHasEpis(hasEpis));
    dispatch(completionStatusActions.setCurrentHasEpcs(hasEpcs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasEpis,
    hasEpcs,
    agencyEPIsController.field.value,
    clientEPIsController.field.value,
    mainEPCsController.field.value,
    otherEPCS,
  ]);

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {missingRequiredFields.length > 0 && isNotCommerceSector && (
            <CompletionStatusMessagesComponent missingRequiredFields={missingRequiredFields} />
          )}
          <h2 className={styles.title}>votre choix</h2>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>
              y a-t-il des EPI pour ce poste ?
              {isNotCommerceSector && <span> (*info attendue)</span>}
            </p>
            <div
              className={classNames(
                { [styles.mobileTripleChoice]: isMobile },
                styles.errorContainer
              )}
            >
              <TripleChoiceButtonForm
                name="hasEpis"
                control={control}
                className={styles.tripleChoice}
                buttonClassName={classNames({
                  [styles.warningEps]: shouldDisplayTripleChoiceEpisWarning,
                })}
              />
              {shouldDisplayTripleChoiceEpisWarning && (
                <div className={styles.errorMessage}>doit être à oui ou à non</div>
              )}
            </div>
          </div>

          <Animation.Unroll visible={hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Yes}>
            <ProtectionEquipmentsSelection
              title="EPI fournis par l'agence"
              selectedProtectionEquipments={agencyEPIsController.field.value}
              onProtectionEquipmentClick={(epi: ProtectionEquipment) => {
                agencyEPIsController.field.onChange(
                  addOrRemove(agencyEPIsController.field.value, epi)
                );
                if (clientEPIsController.field.value.map(epiItem => epiItem.id).includes(epi.id)) {
                  clientEPIsController.field.onChange(
                    addOrRemove(clientEPIsController.field.value, epi)
                  );
                }
              }}
              type={ProtectionEquipmentsTypes.agency}
            />

            <ProtectionEquipmentsSelection
              title="EPI fournis par le client"
              selectedProtectionEquipments={clientEPIsController.field.value}
              onProtectionEquipmentClick={(epi: ProtectionEquipment) => {
                clientEPIsController.field.onChange(
                  addOrRemove(clientEPIsController.field.value, epi)
                );
                if (agencyEPIsController.field.value.map(epiItem => epiItem.id).includes(epi.id)) {
                  agencyEPIsController.field.onChange(
                    addOrRemove(agencyEPIsController.field.value, epi)
                  );
                }
              }}
              type={ProtectionEquipmentsTypes.client}
            />
          </Animation.Unroll>

          <div className={isMobile ? styles.mobileSubsection : styles.subsection}>
            <p className={styles.label}>
              y a-t-il des EPC pour ce poste ?
              {isNotCommerceSector && <span> (*info attendue)</span>}
            </p>
            <div
              className={classNames(
                { [styles.mobileTripleChoice]: isMobile },
                styles.errorContainer
              )}
            >
              <TripleChoiceButtonForm
                name="hasEpcs"
                control={control}
                className={styles.tripleChoice}
                buttonClassName={classNames({
                  [styles.warningEps]: shouldDisplayTripleChoiceEpcsWarning,
                })}
              />
              {shouldDisplayTripleChoiceEpcsWarning && (
                <div className={styles.errorMessage}>doit être à oui ou à non</div>
              )}
            </div>
          </div>

          <Animation.Unroll visible={hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Yes}>
            <ProtectionEquipmentsSelection
              title="EPC présents"
              selectedProtectionEquipments={mainEPCsController.field.value}
              onProtectionEquipmentClick={(epc: ProtectionEquipment) =>
                mainEPCsController.field.onChange(addOrRemove(mainEPCsController.field.value, epc))
              }
              type={ProtectionEquipmentsTypes.collective}
            />

            <WithLightTitle
              title="autres EPC"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {otherEPCS?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <div className={styles.otherRisksContainer}>
                <TextArea
                  name="otherEpcs"
                  control={control}
                  maxLength={3999}
                  placeholder="veuillez lister les autres EPC nécessaires"
                  onBlurCapture={submit}
                />
              </div>
            </WithLightTitle>
          </Animation.Unroll>
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default ProtectionEquipments;
