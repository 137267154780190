import * as z from 'zod';

export const workLocationSchema = z
  .object({
    isMobileWorkLocation: z.boolean(),
    mobileWorkLocationDepartment: z.string().optional(),
    workingLocation: z
      .object({
        address: z.string().optional(),
        address1: z.string().optional(),
        address2: z.string().optional(),
        address3: z.string().optional(),
        cityName: z.string().optional(),
        zipCode: z.string().optional(),
        inseeCode: z.string().optional(),
      })
      .optional(),
    customWorkingLocation: z
      .object({
        address: z.string().optional(),
        cityName: z.string().optional(),
        zipCode: z.string().optional(),
        inseeCode: z.string().optional(),
      })
      .optional(),
  })
  .refine(({ isMobileWorkLocation, mobileWorkLocationDepartment }) => {
    if (isMobileWorkLocation) {
      return mobileWorkLocationDepartment !== undefined;
    }
    return true;
  }, 'département manquant')
  .refine(({ customWorkingLocation }) => {
    return (
      (customWorkingLocation?.address !== undefined &&
        customWorkingLocation.address.length > 0 &&
        customWorkingLocation?.zipCode !== undefined) ||
      ((customWorkingLocation?.address === undefined ||
        customWorkingLocation?.address.length === 0) &&
        customWorkingLocation?.zipCode === undefined)
    );
  }, 'Le numéro de voie et la ville doivent être soit tous les deux vides, soit tous les deux remplis');
