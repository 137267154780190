import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { PositionstudiesService, PositionStudyFile } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionStudyFiles = (
  positionStudyId: string,
  extraOptions?: UseQueryOptions<PositionStudyFile[], unknown, PositionStudyFile[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchPositionStudyFiles, positionStudyId],
    async () => {
      const positionStudy =
        await PositionstudiesService.positionStudiesControllerGetPositionStudyFiles({
          id: positionStudyId,
        });
      return positionStudy;
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
