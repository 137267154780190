import React from 'react';

import {
  TechnicsAndProcesses as TechnicsAndProcessesIcon,
  SuggestedTechnicsAndProcesses,
} from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const TechnicsAndProcesses = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.TECHNIQUES_ET_PROCEDES}
      skillIcon={<TechnicsAndProcessesIcon />}
      suggestedSkillIcon={<SuggestedTechnicsAndProcesses />}
    />
  );
};

export default TechnicsAndProcesses;
