import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import { DetailedPositionStudy, UpdatePositionStudyBody } from 'src/Services/API';

export const PositionStudyModificationContext = React.createContext({
  fetchPositionStudy: {} as UseQueryResult<DetailedPositionStudy>,
  updateMutation: {} as UseMutationResult<
    DetailedPositionStudy,
    unknown,
    { initialPositionStudy: DetailedPositionStudy; modification: Partial<UpdatePositionStudyBody> },
    unknown
  >,
});
