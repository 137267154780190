import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { useController } from 'react-hook-form';

import { useFetchCompanyContacts } from 'src/Hooks/Companies/index';
import { toFetchStatus } from 'src/Services/Async';
import nullify from 'src/Utils/nullify';

import { Props } from './ContactPicker.types';

const ContactPicker = ({
  onChange,
  name,
  companyId,
  customId,
  control,
  shouldNotBeEmpty,
}: Props) => {
  const { data, isSuccess, isError, isLoading } = useFetchCompanyContacts(companyId ?? '');
  const contactResolver = useController({
    name: name,
    control: control,
  });

  return (
    <ComboBox
      id={customId ?? 'contact'}
      searchResults={data?.map(item => item.contactId) ?? []}
      useNativeOptionFiltering
      keyValueExtractor={contactId => ({
        key: data?.find(contact => contact.contactId === contactId)?.contactId ?? '',
        value: [
          data?.find(contact => contact.contactId === contactId)?.name,
          data?.find(contact => contact.contactId === contactId)?.firstName,
        ]
          .filter(item => item !== undefined)
          .join(' '),
      })}
      onChange={
        onChange !== undefined
          ? nullify(onChange)
          : contact => {
              contactResolver?.field.onChange(contact);
            }
      }
      fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
      value={contactResolver.field.value}
      isError={shouldNotBeEmpty}
    />
  );
};

export default ContactPicker;
