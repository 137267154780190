import {
  Button,
  HorizontalTextIconCard,
  Modal,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEDPBonus, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { useFetchPresetBonuses } from 'src/Hooks/Bonus/useFetchPresetBonuses';

import FrequencyAmountSelection from '../FrequencyAmountSelection';

import styles from './BonusRatesModification.module.scss';
import { bonusModificationSchema, Props } from './BonusRatesModification.types';

const BonusRatesModification = ({
  label,
  subLabel,
  onRightIconClick,
  leftIcon,
  initialAmount,
  initialFrequency,
  id,
  onValidate,
}: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  const { data } = useFetchPresetBonuses();
  const presetBonus = data?.find(preset => preset.title === label);

  const bonusModificationZodResolver = useFormWithZodResolver({
    schema: bonusModificationSchema(presetBonus?.maxAmount),
    defaultValues: {
      bonusAmount: initialAmount.toString(),
      bonusFrequency: initialFrequency,
      bonusLabel: label,
    },
  });
  return (
    <Modal
      ref={innerRef}
      icon={<IllusEDPBonus />}
      title={`modification de la ${label}`}
      onOpen={() => {
        bonusModificationZodResolver.setValue('bonusAmount', initialAmount.toString());
        bonusModificationZodResolver.setValue('bonusFrequency', initialFrequency);
        bonusModificationZodResolver.setValue('bonusLabel', label);
      }}
      onClose={() => {
        bonusModificationZodResolver.reset();
      }}
      trigger={
        <button className={styles.horizontalCardContainer}>
          <HorizontalTextIconCard
            rightIcon={<Trashcan />}
            leftIcon={leftIcon}
            onRightIconClick={onRightIconClick}
            label={label}
            subLabel={subLabel}
            containerClassName={styles.bonusCardContainer}
          />
        </button>
      }
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            innerRef.current?.close();
          }}
        >
          annuler
        </Button.Secondary>,
        <Button.Primary
          onClick={bonusModificationZodResolver.handleSubmit(
            ({ bonusAmount, bonusFrequency, bonusLabel }) => {
              onValidate({
                id: id,
                amount: parseFloat(bonusAmount.replace(',', '.')),
                cadency: bonusFrequency,
                label: bonusLabel,
              });
              innerRef.current?.close();
            }
          )}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <FrequencyAmountSelection
        presetBonus={presetBonus}
        control={bonusModificationZodResolver.control}
      />
    </Modal>
  );
};
export default BonusRatesModification;
