import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { ProtectionEquipment, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

const fetchProtectionEquipments = async (type: string) =>
  RepositoriesService.repositoriesControllerGetProtectionEquipments({ type });

export const useFetchProtectionEquipments = (
  type: string,
  extraOptions?: UseQueryOptions<ProtectionEquipment[], unknown, ProtectionEquipment[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchProtectionEquipments, type],
    async () => {
      return fetchProtectionEquipments(type);
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};

export const usePreFetchProtectionEquipments = () => {
  const client = useQueryClient();

  useEffect(() => {
    client.prefetchQuery(
      [QueryKeys.fetchProtectionEquipments, 'client'],
      async () => fetchProtectionEquipments('client'),
      {
        staleTime: 86400000,
      }
    );
    client.prefetchQuery(
      [QueryKeys.fetchProtectionEquipments, 'agency'],
      async () => fetchProtectionEquipments('agency'),
      {
        staleTime: 86400000,
      }
    );
    client.prefetchQuery(
      [QueryKeys.fetchProtectionEquipments, 'collective'],
      async () => fetchProtectionEquipments('collective'),
      {
        staleTime: 86400000,
      }
    );
  });
};
