import React from 'react';

import { Environments as EnvironmentsIcon, SuggestedEnvironments } from 'src/Assets';
import { EnumSkillTypeCode } from 'src/Services/API';

import SkillsSubSection from './SkillsSubSection';

const Environments = () => {
  return (
    <SkillsSubSection
      type={EnumSkillTypeCode.ENVIRONNEMENT_ET_TYPOLOGIES}
      skillIcon={<EnvironmentsIcon />}
      suggestedSkillIcon={<SuggestedEnvironments />}
    />
  );
};

export default Environments;
