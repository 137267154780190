import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { DefaultIcon } from 'src/Assets';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { languageIconMapping } from '../LanguageEdition.types';

import styles from './SelectedLanguageList.module.scss';
import { Props } from './SelectedLanguageList.types';

const SelectedLanguageList = ({ field: { value: selectedLanguages, onChange } }: Props) => {
  return (
    <WithLightTitle title={pluralFormat(selectedLanguages.length, 'langue sélectionnée')}>
      {selectedLanguages.map(selectedLanguage => {
        return (
          <HorizontalTextIconCard
            key={selectedLanguage.id}
            containerClassName={styles.selectedLanguageCard}
            leftIcon={languageIconMapping[selectedLanguage.id] ?? <DefaultIcon />}
            label={selectedLanguage.label}
            rightIcon={<Trashcan />}
            onRightIconClick={() => {
              onChange(addOrRemove(selectedLanguages, selectedLanguage));
            }}
          />
        );
      })}
    </WithLightTitle>
  );
};

export default SelectedLanguageList;
