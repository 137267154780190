import { Button, Modal, PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import {
  FlecheRight,
  IllusEDPBonus,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CacesGrue, CacesNacelle, DefaultIcon, Habilitation } from 'src/Assets';
import HorizontalCardWithIcon from 'src/Components/HorizontalCardWithIcon';
import { useFetchDrivingLicensesHabilitations } from 'src/Hooks/Training/useFetchDrivingLicensesHabilitations';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { getDevice } from '../../../../Redux/UserPreferences/Selectors';

import styles from './DrivingLicenseHabilitationModal.module.scss';
import { drivingLicensesHabilitationsSchema, Props } from './DrivingLicenseHabilitationModal.types';

const iconMapping = {
  WlaJcz3jcF48HhC_pfYFRMt3mEuZNFxhldjZZ0Y1qMA1: <Habilitation />,
  '7nN5vwSXgXuS4Jpgv0drr8t3mEuZNFxhldjZZ0Y1qMA1': <CacesNacelle />,
  'vrLRUYTLeNy-BtpAfcC8Act3mEuZNFxhldjZZ0Y1qMA1': <Habilitation />,
  mL3Ljp0EYZvIIZi6_k0Q3st3mEuZNFxhldjZZ0Y1qMA1: <Habilitation />,
  'rayx3JPju-9wkYS371feA8t3mEuZNFxhldjZZ0Y1qMA1': <Habilitation />,
  'ztYxDvcp_7rAz8JDk3o-V8t3mEuZNFxhldjZZ0Y1qMA1': <Habilitation />,
  'qbqny74-CeKsPGlvR9gu2Mt3mEuZNFxhldjZZ0Y1qMA1': <Habilitation />,
  y9zaLZ2yQoqZkD8dmUNh0Mt3mEuZNFxhldjZZ0Y1qMA1: <Habilitation />,
  mDu0pLpjO6j8dKIVrfRAd8t3mEuZNFxhldjZZ0Y1qMA1: <CacesGrue />,
  sQeRIYbFLpgQjjpdT6GIBst3mEuZNFxhldjZZ0Y1qMA1: <Habilitation />,
};

const DrivingLicenseHabilitationModal = ({ currentHabilitations, onValidate }: Props) => {
  const isMobile = useSelector(getDevice);
  const innerRef = React.useRef<PopupActions>(null);
  const drivingLicenseHabilitationsQuery = useFetchDrivingLicensesHabilitations();
  const licenseHabilitationsResolver = useFormWithZodResolver({
    schema: drivingLicensesHabilitationsSchema,
    defaultValues: {
      habilitations: currentHabilitations,
    },
  });

  const licenseHabilitationsController = useController({
    name: 'habilitations',
    control: licenseHabilitationsResolver.control,
  });

  const habilitationsWithIcon = drivingLicenseHabilitationsQuery.data?.map(habilitation => {
    return {
      icon: iconMapping[habilitation.id as keyof typeof iconMapping] ?? <DefaultIcon />,
      id: habilitation.id,
      label: habilitation.label,
    };
  });
  const drivingHabilitations = currentHabilitations.filter(habilitation =>
    drivingLicenseHabilitationsQuery.data?.map(item => item.id).includes(habilitation.id)
  );
  return (
    <Modal
      title="habilitations pour ce permis"
      icon={<IllusEDPBonus />}
      ref={innerRef}
      // an selected habilitation for a license has to be selected for all licenses, the following onOpen does that by updating the zod state of a modal when it is open
      onOpen={() => licenseHabilitationsController.field.onChange(currentHabilitations)}
      footerActionsRight={[
        <Button.Secondary onClick={innerRef.current?.close}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onValidate(licenseHabilitationsController.field.value ?? []);
            innerRef.current?.close();
          }}
        >
          valider
        </Button.Primary>,
      ]}
      trigger={
        <div className={styles.cardContainer}>
          <div className={isMobile ? styles.mobileTriggerLabel : styles.triggerLabel}>
            {drivingHabilitations.length > 0
              ? 'gestion des habilitations'
              : 'ajouter une habilitation'}
          </div>
          <div className={isMobile ? styles.mobileSelectedNumber : styles.rightCardComponent}>
            {drivingHabilitations.length > 0 && (
              <div className={styles.textRight}>
                {pluralFormat(drivingHabilitations.length, 'sélectionnée')}
              </div>
            )}
            {!isMobile && <FlecheRight className={styles.arrowRight} />}
          </div>
        </div>
      }
    >
      {habilitationsWithIcon?.map(habilitation => {
        const isSelected = licenseHabilitationsController.field.value
          ?.map(item => item.id)
          ?.includes(habilitation.id);
        return (
          <HorizontalCardWithIcon
            key={habilitation.id}
            icon={habilitation.icon}
            label={habilitation.label}
            className={isMobile ? styles.mobileCardLabel : styles.cardLabel}
            selected={isSelected}
            onClick={() => {
              licenseHabilitationsController.field.onChange(
                addOrRemove(licenseHabilitationsController.field.value ?? [], {
                  id: habilitation.id,
                  label: habilitation.label,
                })
              );
            }}
          />
        );
      })}
    </Modal>
  );
};

export default DrivingLicenseHabilitationModal;
