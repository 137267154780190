import {
  FlagGermany,
  FlagArabia,
  FlagTurkey,
  FlagSweden,
  FlagPoland,
  FlagCzech,
  FlagHungary,
  FlagSwiss,
  FlagRussia,
  FlagSpain,
  FlagChineseMandarin,
  FlagVietnam,
  FlagRomania,
  FlagUK,
  FlagUkrain,
  FlagPortugal,
  FlagDanemark,
  FlagSerbia,
  FlagJapan,
  FlagChineseCantonese,
  FlagMalaysia,
  FlagThailand,
  FlagNetherlands,
  FlagChina,
  FlagNorway,
  FlagItaly,
  FlagFinland,
  FlagKorea,
  FlagFrance,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ReactNode } from 'react';

import { LanguagesRepository } from 'src/Services/API';

export interface Props {
  languagesRepository: LanguagesRepository;
}

export const languageIconMapping: Record<string, ReactNode> = {
  uqFEwszrVoa6l3hnlkkecMt3mEuZNFxhldjZZ0Y1qMA1: <FlagGermany />,
  UO9Di0OBUkfu_BwH4D_56ct3mEuZNFxhldjZZ0Y1qMA1: <FlagArabia />,
  cAf62GRGzCJ0kFlh8fnobct3mEuZNFxhldjZZ0Y1qMA1: <FlagTurkey />,
  '0-x8tWGbzN_fDdRE_a98Cst3mEuZNFxhldjZZ0Y1qMA1': <FlagSweden />,
  '7Qps8jefrFk_wU_ZZC7fs8t3mEuZNFxhldjZZ0Y1qMA1': <FlagPoland />,
  UH7jRnsH7Lj3evzOQp4BUst3mEuZNFxhldjZZ0Y1qMA1: <FlagCzech />,
  'S7kCVtRN-EZhGNQATKgr88t3mEuZNFxhldjZZ0Y1qMA1': <FlagHungary />,
  'Suisse allemand': <FlagSwiss />,
  fH8wOAzYHliKQF0FFhoimMt3mEuZNFxhldjZZ0Y1qMA1: <FlagRussia />,
  'j_Es78i-b2FEcb8J4GwWBMt3mEuZNFxhldjZZ0Y1qMA1': <FlagSpain />,
  'Chinois mandarin': <FlagChineseMandarin />,
  uaT9bu7PbwhbAKIRK9G_J8t3mEuZNFxhldjZZ0Y1qMA1: <FlagVietnam />,
  bNyBFMn1z4VWaAKKpAFBFct3mEuZNFxhldjZZ0Y1qMA1: <FlagRomania />,
  wE9J5qRohtLZETF6qB64oct3mEuZNFxhldjZZ0Y1qMA1: <FlagUK />,
  v97p6cvaoYnDj1y1koAKlMt3mEuZNFxhldjZZ0Y1qMA1: <FlagUkrain />,
  '0RkmhMxgfxnMSibZD9bIDst3mEuZNFxhldjZZ0Y1qMA1': <FlagPortugal />,
  cDxOlLhy3c_ybp4CvkGhq8t3mEuZNFxhldjZZ0Y1qMA1: <FlagDanemark />,
  cNBAbMy0YOIVttCiglOnsMt3mEuZNFxhldjZZ0Y1qMA1: <FlagSerbia />,
  'CJw_gmgFSzuoBukW-qmk-Mt3mEuZNFxhldjZZ0Y1qMA1': <FlagJapan />,
  KpY1qeH4XhK0yp8tujwNqct3mEuZNFxhldjZZ0Y1qMA1: <FlagChineseCantonese />,
  '9kp3SaGVN3gzLFdwuaxXjst3mEuZNFxhldjZZ0Y1qMA1': <FlagMalaysia />,
  '7knQB-oM1nq2VRHadhoQLst3mEuZNFxhldjZZ0Y1qMA1': <FlagThailand />,
  'xY4dPswYMpZOy8vOgN-DAct3mEuZNFxhldjZZ0Y1qMA1': <FlagNetherlands />,
  _CWXCLk4FagI4ZxnSGj8J8t3mEuZNFxhldjZZ0Y1qMA1: <FlagChina />,
  EbwGr0mza0JHoBkPyeIOp8t3mEuZNFxhldjZZ0Y1qMA1: <FlagNorway />,
  Hoc_nXAfxzV7pq29ReAmzMt3mEuZNFxhldjZZ0Y1qMA1: <FlagItaly />,
  'k-yPSOjJzgPM-DMTYHS-ast3mEuZNFxhldjZZ0Y1qMA1': <FlagFinland />,
  Fuk4flHdQHAkmsvTmutea8t3mEuZNFxhldjZZ0Y1qMA1: <FlagKorea />,
  '077sKsU9pODcqOgt36fV4ct3mEuZNFxhldjZZ0Y1qMA1': <FlagFrance />,
};
