import {
  BadgeDropdown,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import * as z from 'zod';

import { Diploma } from 'src/Assets';
import DiplomasPicker from 'src/Components/DiplomasPicker';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  EnumPositionStudyStudyLevelLevel,
  EnumPositionStudyStudyLevelPriority,
  Priority,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { priorityMapping } from '../Learning/Learning.component';

import styles from './Diplomas.module.scss';
import { DiplomasContext } from './DiplomasContext';
import { StudyLevelSelection } from './StudyLevelSelection';

export const diplomasSchema = z
  .object({
    hasDiplomas: z.boolean(),
    studyLevels: z.array(
      z.object({
        level: z.nativeEnum(EnumPositionStudyStudyLevelLevel),
        selected: z.boolean(),
        priority: z.nativeEnum(EnumPositionStudyStudyLevelPriority),
      })
    ),
    diplomas: z.array(
      z.object({ id: z.string(), label: z.string(), priority: z.nativeEnum(Priority) })
    ),
  })
  .refine(
    ({ hasDiplomas, diplomas, studyLevels }) => {
      const hasStudyLevelsSelected = studyLevels?.some(level => level.selected);
      return (hasDiplomas && diplomas.length > 0) || hasStudyLevelsSelected;
    },
    {
      message: "Vous devez renseigner au moins un diplôme ou un niveau d'études",
      path: ['diplomas'],
    }
  );

const Diplomas = () => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: diplomasSchema,
    defaultValues: {
      hasDiplomas:
        (fetchPositionStudy.data?.diplomas && fetchPositionStudy.data?.diplomas.length > 0) ||
        fetchPositionStudy.data?.studyLevels.some(level => level.selected),
      studyLevels: fetchPositionStudy.data?.studyLevels ?? [],
      diplomas: fetchPositionStudy.data?.diplomas,
    },
  });

  const {
    field,
    fieldState: { error },
  } = useController({ name: 'diplomas', control });

  const hasDiplomas = watch('hasDiplomas');

  const diplomasHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            studyLevels: values.studyLevels,
            diplomas: values.hasDiplomas ? values.diplomas : [],
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = diplomasHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, hasDiplomas]);

  return (
    <DiplomasContext.Provider
      value={{
        control: control,
        watch: watch,
        setValue: setValue,
        diplomasHandleSubmit: diplomasHandleSubmit,
      }}
    >
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2>diplôme</h2>
          <div className={styles.toggleContainer}>
            <p>le candidat doit-il posséder un ou des diplômes ?</p>
            <ToggleSwitch control={control} name="hasDiplomas" />
          </div>
          {hasDiplomas && (
            <>
              <StudyLevelSelection />
              <WithLightTitle title="rechercher des diplômes" className={styles.picker}>
                <DiplomasPicker
                  onChange={diplomas => field.onChange(diplomas)}
                  selectedDiplomas={field.value}
                />
                <ErrorMessage error={error} />
              </WithLightTitle>
              {field.value.length > 0 && (
                <WithLightTitle
                  className={styles.selectedContainer}
                  title={pluralFormat(field.value.length, 'diplôme sélectionné')}
                >
                  {field.value.map((diploma, idx) => {
                    return (
                      <HorizontalTextIconCard
                        containerClassName={styles.selectedCard}
                        leftIcon={<Diploma />}
                        key={diploma.id}
                        label={diploma.label}
                        rightIcon={<Trashcan />}
                        rightComponent={
                          <BadgeDropdown
                            items={Object.values(Priority)}
                            selectedItem={diploma.priority}
                            keyValueExtractor={(priority: Priority) => ({
                              key: priority,
                              value: priorityMapping[priority]?.label ?? '',
                              className: priorityMapping[priority]?.className,
                            })}
                            onSelectItem={(item: Priority) => {
                              const newItems = [...field.value];

                              newItems.splice(idx, 1, { ...diploma, priority: item });
                              field.onChange(
                                newItems.map((item, idx) => {
                                  return { ...item, rank: idx };
                                })
                              );
                            }}
                          />
                        }
                        onRightIconClick={() => {
                          field.onChange(field.value.filter(({ id }) => id !== diploma.id));
                        }}
                      />
                    );
                  })}
                </WithLightTitle>
              )}
            </>
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </DiplomasContext.Provider>
  );
};

export default Diplomas;
