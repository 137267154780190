import * as z from 'zod';

export interface Props {
  currentHabilitations: { id: string; label: string }[];
  onValidate: (newDrivingHabilitations: { id: string; label: string }[]) => void;
}

export const drivingLicensesHabilitationsSchema = z.object({
  habilitations: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
});
