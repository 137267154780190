import { durationSchema } from '@randstad-lean-mobile-factory/react-form-fields';
import * as z from 'zod';

import { useFetchSMICAmount } from 'src/Hooks/PositionStudies';

import { bonusRateSchema, getSalarySchema, workingHoursSchema } from './Components';

export function useProfileSchema() {
  const { data } = useFetchSMICAmount();

  const salarySchema = getSalarySchema(data?.smic);

  return z.object({
    positionStatus: z.string().optional(),
    salary: salarySchema,
    weekHours: durationSchema.refine(
      ({ hours, minutes }: { hours: number; minutes: number }) => hours * 60 + minutes <= 48 * 60,
      'la durée ne doit pas dépasser 48h'
    ),
    bonusRates: bonusRateSchema,
    practice: z.number(),
    workingHours: workingHoursSchema,
  });
}
