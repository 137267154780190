import * as z from 'zod';

import { practicalInfoSchema } from './Practical.schema';

export type PracticalSchemaType = z.infer<typeof practicalInfoSchema>;

export enum UploadStatus {
  None,
  InProgress,
  Failed,
  Done,
}

export interface DropzoneItem {
  id?: string;
  file: File;
  subtitle?: string;
  status: UploadStatus;
}
