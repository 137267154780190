import * as z from 'zod';

import { BonusItem } from 'src/Services/API';
export interface Props {
  onValidate: (bonus: BonusItem) => void;
}

export enum BONUS_ADD_STEP {
  CATEGORY_SELECTION,
  BONUS_DETAILS,
}

export const bonusDetailSchema = (maxAmount?: number) =>
  z.object({
    bonusLabel: z.string().min(1),
    bonusFrequency: z.object({ id: z.string(), label: z.string() }),
    bonusAmount: z.string().refine(
      item => {
        return (
          !isNaN(parseFloat(item ?? '')) &&
          parseFloat(item ?? '') >= 0 &&
          parseFloat(item ?? '') <= (maxAmount ?? Infinity)
        );
      },
      maxAmount
        ? `Le montant doit être compris entre 0 et ${maxAmount}`
        : 'Le montant doit être un nombre supérieur à 0'
    ),
  });

export type BonusDetailSchemaType = z.infer<
  z.ZodObject<{
    bonusLabel: z.ZodString;
    bonusFrequency: z.ZodObject<{
      id: z.ZodString;
      label: z.ZodString;
    }>;
    bonusAmount: z.ZodString;
  }>
>;

export const bonusCategorySchema = z.object({
  bonusCategory: z.object({ id: z.string(), liable: z.boolean() }),
});
export type BonusCategorySchemaType = z.infer<typeof bonusCategorySchema>;
