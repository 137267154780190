import {
  ComboBox,
  TextInput,
  ToggleSwitch,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ErrorMessage } from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import React, { useContext, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import DepartmentPicker from 'src/Components/DepartmentPicker';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader/RequiredFieldHeader.component';
import { useFetchCityList } from 'src/Hooks/Cities/useFetchCityList';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { City, CompanyAddress } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import CompanyWorkingLocationPicker from '../CompanyWorkingLocationPicker';
import { PracticalContext } from '../PracticalContext';

import styles from './WorkingLocationSelection.module.scss';

const WorkingLocationSelection = () => {
  const isMobile = useSelector(getDevice);
  const practicalContext = useContext(PracticalContext);
  const workLocation = practicalContext.watch('workLocation');
  const {
    fieldState: { error },
  } = useController({
    name: 'workLocation',
    control: practicalContext.control,
  });

  const shouldDisplayWorkLocationWarning =
    !(
      workLocation.customWorkingLocation?.address !== undefined &&
      workLocation.customWorkingLocation.address.length > 0 &&
      workLocation.customWorkingLocation?.zipCode !== undefined
    ) ||
    workLocation.customWorkingLocation?.address === undefined ||
    workLocation.customWorkingLocation?.address.length === 0 ||
    workLocation.customWorkingLocation?.zipCode === undefined;

  const [keyword, setKeyword] = useState(workLocation.customWorkingLocation?.cityName ?? '');
  const [selectedCity, setSelectedCity] = useState<City | null>(
    workLocation.customWorkingLocation?.zipCode !== undefined
      ? {
          name: workLocation.customWorkingLocation.cityName,
          insee: workLocation.customWorkingLocation.inseeCode,
          zipCode: workLocation.customWorkingLocation.zipCode,
        }
      : null
  );
  const { data, isSuccess, isError, isLoading } = useFetchCityList({ keyword });
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  return (
    <div className={styles.container}>
      <h2>lieu de travail</h2>
      <div className={styles.toggleContainer}>
        <p>il s'agit d'un lieu de travail mobile </p>
        <ToggleSwitch
          checked={workLocation.isMobileWorkLocation}
          onCheckStatusChange={(check: boolean) => {
            practicalContext.setValue('workLocation.isMobileWorkLocation', check);
            practicalContext.setValue('workLocation.mobileWorkLocationDepartment', undefined);
            practicalContext.setValue('workLocation.workingLocation', undefined);
            practicalContext.submit();
          }}
        />
      </div>
      {workLocation.isMobileWorkLocation ? (
        <div className={isMobile ? '' : styles.departmentContainer}>
          <DepartmentPicker
            selectedDepartmentId={workLocation.mobileWorkLocationDepartment}
            setDepartementId={(departmentId?: string) => {
              practicalContext.setValue('workLocation.mobileWorkLocationDepartment', departmentId);
              practicalContext.submit();
            }}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.elementContainer}>
            <CompanyWorkingLocationPicker
              selectedCompanyWorkingLocation={workLocation.workingLocation}
              setSelectedCompanyWorkingLocation={(workingLocation?: CompanyAddress) => {
                practicalContext.setValue('workLocation.workingLocation', workingLocation);
                if (workingLocation !== undefined) {
                  practicalContext.setValue('workLocation.customWorkingLocation', workingLocation);
                  setSelectedCity({
                    name: workingLocation.cityName,
                    insee: workingLocation.inseeCode,
                    zipCode: workingLocation.zipCode,
                  });
                }
                practicalContext.submit();
              }}
            />
          </div>
          <div className={styles.elementContainer}>
            <RequiredFieldHeader
              fieldHeader={
                isMobile
                  ? 'ou entrez à minima une ville et un numéro de voie'
                  : 'ou entrez à minima une adresse avec une ville et un numéro de voie'
              }
              isMobile={isMobile}
            />
            <TextInput
              value={workLocation.customWorkingLocation?.address}
              onChange={event => {
                practicalContext.setValue('workLocation.workingLocation', undefined);
                practicalContext.setValue('workLocation.customWorkingLocation', {
                  address: (event.target as HTMLInputElement).value,
                  cityName: workLocation.customWorkingLocation?.cityName,
                  zipCode: workLocation.customWorkingLocation?.zipCode,
                  inseeCode: workLocation.customWorkingLocation?.inseeCode,
                });
                practicalContext.submit();
              }}
              placeholder="numéro et rue, avenue, voie…"
              containerClassName={classNames({
                [styles.warningWorkLocation]: shouldDisplayWorkLocationWarning,
              })}
            />
          </div>
          <div className={isMobile ? '' : styles.cityContainer}>
            <RequiredFieldHeader fieldHeader="ville" isMobile={isMobile} />
            <ComboBox
              id="city"
              placeholder="nom d'une ville"
              value={selectedCity}
              keyValueExtractor={(searchResult: City) => ({
                key: searchResult.uId ?? '',
                value: searchResult.name ?? '',
              })}
              onSearch={(keyword: string) => throttledSetKeyword(keyword)}
              onChange={item => {
                setSelectedCity(item);
                practicalContext.setValue('workLocation.workingLocation', undefined);
                practicalContext.setValue('workLocation.customWorkingLocation', {
                  address: workLocation.customWorkingLocation?.address,
                  cityName: item?.name,
                  zipCode: item?.zipCode,
                  inseeCode: item?.insee,
                });
                practicalContext.submit();
              }}
              searchResults={data ?? []}
              fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
              minLengthToSearch={1}
              isError={shouldDisplayWorkLocationWarning}
            />
            {shouldDisplayWorkLocationWarning && (
              <div className={styles.warningMessage}>adresse manquante</div>
            )}
          </div>
        </div>
      )}
      <ErrorMessage error={error} />
    </div>
  );
};

export default WorkingLocationSelection;
