import { WithLightTitle, DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import { ErrorMessage, TextInput } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useFetchBonusRatesCadencies } from 'src/Hooks/Bonus/useFetchBonusRatesCadencies';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { BonusRateCadencies } from 'src/Services/API';

import styles from './FrequencyAmountSelection.module.scss';
import { Props } from './FrequencyAmountSelection.types';

const FrequencyAmountSelection = ({ presetBonus, control }: Props) => {
  const isMobile = useSelector(getDevice);
  const frequencyQuery = useFetchBonusRatesCadencies();
  const bonusFrequencyController = useController({
    name: 'bonusFrequency',
    control,
  });
  return (
    <div className={isMobile ? styles.containerMobile : styles.containerDesktop}>
      <WithLightTitle
        title="fréquence de la prime"
        className={isMobile ? styles.inputMobile : styles.inputDesktop}
      >
        <DropDown
          placeholder="choisir une fréquence"
          items={frequencyQuery.data ?? []}
          onSelectItem={(item?: BonusRateCadencies) => {
            item?.id === bonusFrequencyController.field.value?.id
              ? bonusFrequencyController.field.onChange(undefined)
              : bonusFrequencyController.field.onChange(item);
          }}
          selectedItem={
            presetBonus === undefined
              ? bonusFrequencyController.field.value
              : frequencyQuery.data?.find(frequency => frequency.id === presetBonus.frequency)
          }
          keyValueExtractor={(item: BonusRateCadencies) => {
            return { key: item.id, value: item.label };
          }}
          disabled={presetBonus !== undefined}
        />
        <ErrorMessage error={bonusFrequencyController.fieldState.error} />
      </WithLightTitle>
      <WithLightTitle
        title="montant de la prime"
        className={isMobile ? styles.inputMobile : styles.inputDesktop}
      >
        <TextInput
          placeholder="montant en euros"
          name="bonusAmount"
          control={control}
          max={presetBonus?.maxAmount}
        />
      </WithLightTitle>
    </div>
  );
};
export default FrequencyAmountSelection;
