import React from 'react';

import { BonusItem } from 'src/Services/API';

import { bonusDetailSchema } from '../BonusRatesModal.types';

export interface Props {
  label: string;
  id?: string;
  subLabel: string;
  initialAmount: number;
  initialFrequency: { id: string; label: string };
  leftIcon: React.ReactNode;
  onRightIconClick: () => void;
  onValidate: (bonus: BonusItem) => void;
}

export const bonusModificationSchema = (maxAmount?: number) => bonusDetailSchema(maxAmount);
