import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
  EnumPositionStudyRisksHasRisks,
  Sector,
} from 'src/Services/API';

import { CompletionStatusState, RequiredFields } from './Types';

const initialState: CompletionStatusState = {
  totalSkills: 0,
  selectedSector: { id: '', label: '' },
  currentHasRisks: EnumPositionStudyRisksHasRisks.No,
  currentHasEpis: EnumPositionStudyProtectionEquipmentsHasEpis.No,
  currentHasEpcs: EnumPositionStudyProtectionEquipmentsHasEpcs.No,
  requiredFields: {
    hr: {
      profile: { positionStatus: false, practice: false, weekHours: false },
      usefulinfo: {
        selectedSector: false,
        contactId: false,
        zipCode: false,
      },
    },
    security: {
      risks: { risks: false },
      protectionEquipment: { hasEpis: false, hasEpcs: false },
      instructions: { hasHomeFormalisation: false },
    },
    job: { skills: false },
  },
};

const { reducer, actions } = createSlice({
  name: 'completionStatus',
  initialState,
  reducers: {
    setTotalSkills: (state, action: PayloadAction<number>) => {
      state.totalSkills = action.payload;
    },
    setSelectedSector: (state, action: PayloadAction<Sector>) => {
      state.selectedSector = action.payload;
    },
    setCurrentHasRisks: (state, action: PayloadAction<EnumPositionStudyRisksHasRisks>) => {
      state.currentHasRisks = action.payload;
    },
    setCurrentHasEpis: (
      state,
      action: PayloadAction<EnumPositionStudyProtectionEquipmentsHasEpis>
    ) => {
      state.currentHasEpis = action.payload;
    },
    setCurrentHasEpcs: (
      state,
      action: PayloadAction<EnumPositionStudyProtectionEquipmentsHasEpcs>
    ) => {
      state.currentHasEpcs = action.payload;
    },
    setRequiredFields: (state, action: PayloadAction<RequiredFields>) => {
      state.requiredFields = action.payload;
    },
  },
});

export const completionStatusReducer = reducer;
export const completionStatusActions = actions;
