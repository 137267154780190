import * as z from 'zod';

import { EnumPositionStudyInstructionsHasHomeFormalisation } from 'src/Services/API';

export const instructionSchema = z
  .object({
    hasInstructionsToForward: z.boolean(),
    instructionToFollow: z.string(),
    hasHomeFormalisation: z.nativeEnum(EnumPositionStudyInstructionsHasHomeFormalisation),
    contactWelcomeToPost: z.string().optional(),
    contactForFirstDay: z
      .object({
        lastName: z.string(),
        firstName: z.string(),
        phone: z
          .string()
          .regex(
            /[0-9]{2}(\s)?[0-9]{2}(\s)?[0-9]{2}(\s)?[0-9]{2}(\s)?[0-9]{2}/,
            'veuillez préciser un numéro de téléphone valide'
          )
          .or(z.literal('')),
      })
      .refine(
        ({ firstName, lastName, phone }) => {
          return !(!firstName && (lastName || phone));
        },
        { path: ['firstName'], message: 'ce champ est obligatoire' }
      )
      .refine(
        ({ firstName, lastName, phone }) => {
          return !(!lastName && (firstName || phone));
        },
        { path: ['lastName'], message: 'ce champ est obligatoire' }
      ),
  })
  .refine(
    ({ hasInstructionsToForward, instructionToFollow }) => {
      return !(hasInstructionsToForward && !instructionToFollow);
    },
    { path: ['hasInstructionsToForward'], message: 'vous devez décrire les consignes ci-dessous' }
  );
