import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { useFetchTestList } from 'src/Hooks/PositionStudies/useFetchTestList';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './TestsPicker.types';

const TestsPicker = ({ onChange, selectedTests }: Props) => {
  const { data, isSuccess, isError, isLoading } = useFetchTestList();

  return (
    <AutoSuggest
      id="tests"
      value={selectedTests}
      useNativeOptionFiltering
      keyValueExtractor={test => ({
        key: test.id ?? '',
        value: test.label ?? '',
      })}
      searchResults={data ?? []}
      onChange={onChange}
      minLengthToSearch={3}
      fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
    />
  );
};

export default TestsPicker;
