import ContentLoader from 'react-content-loader';

import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';

import { Props } from './LoadMoreLoader.types';

const LoadMoreLoader = ({ displayMode }: Props) => (
  <ContentLoader
    height={displayMode === DISPLAY_MODE.GRID ? '19rem' : '3.5rem'}
    width="100%"
    backgroundColor="#d7d7d7"
  >
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);

export default LoadMoreLoader;
