import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Euro } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';

import BonusRatesModal from 'src/Containers/Modals/BonusRatesModal';
import {
  liableIconMapping,
  unliableIconMapping,
} from 'src/Containers/Modals/BonusRatesModal/BonusRatesCategories/BonusRatesCategories.component';
import BonusRatesModification from 'src/Containers/Modals/BonusRatesModal/BonusRatesModification';
import { useFetchPresetBonuses } from 'src/Hooks/Bonus/useFetchPresetBonuses';
import { BonusItem as Bonus } from 'src/Services/API';

import { ProfileContext } from '../../ProfileContext';

import styles from './BonusRatesSelection.module.scss';
import { newBonusId } from './BonusRatesSelection.schema';

const BonusRatesSelection = () => {
  const profileContext = useContext(ProfileContext);
  const { fields, append, remove, update } = useFieldArray({
    control: profileContext.control,
    name: 'bonusRates',
    keyName: 'key',
  });

  const { data } = useFetchPresetBonuses();
  return (
    <>
      <WithLightTitle title="vos primes pour ce poste">
        {fields?.map(({ key, id, label, liable, amount, cadency }, idx) => {
          const matchingLabel = data?.find(presetBonus => presetBonus.title === label)?.id;
          return (
            <BonusRatesModification
              id={id}
              key={key}
              label={label}
              subLabel={`${!liable ? 'non assujetti' : 'assujetti'} | ${amount}€/${cadency.label}`}
              leftIcon={
                matchingLabel ? (
                  liable ? (
                    liableIconMapping[matchingLabel]
                  ) : (
                    unliableIconMapping[matchingLabel]
                  )
                ) : (
                  <Euro />
                )
              }
              initialFrequency={cadency}
              initialAmount={amount}
              onRightIconClick={() => {
                remove(idx);
                profileContext.submit();
              }}
              onValidate={(modifiedBonus: Bonus) => {
                update(idx, {
                  id: modifiedBonus.id,
                  label: modifiedBonus.label ?? '',
                  amount: modifiedBonus.amount ?? 0,
                  cadency: {
                    id: modifiedBonus.cadency?.id ?? '',
                    label: modifiedBonus.cadency?.label ?? '',
                  },
                });
                profileContext.submit();
              }}
            />
          );
        })}
        {(fields.length ?? 0) > 0 && <div className={styles.bonusBottomSeparator} />}
      </WithLightTitle>
      <BonusRatesModal
        onValidate={(newBonus: Bonus) => {
          append({
            // Because of react-hook-form, we have to implmement a default id (newBonusId) otherwise "append" will use the id of another element of the array as the id
            id: newBonus.id ?? newBonusId,
            label: newBonus.label ?? '',
            amount: newBonus.amount ?? 0,
            cadency: {
              id: newBonus.cadency?.id ?? '',
              label: newBonus.cadency?.label ?? '',
            },
            liable: newBonus.liable ?? false,
          });
          profileContext.submit();
        }}
      />
    </>
  );
};

export default BonusRatesSelection;
