import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Grid, List } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import styles from './DisplayModeSelector.module.scss';
import { Props } from './DisplayModeSelector.types';

export enum DISPLAY_MODE {
  LIST,
  GRID,
}

export const displayModes = [
  {
    mode: DISPLAY_MODE.GRID,
    name: 'cartes',
    icon: <Grid className={styles.menuIcon} />,
  },
  {
    mode: DISPLAY_MODE.LIST,
    name: 'liste',
    icon: <List className={styles.menuIcon} />,
  },
];

const DisplayModeSelector = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const currentMode = displayModes.find(dpm => dpm.mode === props.displayMode)?.name;
  return (
    <PopupMenu
      position="bottom right"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      contentStyle={{ width: '14.625rem' }}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            {displayModes.find(dpm => dpm.mode === props.displayMode)?.icon}
          </Button.Tertiary>
        </div>
      }
      selectedIds={currentMode ? [currentMode] : undefined}
    >
      {displayModes.map(dpm => {
        return (
          <PopupMenu.Item
            icon={dpm.icon}
            text={dpm.name}
            onClick={() => {
              props.onDisplayModeIndexUpdate(dpm.mode);
            }}
            key={dpm.name}
            id={dpm.name}
          />
        );
      })}
    </PopupMenu>
  );
};

export default DisplayModeSelector;
