import { ErrorMessage } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import { useContext } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import { ProfileContext } from '../../ProfileContext';

import SalaryBaseSelection from './SalaryBaseSelection';
import { SalaryFixedAmount } from './SalaryFixedAmount';
import SalaryFormatSelection from './SalaryFormatSelection';
import { SalaryInBetweenAmount } from './SalaryInBetweenAmount';
import styles from './SalarySelection.module.scss';

const SalarySelection = () => {
  const isMobile = useSelector(getDevice);
  const profileContext = useContext(ProfileContext);
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: 'salary',
    control: profileContext.control,
  });

  return (
    <div className={styles.salaryContainer}>
      <div
        className={classnames(
          isMobile ? styles.mobileSalaryFirstRowContainer : styles.salaryFirstRowContainer
        )}
      >
        <SalaryBaseSelection
          isSMICChecked={value.isSmic}
          selected={value.salaryTimeRate}
          setSelected={salaryTimeRate => {
            onChange({ ...value, salaryTimeRate });
          }}
        />
        <SalaryFormatSelection
          isSMICChecked={value.isSmic}
          selected={value.salaryFormat}
          setSelected={salaryFormat => {
            onChange({ ...value, salaryFormat });
          }}
        />
      </div>
      <div className={classnames(styles.salarySecondRowContainer)}>
        {value.salaryFormat === 'fixe' ? (
          <SalaryFixedAmount value={value} onChange={onChange} />
        ) : (
          <SalaryInBetweenAmount value={value} onChange={onChange} />
        )}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default SalarySelection;
