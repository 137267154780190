import * as z from 'zod';

import {
  DetailedPositionStudy,
  EnumPositionStudyLanguagesHasToSpeakOtherThanFrenchLanguage as YesNoUnknownEnum,
  LanguagesRepository,
  PositionStudyLanguages,
} from 'src/Services/API';

export const languageSchema = z
  .object({
    hasToSpeakFrench: z.boolean(),
    hastToSpeakOtherThanFrenchLanguage: z.nativeEnum(YesNoUnknownEnum),
    otherThanFrenchLanguages: z.array(z.object({ id: z.string(), label: z.string() })),
  })
  .refine(
    ({ hastToSpeakOtherThanFrenchLanguage, otherThanFrenchLanguages }) => {
      if (hastToSpeakOtherThanFrenchLanguage !== YesNoUnknownEnum.Yes) return true;

      return otherThanFrenchLanguages.length > 0;
    },
    {
      message: 'Vous devez renseigner au moins une langue si vous avez selectionné oui',
      path: ['hastToSpeakOtherThanFrenchLanguage'],
    }
  );

export type LanguageSchemaType = z.infer<typeof languageSchema>;

export function getDefaultValues(
  languagesRepository: LanguagesRepository,
  languages?: PositionStudyLanguages
): z.infer<typeof languageSchema> {
  const otherThanFrenchLanguages =
    languages?.requiredLanguages.filter(({ id }) => id !== languagesRepository.french?.id) ?? [];

  const hasToSpeakFrench =
    otherThanFrenchLanguages.length + 1 === languages?.requiredLanguages.length ?? 0;

  const hastToSpeakOtherThanFrenchLanguage =
    languages?.hasToSpeakOtherThanFrenchLanguage ?? YesNoUnknownEnum.Unknown;

  return {
    hasToSpeakFrench,
    hastToSpeakOtherThanFrenchLanguage,
    otherThanFrenchLanguages,
  };
}

export function getEditedLanguages(
  languagesRepository: LanguagesRepository,
  data: z.infer<typeof languageSchema>
): DetailedPositionStudy['languages'] {
  const requiredLanguages = [
    ...data.otherThanFrenchLanguages,
    ...(data.hasToSpeakFrench && languagesRepository.french ? [languagesRepository.french] : []),
  ];

  return {
    requiredLanguages,
    hasToSpeakOtherThanFrenchLanguage: data.hastToSpeakOtherThanFrenchLanguage,
  };
}
