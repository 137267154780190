import { DragIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { SuggestedSkillsAndTasks } from 'src/Assets';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './SkillCard.module.scss';
import { Props } from './SkillCard.types';
import SkillCardRightComponents from './SkillCardRightComponents/SkillCardRightComponents.component';

const SkillCard = ({ skill, idx, field }: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const isMobile = useSelector(getDevice);

  return (
    <div
      className={classnames(
        styles.container,
        { [styles.openPopup]: dropDownOpen },
        { [styles.mobileContainer]: isMobile }
      )}
    >
      {isMobile && (
        <div className={styles.mobileActions}>
          <DragIcon className={styles.dragIcon} />
          <SkillCardRightComponents
            seeAll={setSeeAll}
            setOpenPopup={setDropDownOpen}
            idx={idx}
            skill={skill}
            field={field}
          />
        </div>
      )}
      <div className={styles.leftSide}>
        {!isMobile && <DragIcon className={styles.dragIcon} />}
        <SuggestedSkillsAndTasks className={styles.leftIcon} />
        <div className={styles.leftTextContainer}>
          <div className={classnames(styles.title)}>{`${skill.rank + 1}. ${skill.label}`}</div>

          <div className={styles.description}>
            {skill.tasks !== undefined && skill.tasks.length > 0 && (
              <div>
                {pluralFormat(skill.tasks.length, 'tâche')}
                <span className={styles.seeAll} onClick={() => setSeeAll(!seeAll)}>
                  {seeAll ? 'refermer' : 'tout voir'}
                </span>
              </div>
            )}
            {seeAll && (
              <ul className={styles.listContainer}>
                {skill.tasks?.map(task => (
                  <li>{task}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {!isMobile && (
        <SkillCardRightComponents
          seeAll={setSeeAll}
          setOpenPopup={setDropDownOpen}
          idx={idx}
          skill={skill}
          field={field}
        />
      )}
    </div>
  );
};

export default SkillCard;
