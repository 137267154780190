import * as z from 'zod';

import { serviceSelectionSchema } from './ServiceSelection/ServiceSelection.schema';
import { workLocationSchema } from './WorkingLocationSelection/WorkingLocationSelection.schema';

export const practicalInfoSchema = z.object({
  workLocation: workLocationSchema,
  service: serviceSelectionSchema,
  contactId: z.string().optional(),
  jobInfo: z.string().optional(),
  sector: z.object({ id: z.string(), label: z.string() }).optional(),
});
