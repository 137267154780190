import {
  LetterBadge,
  RandstadMobileAppsPopup,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  EDPIcon,
  Grid,
  WhiteCross,
  OpenLock,
  StraightBurgerMenu,
  RandstadDisk,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useSelector } from 'react-redux';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { ScrollDirection, useScrollDirection } from 'src/Utils/detectScrollDirection';

import styles from './MobileTopBar.module.scss';

const MobileTopBar = () => {
  const { givenName, familyName, email } = useAuthenticatedUser();
  const { isProd } = useIsProductionEnvironment();
  const scrollDirection = useScrollDirection('formContainer');

  const brand = useSelector(getCurrentBrand);
  const [isAppLinkOpen, setIsAppLinkOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useAuth();

  const { open } = useModalWithLocation('perimeter-selection');
  return (
    <div
      className={classnames(styles.container, {
        [styles.hideTopBar]: scrollDirection === ScrollDirection.DOWN,
      })}
    >
      {scrollDirection !== ScrollDirection.DOWN && (
        <Menu
          isOpen={isOpen}
          customBurgerIcon={<StraightBurgerMenu className={styles.burgerButton} />}
          className={styles.burgerMenu}
          customCrossIcon={<WhiteCross />}
          onClose={() => {
            setIsAppLinkOpen(false);
            setIsOpen(false);
          }}
          onOpen={() => setIsOpen(true)}
        >
          <div className={styles.menuContainer}>
            {isAppLinkOpen ? (
              <RandstadMobileAppsPopup
                isProd={isProd}
                onArrowClick={() => setIsAppLinkOpen(false)}
                brandCode={brand?.brand?.code}
              />
            ) : (
              <div>
                <div className={styles.userInfo}>
                  <LetterBadge
                    text={`${givenName?.[0] ?? '-'}${familyName?.[0] ?? '-'}`}
                    className={styles.letterBadge}
                  />
                  <div>
                    <p className={styles.whiteText}>
                      {givenName} {familyName}
                    </p>
                    <p className={styles.greyText}>{email}</p>
                  </div>
                </div>
                <div className={styles.lineSeparator} />
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    setIsOpen(false);
                    open({});
                  }}
                >
                  <div className={styles.circle}>
                    <RandstadDisk className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>choix unités</p>
                    <p className={styles.greyText}>définissez votre périmètre</p>
                  </div>
                </div>
                <div
                  className={classnames(styles.menuItem, styles.lastMenuItem)}
                  onClick={() => setIsAppLinkOpen(true)}
                >
                  <div className={styles.circle}>
                    <Grid className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>inter apps</p>
                    <p className={styles.greyText}>naviguer parmi les applications Randstad</p>
                  </div>
                </div>
                <div className={styles.lineSeparator} />
                <div
                  className={styles.disconnect}
                  onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
                >
                  <div className={styles.circle}>
                    <OpenLock className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>déconnexion</p>
                    <p className={styles.greyText}>fermer la session</p>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className={styles.lineSeparator} />
              <p className={styles.version}>
                {process.env.REACT_APP_VERSION &&
                  `version de l'app : ${process.env.REACT_APP_VERSION}`}
              </p>
            </div>
          </div>
        </Menu>
      )}

      <div className={styles.topBarComponent}>
        <EDPIcon />
        <div className={styles.separator} />
        <h1 className={styles.title}>étude de poste</h1>
      </div>
    </div>
  );
};
export default MobileTopBar;
