import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, CACESCategory } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCaces = (
  extraOptions?: UseQueryOptions<CACESCategory[], unknown, CACESCategory[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchCaces],
    async () => {
      return RepositoriesService.repositoriesControllerGetCaces();
    },
    { ...extraOptions }
  );
};
