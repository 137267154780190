import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { City, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCityList = (
  { keyword }: { keyword: string },
  extraOptions?: UseQueryOptions<City[], unknown, City[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCities, keyword],
    async () => {
      if (keyword) {
        return RepositoriesService.repositoriesControllerGetCities({
          keyword,
        });
      }
      return [];
    },
    { ...extraOptions }
  );
};
