import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BigActionButton,
  DocumentPDF,
  Flux,
  Refresh,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import MissionCreationFlashModal from 'src/Containers/Modals/MissionCreationFlashModal';
import SynthesisSheetLoadingModal from 'src/Containers/Modals/SynthesisSheetLoadingModal/SynthesisSheetLoadingModal.component';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { useGeneratePositionStudyPdf } from 'src/Hooks/PositionStudies/useGeneratePositionStudyPdf';
import { useGeneratePreventionSheetPdf } from 'src/Hooks/Security/useGeneratePreventionSheetPdf';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumDetailedPositionStudyCompletionStatus } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { onFileOpen } from 'src/Utils/onFileOpen';

import { getAnalyticsUserInfo } from '../../Redux/Perimeter/Selectors';
import { getDevice } from '../../Redux/UserPreferences/Selectors';

import styles from './EDPDetailActions.module.scss';
import { Props } from './EDPDetailActions.types';

const PositionStudyActions = ({ positionStudyId, positionStudyCompletionStatus }: Props) => {
  const userInfo = useSelector(getAnalyticsUserInfo);
  const [isOpen, setIsOpen] = useState(false);
  const [isSynthesisSheetLoadingModalOpen, setIsSynthesisSheetLoadingModalOpen] = useState(false);
  const generatePositionStudyPdf = useGeneratePositionStudyPdf();
  const generatePreventionSheetPdf = useGeneratePreventionSheetPdf();
  const isMobile = useSelector(getDevice);
  const { isProd } = useIsProductionEnvironment();
  const openRecrutlive = () => {
    window.open(
      `${process.env.REACT_APP_RECRUTLIVE_URL}?edpId=${positionStudyId}`,
      '_blank',
      'noopener,noreferrer'
    );
    ReactGA.event("Requête Recrut'Live", {
      ...userInfo,
    });
  };
  return (
    <PopupMenu
      position="bottom left"
      width={257}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <BigActionButton className={styles.icon} />
        </Button.Tertiary>
      }
    >
      {!isMobile && (
        <>
          <PopupMenu.Item icon={<Flux />} text="recrut'live" onClick={openRecrutlive} />
          <MissionCreationFlashModal
            positionStudyId={positionStudyId}
            positionStudyCompletionStatus={positionStudyCompletionStatus}
          />
        </>
      )}
      <PopupMenu.Item
        icon={
          toFetchStatus(generatePositionStudyPdf) === FETCH_STATUS.PENDING ? (
            <Refresh className={styles.loading} />
          ) : (
            <DocumentPDF />
          )
        }
        text="exporter l'étude"
        keepOpenOnClick
        onClick={() => {
          generatePositionStudyPdf.mutate(positionStudyId ?? '', {
            onSuccess: buffer => onFileOpen(buffer, 'application/pdf'),
          });
        }}
        disabled={positionStudyId === undefined}
      />
      <PopupMenu.Item
        icon={
          toFetchStatus(generatePreventionSheetPdf) === FETCH_STATUS.PENDING ? (
            <Refresh className={styles.loading} />
          ) : (
            <DocumentPDF />
          )
        }
        text="fiche prévention"
        keepOpenOnClick
        onClick={() => {
          generatePreventionSheetPdf.mutate(positionStudyId ?? '', {
            onSuccess: buffer => onFileOpen(buffer, 'application/pdf'),
          });
        }}
        disabled={positionStudyId === undefined}
      />
      {!isProd &&
        positionStudyCompletionStatus === EnumDetailedPositionStudyCompletionStatus.UP_TO_DATE && (
          <>
            <PopupMenu.Item
              icon={<DocumentPDF />}
              text="synthèse du marché"
              keepOpenOnClick
              onClick={() => {
                setIsSynthesisSheetLoadingModalOpen(true);
              }}
              disabled={positionStudyId === undefined}
            />
            <SynthesisSheetLoadingModal
              positionStudyId={positionStudyId ?? ''}
              open={isSynthesisSheetLoadingModalOpen}
              onClose={() => {
                setIsSynthesisSheetLoadingModalOpen(false);
              }}
            />
          </>
        )}
    </PopupMenu>
  );
};

export default PositionStudyActions;
