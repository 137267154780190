import { BadgeDropdown } from '@randstad-lean-mobile-factory/react-components-core';

import { Priority } from 'src/Services/API';

import { priorityMapping } from '../../Learning.component';
import SkillCardButton from '../SkillCardButton';

import styles from './SkillCardRightComponents.module.scss';
import { RightComponentProps } from './SkillCardRightComponents.types';
const SkillCardRightComponents = ({
  field,
  skill,
  idx,
  setOpenPopup,
  seeAll,
}: RightComponentProps) => (
  <div className={styles.rightsActions}>
    <BadgeDropdown
      items={Object.values(Priority)}
      selectedItem={skill.priority}
      onOpen={() => setOpenPopup(true)}
      onClose={() => setOpenPopup(false)}
      keyValueExtractor={(priority: Priority) => ({
        key: priority,
        value: priorityMapping[priority]?.label ?? '',
        className: priorityMapping[priority]?.className,
      })}
      onSelectItem={(item: Priority) => {
        const newItems = [...field.value];

        newItems.splice(idx, 1, { ...skill, priority: item });
        field.onChange(
          newItems.map((item, idx) => {
            return { ...item, rank: idx };
          })
        );
      }}
    />

    <SkillCardButton
      skill={skill}
      onOpen={() => setOpenPopup(true)}
      onClose={() => setOpenPopup(false)}
      onValidate={tasks => {
        const newItems = [...field.value];
        newItems.splice(idx, 1, { ...skill, tasks });
        field.onChange(
          newItems.map((item, idx) => {
            return { ...item, rank: idx };
          })
        );
        if (tasks.length === 0) {
          seeAll(false);
        }
      }}
    />
  </div>
);

export default SkillCardRightComponents;
