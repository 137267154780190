import { RequiredFieldsObject } from 'src/Redux/CompletionStatus/Types';

export const checkTruthOfRequiredFields = (obj: RequiredFieldsObject): boolean => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (obj[key] && checkTruthOfRequiredFields(obj[key] as RequiredFieldsObject)) {
        return true;
      }
    } else if (obj[key]) {
      return true;
    }
  }
  return false;
};
