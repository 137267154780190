import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  HighVoltage,
  LowVoltage,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import flattenDeep from 'lodash.flattendeep';
import React, { useCallback, useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import * as z from 'zod';

import { Habilitation } from 'src/Assets';
import HabilitationsPicker from 'src/Components/HabilitationsPicker';
import ElectricHabilitationModal from 'src/Containers/Modals/ElectricHabilitationModal';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useFetchCaces } from 'src/Hooks/Training/useFetchCaces';
import { useFetchElectricHabilitations } from 'src/Hooks/Training/useFetchElectricHabilitations';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Habilitations.module.scss';

const schema = z.object({
  habilitations: z.array(z.object({ id: z.string(), label: z.string() })),
});

const Habilitations = () => {
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const initialHabilitations = fetchPositionStudy.data?.habilitations;
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema,
    defaultValues: {
      habilitations: initialHabilitations ?? [],
    },
  });
  const cacesCategory = useFetchCaces();
  const electricHabilitations = useFetchElectricHabilitations();
  const lowVoltage = electricHabilitations.data?.find(item => item.currentId === 'B');
  const highVoltage = electricHabilitations.data?.find(item => item.currentId === 'H');
  const lowVoltageIds = flattenDeep<string>(
    lowVoltage?.levels.map(level => level.currents.map(current => current.electricHabilitation.id))
  );
  const highVoltageIds = flattenDeep<string>(
    highVoltage?.levels.map(level => level.currents.map(current => current.electricHabilitation.id))
  );

  const { field } = useController({ name: 'habilitations', control });
  const nonCacesHabilitation = field.value?.filter(
    item =>
      !cacesCategory.data
        ?.map(category => category.caces)
        .flat(1)
        .map(caces => caces.id)
        .includes(item.id)
  );

  const habilitationsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            habilitations: values.habilitations,
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = habilitationsHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <h2>votre choix</h2>
          <WithLightTitle title="habilitations électriques" className={styles.picker}>
            <ElectricHabilitationModal
              currentHabilitations={field.value ?? []}
              onValidate={electricHabilitation => {
                field.onChange([...field.value, electricHabilitation]);
                submit();
              }}
            />
          </WithLightTitle>
          <WithLightTitle
            title="ou rechercher parmi toutes les habilitations"
            className={styles.picker}
          >
            <HabilitationsPicker
              onChange={habilitations => field.onChange(habilitations)}
              selectedHabilitations={field.value}
            />
          </WithLightTitle>
          {nonCacesHabilitation.length > 0 && (
            <WithLightTitle
              className={styles.selectedContainer}
              title={pluralFormat(nonCacesHabilitation.length, 'habilitation sélectionné')}
            >
              {nonCacesHabilitation.map(habilitation => {
                return (
                  <React.Fragment key={habilitation.id}>
                    <HorizontalTextIconCard
                      containerClassName={styles.selectedCard}
                      leftIcon={
                        lowVoltageIds.includes(habilitation.id) ? (
                          <LowVoltage />
                        ) : highVoltageIds.includes(habilitation.id) ? (
                          <HighVoltage />
                        ) : (
                          <Habilitation />
                        )
                      }
                      label={habilitation.label}
                      rightIcon={<Trashcan />}
                      onRightIconClick={() => {
                        field.onChange(field.value.filter(({ id }) => id !== habilitation.id));
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </WithLightTitle>
          )}
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </div>
  );
};

export default Habilitations;
