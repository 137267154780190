import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { TextInput } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { useFetchBonusRatesCadencies } from 'src/Hooks/Bonus/useFetchBonusRatesCadencies';
import { useFetchPresetBonuses } from 'src/Hooks/Bonus/useFetchPresetBonuses';

import FrequencyAmountSelection from '../FrequencyAmountSelection';

import { Props } from './BonusRatesDetails.types';

const BonusRatesDetails = ({ control, bonusCategoyField, setValue }: Props) => {
  const frequencyQuery = useFetchBonusRatesCadencies();

  const bonusLabelController = useController({
    name: 'bonusLabel',
    control,
  });

  const { data } = useFetchPresetBonuses();

  const presetBonus = data?.find(preset => preset.id === bonusCategoyField.value?.id);

  useEffect(() => {
    if (presetBonus !== undefined) {
      const presetFrequency = frequencyQuery.data?.find(
        frequency => frequency.id === presetBonus.frequency
      );
      if (presetFrequency !== undefined) {
        setValue('bonusFrequency', presetFrequency);
      }
      bonusLabelController.field.onChange(presetBonus.title);
    }
  }, [bonusLabelController.field, frequencyQuery.data, presetBonus, setValue]);

  return (
    <>
      {presetBonus === undefined && (
        <WithLightTitle title="libellé de la prime">
          <TextInput placeholder="1 caractère minimum..." name="bonusLabel" control={control} />
        </WithLightTitle>
      )}
      <FrequencyAmountSelection control={control} presetBonus={presetBonus} />
    </>
  );
};

export default BonusRatesDetails;
