import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { IllusEDPWarning } from 'src/Assets';
import { useGiveConsultantPhotoRights } from 'src/Hooks/Consultants/useGiveConsultantsPhotoRights';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './ConsultantRightsModal.types';
const ConsultantRightsModal = ({ onValidate, open, onClose, onRefuse }: Props) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const giveConsultantsRightsMutation = useGiveConsultantPhotoRights();
  return (
    <Modal
      open={open}
      title="cession de droits"
      onClose={() => {
        hasAccepted ? setHasAccepted(false) : onRefuse();
        onClose();
      }}
      icon={<IllusEDPWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={toFetchStatus(giveConsultantsRightsMutation)}
          onClick={() => {
            onValidate();
            giveConsultantsRightsMutation.mutate();
            setHasAccepted(true);
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      En validant, vous acceptez de céder les droits des images utilisées sur vos études de poste
    </Modal>
  );
};

export default ConsultantRightsModal;
