import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { BonusPreset, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPresetBonuses = (
  extraOptions?: UseQueryOptions<BonusPreset[], unknown, BonusPreset[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchPresetBonuses],
    () => RepositoriesService.repositoriesControllerGetBonuses(),
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
