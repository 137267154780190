import { UseMutationOptions, useQueryClient, useMutation } from '@tanstack/react-query';

import {
  DetailedPositionStudy,
  PositionstudiesService,
  UpdatePositionStudyNameBody,
} from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUpdatePositionStudyName = (
  extraOptions?: UseMutationOptions<
    DetailedPositionStudy,
    unknown,
    { body: UpdatePositionStudyNameBody; positionStudyId: string }
  >
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updatePositionStudyName],
    async (params: { body: UpdatePositionStudyNameBody; positionStudyId: string }) => {
      if (!params.body.brandCode) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const updatedNamePositionStudy =
        await PositionstudiesService.positionStudiesControllerUpdatePositionStudyName({
          id: params.positionStudyId,
          body: {
            positionStudyName: params.body.positionStudyName,
            brandCode: params.body.brandCode,
          },
        });

      return updatedNamePositionStudy;
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudies'] });
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudiesCount'] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
