import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { DetailedPositionStudy, PositionstudiesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useDuplicateEDP = (
  extraOptions?: UseMutationOptions<DetailedPositionStudy, unknown, unknown>
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.duplicateEDP],
    async (positionStudyId: string) => {
      if (!currentBrand?.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const upadatedPositionStudy =
        await PositionstudiesService.positionStudiesControllerDuplicateFullPositionStudy({
          id: positionStudyId,
          body: { brandCode: currentBrand.brandCodeApiHeader },
        });

      ReactGA.event("Duplication d'une EDP", {
        ...userInfo,
      });

      return upadatedPositionStudy;
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudies'] });
        queryClient.invalidateQueries({ queryKey: ['fetchPositionStudiesCount'] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
